import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Qs from 'qs';

import Header from '../../components/header/Header';
import Footer from '../../components/footer/Footer';
import Hero from '../../components/hero/Hero';
import ModalCreateOrder from './components/modal-create-order/ModalCreateOrder';

import { getBusinessLines } from '../../actions/catalogActions';
import { SessionOptions } from '../../constants';

class PageCreateOrder extends Component {

    static propTypes = {
        location: PropTypes.object,
        history: PropTypes.object,
        saleDetails: PropTypes.object,
        user: PropTypes.object,
        getBusinessLines: PropTypes.func
    }

    constructor(props) {
        super(props);

        // check passed params
        let parameters = null;
        let sessionType = null;
        let lineOfBusiness = null;
        let zipCode = null;
        let agent = null;
        if (props.location.search) {
            parameters = Qs.parse(props.location.search.substring(1));
            if (parameters.type) {
                sessionType = Object.keys(SessionOptions).find((key) => SessionOptions[key] === parameters.type);
            }

            if (parameters.lob) {
                lineOfBusiness = parameters.lob;
            }

            if (parameters.zipcode) {
                zipCode = parameters.zipcode;
            }

            if (parameters.salesagent) {
                agent = parameters.salesagent;
            }
        }

        if (!sessionType && props.user.isReseller && props.user.sessionType) {
            sessionType = Object.keys(SessionOptions).find((key) => SessionOptions[key] === props.user.sessionType);
        }

        this.parameters = {
            sessionType,
            lineOfBusiness,
            zipCode,
            agent
        };
    }

    componentDidMount() {
        this.props.getBusinessLines();
    }

    render() {
        return (
            <div className="page modal-active">
                <Header />
                <Hero />
                <Footer />
                <ModalCreateOrder
                    sessionType={this.props.saleDetails.session || this.parameters.sessionType}
                    lineOfBusiness={this.parameters.lineOfBusiness}
                    zipCode={this.parameters.zipCode}
                    agent={this.parameters.agent}
                    user={this.props.user}
                />
            </div>
        );
    }
}

const mapStateToProps = ({ order, profile }) => ({
    saleDetails: order.saleDetails,
    user: profile.user
});

export default withRouter(connect(mapStateToProps, {
    getBusinessLines
})(PageCreateOrder));
