import React from 'react';
import PropTypes from 'prop-types';
import { Route } from 'react-router-dom';
import userManager from '../helpers/userManager';

const LoginRoute = ({ ...rest }) => {

    return (
        <Route
            {...rest}
            render={(props) => {
                const { location } = props;

                if (location.search) {
                    localStorage.setItem('createOrderParams', location.search);
                } else {
                    localStorage.removeItem('createOrderParams');
                }

                userManager.signinRedirect();
                return null;
            }}
        />
    );
};

LoginRoute.propTypes = {
    location: PropTypes.object
};

export default LoginRoute;
