import { makeUserManager } from 'react-oidc';
import { ResellerRole, CanAccessRole } from '../constants';

const gatekeeperUrl = process.env.REACT_APP_GATEKEEPER_URL;
const gatekeeperRedirectUrl = process.env.REACT_APP_GATEKEEPER_REDIRECT_URL;
const gatekeeperClientSecret = process.env.REACT_APP_GATEKEEPER_CLIENT_SECRET;

const userManagerConfig = {
    authority: `${gatekeeperUrl}/oidc`,
    redirect_uri: `${gatekeeperRedirectUrl}/signin-oidc`,
    post_logout_redirect_url: gatekeeperRedirectUrl,
    client_id: 'SmartConnectSales',
    automaticSilentRenew: true,
    response_type: 'code',
    scope: 'openid offline_access WIN SmartConnectSales',
    post_logout_redirect_uri: `${gatekeeperRedirectUrl}/signout-oidc`,
    loadUserInfo: true,
    revokeAccessTokenOnSignout: true,
    metadata: {
        issuer: `${gatekeeperUrl}/oidc`,
        authorization_endpoint: `${gatekeeperUrl}/oidc/connect/authorize`,
        userinfo_endpoint: `${gatekeeperUrl}/oidc/connect/userinfo`,
        end_session_endpoint: `${gatekeeperUrl}/oidc/connect/endsession`,
        token_endpoint: `${gatekeeperUrl}/oidc/connect/token`,
        jwks_uri: `${gatekeeperUrl}/oidc/.well-known/openid-configuration/jwks`
    }
};

if (gatekeeperClientSecret) {
    userManagerConfig.client_secret = gatekeeperClientSecret;
}

const userManager = makeUserManager(userManagerConfig);

export const parseUserRoles = (user) => {
    const userRoles = user.profile.role;
    const resellerRole = userRoles.find((role) => role.includes(ResellerRole));
    if (resellerRole) {
        user.profile.isReseller = true;
        // parse reseller type
        const resellerType = resellerRole.substring(resellerRole.indexOf('-') + 2);
        if (resellerType) {
            user.profile.resellerType = resellerType;

            switch (resellerType) {
                case 'Updater':
                    user.profile.sessionType = 'Consumer (Includes Lead Gen)';
                    user.profile.lineOfBusiness = 'Updater';
                    break;
                default:
                    break;
            }
        } else {
            user.profile.isReseller = false;
        }
    } else {
        user.profile.isReseller = false;
    }

    user.profile.isAccessDenied = !userRoles.includes(CanAccessRole);
};

export default userManager;
