import React, { Component} from 'react';
import PropTypes from 'prop-types';
import ClassNames from 'classnames';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { isEmpty } from 'lodash';

import Modal from '../modal/Modal';
import Preloader from '../preloader/Preloader';

import { lookUpZip, toggleModalZipLookup, toggleModalNoProducts } from '../../actions/catalogActions';
import { setSaleDetails, setCustomerDetails } from '../../actions/orderActions';

import './modal-zip-lookup.scss';

class ModalZipLookUp extends Component {

    static propTypes = {
        history: PropTypes.object,
        isOSC: PropTypes.bool,
        description: PropTypes.string,
        noService: PropTypes.bool,
        products: PropTypes.object,
        filteredProducts: PropTypes.object,
        warehousesData: PropTypes.object,
        errors: PropTypes.object,
        isLoading: PropTypes.bool,
        customer: PropTypes.object,
        saleDetails: PropTypes.object,
        redirectToCart: PropTypes.bool,
        zipLookup: PropTypes.object,
        lookUpZip: PropTypes.func,
        toggleModalZipLookup: PropTypes.func,
        toggleModalNoProducts: PropTypes.func,
        setSaleDetails: PropTypes.func,
        setCustomerDetails: PropTypes.func
    }

    static defaultProps = {
        redirectToCart: false,
        description: `Our network of over 4,000 skilled technicians in over 125 markets deliver and
        install technology solutions to residential and commercial customers throughout the
        United States. Despite operating one of the largest fleets of service vehicles in
        the nation, we are not currently operating in every market. Please tell us the
        zip code of the location where you would like service to see if we can connect with you.`
    }

    constructor(props) {
        super(props);
        this.state = {
            isActionDisabled: true,
            zip: ''
        };
    }

    onChange = (event) => {

        const { value, name } = event.target;

        let isActionDisabled = true;
        if (value && value.length >= 4 && value.length <= 5) {
            isActionDisabled = false;
        }

        if (value.length <= 5) {
            this.setState({
                [name]: value,
                isActionDisabled
            });
        }
    }

    handleCheckClick = () => {
        const { history, redirectToCart, customer, saleDetails, isOSC, products, warehousesData } = this.props;
        const { zip } = this.state;
        if (zip) {
            this.props.lookUpZip(
                zip,
                saleDetails.session,
                saleDetails.lineOfBusiness,
                saleDetails.lineOfBusinessId,
                isOSC,
                { products, warehousesData },
                (success, zipLookup, filteredProducts) => {
                    if (success) {
                        if (Object.keys(filteredProducts).length === 0) {
                            this.props.toggleModalZipLookup(false);
                            return this.props.toggleModalNoProducts(true);
                        }

                        this.props.setSaleDetails({
                            ...saleDetails,
                            zipCode: zipLookup.zip
                        });

                        if (!isEmpty(customer)) {
                            this.props.setCustomerDetails({
                                ...customer,
                                postalCode: zipLookup.zip
                            });
                        }

                        this.props.toggleModalZipLookup(false);
                        if (redirectToCart) {
                            history.replace({ pathname: '/cart' });
                        }
                    }
                });
        }
    }

    render() {

        const { errors, isLoading, noService, description } = this.props;

        const formClasses = {
            'authorize-user-form': true,
            'area-not-covered': Object.keys(errors).length > 0 || noService
        };

        let modalSize = 's';
        if (description.length < 40) {
            modalSize = 'ss';
        }

        return (

            <Modal onClose={() => this.props.toggleModalZipLookup(false)} size={modalSize}>
                <h1 data-title="Please enter your zip code">Please enter your zip code</h1>
                <p>
                    {description}
                </p>
                <div className={ClassNames(formClasses)}>
                    <p className="form-item">
                        <input
                            type="number"
                            name='zip'
                            value={this.state.zip}
                            onChange={this.onChange}
                            placeholder="Your zip code"/>
                    </p>
                    <p className="form-action-wrapper">
                        <button
                            onClick={this.handleCheckClick}
                            disabled={this.state.isActionDisabled}
                            className="primary">
                            Check
                        </button>
                    </p>
                    {
                        isLoading ? <Preloader type="overlay" /> : null
                    }
                    <p className="coverage-error-message">
                        Unfortunately, that zip code is outside our service area.<br/>
                        We will not be able to connect with you at this time.
                    </p>
                </div>
            </Modal>
        );
    }
}

const mapStateToProps = ({ profile, catalog, order }) => ({
    isOSC: !!profile.accessToken && profile.expired != null && !profile.expired,
    noService: catalog.noService,
    isLoading: catalog.isLoading,
    filteredProducts: catalog.filteredProducts,
    errors: catalog.errors,
    customer: order.customerAccount,
    saleDetails: order.saleDetails,
    zipLookup: catalog.zipLookup,
    warehousesData: catalog.warehousesData,
    products: catalog.products
});

export default withRouter(connect(mapStateToProps, {
    lookUpZip,
    toggleModalZipLookup,
    toggleModalNoProducts,
    setSaleDetails,
    setCustomerDetails
})(ModalZipLookUp));
