import { isEmpty, cloneDeep } from 'lodash';
import moment from 'moment';
import {
    LOGOUT
} from '../actions/types';
import CNST from '../actions/constants';
import FormatHelper from '../helpers/format';
import ProductsHelper from '../helpers/products';
import { FutureTruckRollItemCode, BuriedCableItemCode } from '../constants';

const initialState = {
    saleDetails: {},
    customerAccount: {},
    isExternal: false,
    isLoading: false,
    isGetScheduleAvailabilityLoading: false,
    items: [],
    totalCartQuantity: 0,
    workOrder: {},
    tax: [],
    discountReasons: [],
    promoCode: null,
    errors: {},
    invoice: {
        id: '',
        status: '',
        date: ''
    },
    installationDate: undefined,
    installationTime: undefined,
    scheduleAvailabilityId: null,
    scheduleAvailability: [],
    isTermsSigned: false
};

const int = (val) => {
    const parsedNumber = parseInt(val, 10);
    return isNaN(parsedNumber) ? 0 : parsedNumber;
};

const formatScheduleSlots = (scheduleAvailability) => {

    if (scheduleAvailability) {
        scheduleAvailability.forEach((schedule) => {
            const startTime = moment(schedule.startDateTime).format('h a');
            const endTime = moment(schedule.endDateTime).format('h a');
            schedule.title = `${startTime} - ${endTime}`;
        });
    }

    return scheduleAvailability;
};

export default function (state = cloneDeep(initialState), action) {
    switch (action.type) {
        case LOGOUT:
            return {
                ...state,
                ...initialState
            };
        case CNST.ORDER.GET_CUSTOMER_ACCOUNT.SUCCESS: {
            let { data } = action.response;
            const isExternal = !isEmpty(data);
            data = FormatHelper.formatCustomerInfo(data);
            return {
                ...state,
                ...{ errors: {}, customerAccount: data, isExternal, isLoading: false }
            };
        }
        case CNST.ORDER.CLEAR_CUSTOMER_ACCOUNT: {
            return {
                ...state,
                errors: {},
                customerAccount: {},
                isExternal: false
            };
        }
        case CNST.ORDER.SET_CUSTOMER_DETAILS:
            return {
                ...state,
                customerAccount: {
                    ...state.customerAccount,
                    ...action.data
                }
            };
        case CNST.ORDER.GET_DISCOUNT_REASONS.SUCCESS: {
            const { discountReasons } = action.response;
            return {
                ...state,
                ...{ errors: {}, discountReasons, isLoading: false }
            };
        }
        case CNST.ORDER.GET_PROMO_CODE.SUCCESS: {
            const { promoCode } = action.response;
            return {
                ...state,
                ...{ errors: {}, promoCode, isLoading: false },
                items: state.items.map(item => {
                    const discount = ProductsHelper.calculateDiscount(
                        item, item.discount ? item.discount.percent : 0, promoCode);
                    return {
                        ...item,
                        ...discount
                    };
                })
            };
        }
        case CNST.ORDER.GET_TAX_RATES.SUCCESS: {
            return {
                ...state,
                ...{
                    tax: action.response,
                    isLoading: false,
                    errors: {
                        ...state.errors,
                        tax: ""
                    }
                }
            };
        }
        case CNST.ORDER.SUBMIT_ORDER.SUCCESS: {
            const { data } = action.response;
            return { ...state, ...{ errors: {}, workOrder: data.workOrder, isLoading: false } };
        }
        case CNST.ORDER.CANCEL_PAYPAL_INVOICE.SUCCESS:
            return { ...state, invoice: action.invoice, isLoading: false };
        case CNST.ORDER.REMOVE_PROMO_CODE:
            return {
                ...state,
                ...{ errors: {}, promoCode: null },
                items: state.items.map(item => {
                    const discount = ProductsHelper.calculateDiscount(
                        item, item.discount ? item.discount.percent : 0, null);
                    return {
                        ...item,
                        ...discount
                    };
                })
            };
        case CNST.ORDER.GET_SCHEDULE_AVAILABILITY.SUCCESS: {
            const { scheduleAvailability } = action.response;
            return {
                ...state,
                errors: {},
                scheduleAvailability: formatScheduleSlots(scheduleAvailability),
                isGetScheduleAvailabilityLoading: false
            };
        }
        case CNST.ORDER.RESOLVE_ADDRESS.SUCCESS: {
            return {
                ...state,
                ...{ error: {}, isLoading: false }
            };
        }
        case CNST.ORDER.SEND_INVOICE.SUCCESS:
        case CNST.ORDER.GET_INVOICE_STATUS.SUCCESS:
            return { ...state, invoice: action.invoice, isLoading: false };
        case CNST.ORDER.SET_INVOICE:
            return { ...state, invoice: action.invoice };
        case CNST.ORDER.GET_CUSTOMER_ACCOUNT.FAILED:
        case CNST.ORDER.GET_DISCOUNT_REASONS.FAILED:
        case CNST.ORDER.GET_PROMO_CODE.FAILED:
        case CNST.ORDER.SEND_INVOICE.FAILED:
        case CNST.ORDER.GET_INVOICE_STATUS.FAILED:
        case CNST.ORDER.SUBMIT_ORDER.FAILED:
        case CNST.ORDER.CANCEL_PAYPAL_INVOICE.FAILED:
        case CNST.ORDER.RESOLVE_ADDRESS.FAILED:
            return {
                ...state,
                ...{ errors: action.response.error, isLoading: false }
            };
        case CNST.ORDER.GET_SCHEDULE_AVAILABILITY.FAILED:
            return {
                ...state,
                ...{ errors: action.response.error, isGetScheduleAvailabilityLoading: false }
            };
        case CNST.ORDER.GET_TAX_RATES.FAILED:
            return {
                ...state,
                isLoading: false,
                tax: [],
                errors: {
                    ...state.errors,
                    tax: action.response.error.message
                }
            };
        case CNST.ORDER.GET_CUSTOMER_ACCOUNT.LOADING:
        case CNST.ORDER.GET_DISCOUNT_REASONS.LOADING:
        case CNST.ORDER.GET_PROMO_CODE.LOADING:
        case CNST.ORDER.GET_TAX_RATES.LOADING:
        case CNST.ORDER.SUBMIT_ORDER.LOADING:
        case CNST.ORDER.SEND_INVOICE.LOADING:
        case CNST.ORDER.GET_INVOICE_STATUS.LOADING:
        case CNST.ORDER.CANCEL_PAYPAL_INVOICE.LOADING:
        case CNST.ORDER.RESOLVE_ADDRESS.LOADING:
            return {
                ...state,
                errors: {},
                isLoading: true
            };
        case CNST.ORDER.GET_SCHEDULE_AVAILABILITY.LOADING:
            return {
                ...state,
                errors: {},
                installationDate: undefined,
                installationTime: undefined,
                isGetScheduleAvailabilityLoading: true
            };
        case CNST.CART.PUT_PRODUCT_TO_CART: {
            let totalCartQuantity = 0;
            let futureTruckRollItem = null;
            let futureTruckRollItemIndex = null;
            let items = [...state.items, action.item].map((item, index) => {
                const discount = ProductsHelper.calculateDiscount(
                    item, item.discount ? item.discount.percent : 0, state.promoCode);

                if (item.itemCode !== FutureTruckRollItemCode) {
                    totalCartQuantity += item.quantity;
                } else {
                    futureTruckRollItem = item;
                    futureTruckRollItemIndex = index;
                }
                return {
                    ...item,
                    ...discount
                };
            });

            if (futureTruckRollItem && futureTruckRollItemIndex !== items.length - 1) {
                // move future truck roll item to the end
                items = items.filter(item => {
                    if (item.itemCode !== FutureTruckRollItemCode) {
                        return true;
                    }
                    return false;
                });
                items = [...items, futureTruckRollItem];
            }

            return {
                ...state,
                totalCartQuantity,
                items
            };
        }

        case CNST.CART.CHANGE_PRODUCT_QUANTITY: {
            let totalCartQuantity = 0;
            const maxDigitsCount = action.productCode === BuriedCableItemCode || action.useTextQuantity ? 5 : 2;

            if (action.quantity.length > maxDigitsCount) {
                action.quantity = action.quantity.substring(0, maxDigitsCount);
            }

            let quantity = int(action.quantity);

            if (quantity === 0) {
                quantity = 1;
            }
            const items = state.items.map(item => {
                let updatedItem = null;
                if (item.id === action.id) {

                    updatedItem = {
                        ...item,
                        quantity
                    };
                    const discount = ProductsHelper.calculateDiscount(
                        updatedItem, updatedItem.discount ? updatedItem.discount.percent : 0, state.promoCode);
                    updatedItem = {
                        ...updatedItem,
                        ...discount
                    };
                } else {
                    updatedItem = item;
                }

                if (item.itemCode !== FutureTruckRollItemCode) {
                    totalCartQuantity += updatedItem.quantity;
                }
                return updatedItem;
            });
            return {
                ...state,
                totalCartQuantity,
                items
            };
        }
        case CNST.CART.REMOVE_PRODUCT_FROM_CART: {
            let totalCartQuantity = 0;
            let hasFtrProducts = false;
            let hasFtrCharge = false;
            let items = state.items.filter(item => {
                if (item.id !== action.item.id) {
                    if (item.itemCode !== FutureTruckRollItemCode) {
                        totalCartQuantity += item.quantity;
                    } else {
                        hasFtrCharge = true;
                    }

                    if (item.futureTruckRoll) {
                        hasFtrProducts = true;
                    }

                    return true;
                }
                return false;
            });

            if (hasFtrCharge && !hasFtrProducts) {
                items = items.filter(item => {
                    return item.itemCode !== FutureTruckRollItemCode;
                });
            }

            return {
                ...state,
                totalCartQuantity,
                items
            };
        }

        case CNST.ORDER.CLEAR_ORDER:
            return {
                ...initialState
            };
        case CNST.ORDER.CLEAR_SALE_DETAILS: {
            if (action.isOSC) {
                return {
                    ...state,
                    saleDetails: {},
                    isExternal: false,
                    customerAccount: {}
                };
            }
            return {
                ...state,
                saleDetails: {}
            };
        }
        case CNST.ORDER.SET_SALE_DETAILS:
            return {
                ...state,
                saleDetails: {
                    ...action.data
                }
            };
        case CNST.ORDER.DISCOUNT.SET: {
            const discountPercent = int(action.percent);
            const items = state.items.map((item) => {
                if (item.id === action.productId) {
                    const discount = ProductsHelper.calculateDiscount(item, discountPercent, state.promoCode);
                    return {
                        ...item,
                        ...discount,
                        discount: {
                            percent: discountPercent,
                            reason: action.reason
                        }
                    };
                }
                return item;
            });
            return {
                ...state,
                items
            };
        }
        case CNST.ORDER.DISCOUNT.REMOVE:
            return {
                ...state,
                items: state.items.map(item => {
                    if (item.id === action.productId) {
                        const discount = ProductsHelper.calculateDiscount(item, 0, state.promoCode);
                        return {
                            ...item,
                            ...discount,
                            discount: null
                        };
                    }
                    return item;
                })
            };
        case CNST.ORDER.PAYMENT_SUCCEEDED:
            return {
                ...state,
                invoice: {
                    id: action.paymentId,
                    status: 'PAID',
                    date: action.date,
                    type: 'direct'
                }
            };
        case CNST.ORDER.SET_INSTALLATION_DATE:
            return {
                ...state,
                installationDate: action.date
            };
        case CNST.ORDER.SET_INSTALLATION_TIME:
            return {
                ...state,
                installationTime: action.time,
                scheduleAvailabilityId: action.id
            };
        case CNST.ORDER.SET_TERMS_SIGNED:
            return {
                ...state,
                isTermsSigned: true
            };
        default:
            return state;
    }
}
