import React, { Component} from 'react';
import PropTypes from 'prop-types';

import Modal from '../../../../components/modal/Modal';

class ModalNoProduct extends Component {

    static propTypes = {
        onClose: PropTypes.func
    }

    render() {

        return (

            <Modal onClose={this.props.onClose} size={'ss'}>
                <h1 data-title="Please select another product">Please select another product</h1>
                <p className="error-message-icon">
                    The product you selected is not available in your<br/>
                    zip code. Please choose another product.
                </p>
                <p className="action-wrapper">
                    <button
                        onClick={this.props.onClose}
                        className="primary">
                        OK
                    </button>
                </p>
            </Modal>
        );
    }
}

export default ModalNoProduct;
