import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";

import ModalError from "../../../../components/modal-error/ModalError";

import {
    setPaymentSucceeded,
    getClientToken,
    createOrder,
    captureOrder,
    sendPayPalLog
} from "../../../../actions/orderActions";

import ProductsHelper from "../../../../helpers/products";
import FormatHelper from "../../../../helpers/format";

import "./form-paypal.scss";

const clientId = process.env.REACT_APP_PAYPAL_CLIENT_ID;

class FormPayPal extends Component {
    static propTypes = {
        cartItems: PropTypes.array,
        customer: PropTypes.object,
        saleDetails: PropTypes.object,
        tax: PropTypes.object,
        user: PropTypes.object,
        setPaymentSucceeded: PropTypes.func
    };

    constructor(props) {
        super(props);

        const { customer } = props;

        this.state = {
            loading: false,
            isSdkReady: false,
            isFormReady: false,
            ...customer
        };

        this.cardFields = null;
    }

    _getNames(name) {
        const names = {
            given_name: "",
            surname: ""
        };

        name = name.trim();
        const nameParts = name.split(" ");

        if (nameParts.length > 1) {
            for (let namePart of nameParts) {
                namePart = namePart.trim();

                if (namePart && !names.given_name) {
                    names.given_name = namePart;
                } else if (namePart) {
                    names.surname += `${namePart} `;
                }
            }
        } else {
            names.given_name = nameParts[0];
        }

        names.given_name = names.given_name.trim();
        names.surname = names.surname.trim();

        return names;
    }

    _parsePayPalError = error => {
        const errorDetail =
            error.details && Array.isArray(error.details) && error.details[0];

        if (errorDetail) {
            const msg =
                "Sorry, your transaction could not be processed. Please try again.";
            let msgDetails = "";
            if (errorDetail.description) {
                msgDetails += errorDetail.description;
            }

            this.setState({
                payPalError: msg,
                payPalErrorDetails: msgDetails
            });
        }
    };

    _parsePayPalProcessorResponse = (paymentSource, response) => {
        let message = '';
        const { avs_code, cvv_code, response_code  } = response;
        const { card } = paymentSource;
        const cardBrand = card || card.brand;

        if (avs_code) {
            switch(avs_code) {
                case 'A':
                    message = 'The address matches but the zip code does not match. ';
                    break;
                case 'E':
                    if (cardBrand === 'AMEX') {
                        message = 'The name is incorrect but the address and postal code match. ';
                    } else {
                        message = 'Not allowed for Internet or phone transactions. ';
                    }
                    break;
                case 'G':
                    message = 'Global is unavailable. Nothing matches. ';
                    break;
                case 'I':
                    message = 'International is unavailable. Not applicable. ';
                    break;
                case 'N':
                    if (cardBrand === 'AMEX') {
                        message = 'The address and postal code are both incorrect. ';
                    } else {
                        message = 'Address not match. ';
                    }
                    break;
                case 'S':
                    message = 'The service is not supported. ';
                    break;
                case 'U':
                    if (cardBrand === 'MAESTRO') {
                        message = 'The address is not checked or the acquirer had no response. The service is not available. ';
                    } else if (cardBrand === 'AMEX') {
                        message = 'Information is not available. ';
                    } else {
                        message = 'The service is unavailable. ';
                    }
                    break;
                case 'W':
                    if (cardBrand === 'AMEX') {
                        message = 'The card holder name, address, and postal code are all incorrect. ';
                    } else {
                        message = 'Whole ZIP code. ';
                    }
                    break;
                case 'X':
                    if (cardBrand === 'AMEX') {
                        message = 'The card holder name, address, and postal code are all incorrect. ';
                    } else {
                        message = 'Exact match of the address and the nine-digit ZIP code. ';
                    }
                    break;
                case 'Y':
                    if (cardBrand === 'AMEX') {
                        message = 'The address and five-digit ZIP code match. ';
                    } else {
                        message = 'Exact match of the address and the nine-digit ZIP code. ';
                    }
                    break;
                case 'Z':
                    if (cardBrand === 'AMEX') {
                        message = 'Only the card holder postal code is correct. ';
                    } else {
                        message = 'The five-digit ZIP code matches but no address. ';
                    }
                    break;
            }
        }

        if (cvv_code) {
            switch (cvv_code) {
                case 'E':
                    message += 'CVV2: Error - unrecognized or unknown response. ';
                    break;
                case 'I':
                    message += 'CVV2: Invalid or null. ';
                    break;
                case 'M':
                    message += 'The CVV2/CSC matches. ';
                    break;
                case 'N':
                    message += 'The CVV2/CSC does not match. ';
                    break;
                case 'P':
                    message += 'The CVV2/CSC was not processed. ';
                    break;
                case 'S':
                    message += 'CVV2: The service is not supported. ';
                    break;
                case 'U':
                    message += 'CVV2: Unknown - the issuer is not certified. ';
                    break;
                case 'X':
                    message += 'CVV2: No response. ';
                    break;
            }
        }

        if (response_code) {
            switch (response_code) {
                case '0000':
                    message += '(APPROVED)';
                    break;
                case '0100':
                    message += '(REFERRAL)';
                    break;
                case '0800':
                    message += '(BAD_RESPONSE_REVERSAL_REQUIRED)';
                    break;
                case '1000':
                    message += '(PARTIAL_AUTHORIZATION)';
                    break;
                case '1300':
                    message += '(INVALID_DATA_FORMAT)';
                    break;
                case '1310':
                    message += '(INVALID_AMOUNT)';
                    break;
                case '1312':
                    message += '(INVALID_TRANSACTION_CARD_ISSUER_ACQUIRER)';
                    break;
                case '1317':
                    message += '(INVALID_CAPTURE_DATE)';
                    break;
                case '1320':
                    message += '(INVALID_CURRENCY_CODE)';
                    break;
                case '1330':
                    message += '(INVALID_ACCOUNT)';
                    break;
                case '1335':
                    message += '(INVALID_ACCOUNT_RECURRING)';
                    break;
                case '1340':
                    message += '(INVALID_TERMINAL)';
                    break;
                case '1350':
                    message += '(INVALID_MERCHANT)';
                    break;
                case '1360':
                    message += '(BAD_PROCESSING_CODE)';
                    break;
                case '1370':
                    message += '(INVALID_MCC)';
                    break;
                case '1380':
                    message += '(INVALID_EXPIRATION)';
                    break;
                case '1382':
                    message += '(INVALID_CARD_VERIFICATION_VALUE)';
                    break;
                case '1384':
                    message += '(INVALID_LIFE_CYCLE_OF_TRANSACTION)';
                    break;
                case '1390':
                    message += '(INVALID_ORDER)';
                    break;
                case '1393':
                    message += '(TRANSACTION_CANNOT_BE_COMPLETED)';
                    break;
                case '0500':
                    message += '(DO_NOT_HONOR)';
                    break;
                case '5100':
                    message += '(GENERIC_DECLINE)';
                    break;
                case '5110':
                    message += '(CVV2_FAILURE)';
                    break;
                case '5120':
                    message += '(INSUFFICIENT_FUNDS)';
                    break;
                case '5130':
                    message += '(INVALID_PIN)';
                    break;
                case '5140':
                    message += '(CARD_CLOSED)';
                    break;
                case '5150':
                    message += '(PICKUP_CARD_SPECIAL_CONDITIONS)';
                    break;
                case '5160':
                    message += '(UNAUTHORIZED_USER)';
                    break;
                case '5170':
                    message += '(AVS_FAILURE)';
                    break;
                case '5180':
                    message += '(INVALID_OR_RESTRICTED_CARD)';
                    break;
                case '5190':
                    message += '(SOFT_AVS)';
                    break;
                case '5200':
                    message += '(DUPLICATE_TRANSACTION)';
                    break;
                case '5210':
                    message += '(INVALID_TRANSACTION)';
                    break;
                case '5400':
                    message += '(EXPIRED_CARD)';
                    break;
                case '5500':
                    message += '(INCORRECT_PIN_REENTER)';
                    break;
                case '5700':
                    message += '(TRANSACTION_NOT_PERMITTED)';
                    break;
                case '5800':
                    message += '(REVERSAL_REJECTED)';
                    break;
                case '5900':
                    message += '(INVALID_ISSUE)';
                    break;
                case '5910':
                    message += '(ISSUER_NOT_AVAILABLE_NOT_RETRIABLE)';
                    break;
                case '5920':
                    message += '(ISSUER_NOT_AVAILABLE_RETRIABLE)';
                    break;
                case '6300':
                    message += '(ACCOUNT_NOT_ON_FILE)';
                    break;
                case '7600':
                    message += '(APPROVED_NON_CAPTURE)';
                    break;
                case '7700':
                    message += '(ERROR_3DS)';
                    break;
                case '7710':
                    message += '(AUTHENTICATION_FAILED)';
                    break;
                case '7800':
                    message += '(BIN_ERROR)';
                    break;
                case '7900':
                    message += '(PIN_ERROR)';
                    break;
                case '8000':
                    message += '(PROCESSOR_SYSTEM_ERROR)';
                    break;
                case '8010':
                    message += '(HOST_KEY_ERROR)';
                    break;
                case '8020':
                    message += '(CONFIGURATION_ERROR)';
                    break;
                case '8030':
                    message += '(UNSUPPORTED_OPERATION)';
                    break;
                case '8100':
                    message += '(FATAL_COMMUNICATION_ERROR)';
                    break;
                case '8110':
                    message += '(RETRIABLE_COMMUNICATION_ERROR)';
                    break;
                case '8220':
                    message += '(SYSTEM_UNAVAILABLE)';
                    break;
                case '9100':
                    message += '(DECLINED_PLEASE_RETRY)';
                    break;
                case '9500':
                    message += '(SUSPECTED_FRAUD)';
                    break;
                case '9510':
                    message += '(SECURITY_VIOLATION)';
                    break;
                case '9520':
                    message += '(LOST_OR_STOLEN)';
                    break;
                case '9530':
                    message += '(HOLD_CALL_CENTER)';
                    break;
                case '9540':
                    message += '(REFUSED_CARD)';
                    break;
                case '9600':
                    message += '(UNRECOGNIZED_RESPONSE_CODE)';
                    break;
                case '5930':
                    message += '(CARD_NOT_ACTIVATED)';
                    break;
                case 'PPMD':
                    message += '(PPMD)';
                    break;
                case 'PPCE':
                    message += '(CE_REGISTRATION_INCOMPLETE)';
                    break;
                case 'PPNT':
                    message += '(NETWORK_ERROR)';
                    break;
                case 'PPCT':
                    message += '(CARD_TYPE_UNSUPPORTED)';
                    break;
                case 'PPTT':
                    message += '(TRANSACTION_TYPE_UNSUPPORTED)';
                    break;
                case 'PPCU':
                    message += '(CURRENCY_USED_INVALID)';
                    break;
                case 'PPQC':
                    message += '(QUASI_CASH_UNSUPPORTED)';
                    break;
                case 'PPVE':
                    message += '(VALIDATION_ERROR)';
                    break;
                case 'PPVT':
                    message += '(VIRTUAL_TERMINAL_UNSUPPORTED)';
                    break;
                case 'PPDC':
                    message += '(DCC_UNSUPPORTED)';
                    break;
                case 'PPER':
                    message += '(INTERNAL_SYSTEM_ERROR)';
                    break;
                case 'PPIM':
                    message += '(ID_MISMATCH)';
                    break;
                case 'PPH1':
                    message += '(H1_ERROR)';
                    break;
                case 'PPSD':
                    message += '(STATUS_DESCRIPTION)';
                    break;
                case 'PPAG':
                    message += '(ADULT_GAMING_UNSUPPORTED)';
                    break;
                case 'PPLS':
                    message += '(LARGE_STATUS_CODE)';
                    break;
                case 'PPCO':
                    message += '(COUNTRY)';
                    break;
                case 'PPAD':
                    message += '(BILLING_ADDRESS)';
                    break;
                case 'PPAU':
                    message += '(MCC_CODE)';
                    break;
                case 'PPUC':
                    message += '(CURRENCY_CODE_UNSUPPORTED)';
                    break;
                case 'PPUR':
                    message += '(UNSUPPORTED_REVERSAL)';
                    break;
                case 'PPVC':
                    message += '(VALIDATE_CURRENCY)';
                    break;
                case 'PPS0':
                    message += '(BANKAUTH_ROW_MISMATCH)';
                    break;
                case 'PPS1':
                    message += '(BANKAUTH_ROW_SETTLED)';
                    break;
                case 'PPS2':
                    message += '(BANKAUTH_ROW_VOIDED)';
                    break;
                case 'PPS3':
                    message += '(BANKAUTH_EXPIRED)';
                    break;
                case 'PPS4':
                    message += '(CURRENCY_MISMATCH)';
                    break;
                case 'PPS5':
                    message += '(CREDITCARD_MISMATCH)';
                    break;
                case 'PPS6':
                    message += '(AMOUNT_MISMATCH)';
                    break;
                case 'PPRF':
                    message += '(INVALID_PARENT_TRANSACTION_STATUS)';
                    break;
                case 'PPEX':
                    message += '(EXPIRY_DATE)';
                    break;
                case 'PPAX':
                    message += '(AMOUNT_EXCEEDED)';
                    break;
                case 'PPDV':
                    message += '(AUTH_MESSAGE)';
                    break;
                case 'PPDI':
                    message += '(DINERS_REJECT)';
                    break;
                case 'PPAR':
                    message += '(AUTH_RESULT)';
                    break;
                case 'PPBG':
                    message += '(BAD_GAMING)';
                    break;
                case 'PPGR':
                    message += '(GAMING_REFUND_ERROR)';
                    break;
                case 'PPCR':
                    message += '(CREDIT_ERROR)';
                    break;
                case 'PPAI':
                    message += '(AMOUNT_INCOMPATIBLE)';
                    break;
                case 'PPIF':
                    message += '(IDEMPOTENCY_FAILURE)';
                    break;
                case 'PPMC':
                    message += '(BLOCKED_Mastercard)';
                    break;
                case 'PPAE':
                    message += '(AMEX_DISABLED)';
                    break;
                case 'PPFV':
                    message += '(FIELD_VALIDATION_FAILED)';
                    break;
                case 'PPII':
                    message += '(INVALID_INPUT_FAILURE)';
                    break;
                case 'PPPM':
                    message += '(INVALID_PAYMENT_METHOD)';
                    break;
                case 'PPUA':
                    message += '(USER_NOT_AUTHORIZED)';
                    break;
                case 'PPFI':
                    message += '(INVALID_FUNDING_INSTRUMENT)';
                    break;
                case 'PPEF':
                    message += '(EXPIRED_FUNDING_INSTRUMENT)';
                    break;
                case 'PPFR':
                    message += '(RESTRICTED_FUNDING_INSTRUMENT)';
                    break;
                case 'PPEL':
                    message += '(EXCEEDS_FREQUENCY_LIMIT)';
                    break;
                case 'PCVV':
                    message += '(CVV_FAILURE)';
                    break;
                case 'PPTV':
                    message += '(INVALID_VERIFICATION_TOKEN)';
                    break;
                case 'PPTE':
                    message += '(VERIFICATION_TOKEN_EXPIRED)';
                    break;
                case 'PPPI':
                    message += '(INVALID_PRODUCT)';
                    break;
                case 'PPIT':
                    message += '(INVALID_TRACE_ID)';
                    break;
                case 'PPTF':
                    message += '(INVALID_TRACE_REFERENCE)';
                    break;
                case 'PPFE':
                    message += '(FUNDING_SOURCE_ALREADY_EXISTS)';
                    break;
                case 'PPTR':
                    message += '(VERIFICATION_TOKEN_REVOKED)';
                    break;
                case 'PPTI':
                    message += '(INVALID_TRANSACTION_ID)';
                    break;
                case 'PPD3':
                    message += '(SECURE_ERROR_3DS)';
                    break;
                case 'PPPH':
                    message += '(NO_PHONE_FOR_DCC_TRANSACTION)';
                    break;
                case 'PPAV':
                    message += '(ARC_AVS)';
                    break;
                case 'PPC2':
                    message += '(ARC_CVV)';
                    break;
                case 'PPLR':
                    message += '(LATE_REVERSAL)';
                    break;
                case 'PPNC':
                    message += '(NOT_SUPPORTED_NRC)';
                    break;
                case 'PPRR':
                    message += '(MERCHANT_NOT_REGISTERED)';
                    break;
                case 'PPSC':
                    message += '(ARC_SCORE)';
                    break;
                case 'PPSE':
                    message += '(AMEX_DENIED)';
                    break;
                case 'PPUE':
                    message += '(UNSUPPORT_ENTITY)';
                    break;
                case 'PPUI':
                    message += '(UNSUPPORT_INSTALLMENT)';
                    break;
                case 'PPUP':
                    message += '(UNSUPPORT_POS_FLAG)';
                    break;
                case 'PPRE':
                    message += '(UNSUPPORT_REFUND_ON_PENDING_BC)';
                    break;
            }
        }

        return message;
    };

    getPhone() {
        const { customer } = this.props;
        let phone = customer.primaryContactPhone.replace(/\s/g, "");
        phone = phone.includes("+") ? phone.substring(2) : phone;
        return phone;
    }

    componentDidMount() {
        getClientToken((err, response) => {
            console.log("getClientToken", err, response);

            if (!err) {
                const { client_token } = response;
                this.initPaypalSdk(client_token);
            }
        });
    }

    initPaypalSdk(clientToken) {
        console.log("initPaypalSdk");

        // check if script link already added
        const existingLink = document.getElementById("paypal-sdk-link");
        if (existingLink) {
            console.log("script tag already present, removing...");
            existingLink.parentNode.removeChild(existingLink);
        }

        const script = document.createElement("script");
        script.id = "paypal-sdk-link";
        script.type = "text/javascript";
        script.src = `https://www.paypal.com/sdk/js?components=hosted-fields&client-id=${clientId}`;
        script.dataset.clientToken = clientToken;
        script.async = true;
        script.onload = () => {
            console.log("Paypal SDK is loaded.");
            this.setState({ isSdkReady: true });
            this.initForm();
        };
        script.onerror = () => {
            throw new Error("Paypal SDK could not be loaded.");
        };

        document.body.appendChild(script);
    }

    initForm() {
        const { isSdkReady, isFormReady } = this.state;
        if (isSdkReady && window.paypal && !isFormReady) {
            console.log("init credit card form");
            if (window.paypal.HostedFields.isEligible()) {
                window.paypal.HostedFields.render({
                    styles: {
                        input: {
                            "font-family":
                                '"avenir", Helvetica, Arial, sans-serif',
                            "font-size": "17px",
                            transition: "box-shadow 0.5s, border-color 0.5s",
                            "-webkit-transition":
                                "box-shadow 0.5s, border-color 0.5s"
                        },
                        ".valid": {
                            color: "green"
                        },
                        ".invalid": {
                            color: "red"
                        }
                    },
                    fields: {
                        number: {
                            selector: "#card-number",
                            placeholder: "4111 1111 1111 1111"
                        },
                        cvv: {
                            selector: "#cvv",
                            placeholder: "123"
                        },
                        expirationDate: {
                            selector: "#expiration-date",
                            placeholder: "MM/YY"
                        }
                    },
                    createOrder: this.createOrder
                }).then(cardFields => {
                    this.cardFields = cardFields;
                });
            } else {
                console.log("HostedFields is not Eligible");
            }

            this.setState({
                isFormReady: true
            });
        }
    }

    handleFieldChange = ev => {
        let fieldName = ev.target.name;
        switch (fieldName) {
            case "card-holder-name":
                fieldName = "primaryContactName";
                break;
            case "card-billing-address-street":
                fieldName = "street1";
                break;
            case "card-billing-address-city":
                fieldName = "city";
                break;
            case "card-billing-address-state":
                fieldName = "state";
                break;
            case "card-billing-address-zip":
                fieldName = "postalCode";
                break;
            default:
                break;
        }

        this.setState({
            [fieldName]: ev.target.value
        });
    };

    savePayPalLog = (success, paypalResponse, paypalError) => {
        const { user, customer, saleDetails, cartItems } = this.props;
        const data = {
            user,
            customer,
            businessLine: saleDetails.lineOfBusiness,
            workOrderNumber: saleDetails.workOrderNumber,
            cartItems
        };

        sendPayPalLog(success, data, paypalResponse, paypalError, 'Manual - Advanced');
    };

    createOrder = async () => {
        const { tax, cartItems, customer } = this.props;
        const { primaryContactName, city, postalCode, street1, state } = this.state;
        const sum = ProductsHelper.getCartTotalAmount(cartItems);

        const order = {
            payment_source: {
                paypal: {
                    email_address: customer.primaryContactEmail,
                    name: this._getNames(primaryContactName),
                    phone: {
                        phone_number: {
                            national_number: this.getPhone()
                        }
                    },
                    billing_address: {
                        address_line_1:  street1,
                        address_line_2: '',
                        admin_area_2: city,
                        admin_area_1: state,
                        postal_code: postalCode,
                        country_code: 'US'
                    }
                }
            },
             purchase_units: [
                {
                    amount: {
                        currency_code: "USD",
                        value: FormatHelper.roundNumber(
                            sum - -ProductsHelper.getTotalTax(tax),
                            2
                        )
                    },
                    shipping: {
                        name: {
                            full_name: customer.primaryContactName
                        },
                        address: {
                            address_line_1: customer.street1,
                            address_line_2: customer.suite + ' ' + customer.floor,
                            admin_area_1: customer.state,
                            admin_area_2: customer.city,
                            postal_code: customer.postalCode,
                            country_code: 'US'
                        }
                    },
                    invoice_id: uuidv4()
                }
            ]
        };

        const { data, err } = await createOrder(order);
        if (!err) {
            return data.id;
        } else {
            this.savePayPalLog(
                false,
                null,
                err.error.details ? err.error.details : err.error
            );
            this._parsePayPalError(
                err.error.details ? err.error.details : err.error
            );
        }

        return false;
    };

    handleSubmitOrderClick = ev => {
        ev.preventDefault();
        ev.stopPropagation();

        this.setState({
            loading: true,
            payPalError: "",
            payPalErrorDetails: ""
        });

        const {
            primaryContactName,
            street1,
            city,
            state,
            postalCode
        } = this.state;

        if (!primaryContactName) {
            this.setState({
                loading: false,
                payPalError: "Please enter a card holder name"
            });
            return;
        }

        if (!street1 || !city || !state || !postalCode) {
            this.setState({
                loading: false,
                payPalError: "Please enter a billing address"
            });
            return;
        }

        this.cardFields
            .submit({
                // Cardholder's first and last name
                cardholderName: primaryContactName,
                // Billing Address
                billingAddress: {
                    // Street address, line 1
                    streetAddress: street1,
                    // Street address, line 2 (Ex: Unit, Apartment, etc.)
                    extendedAddress: "",
                    // State
                    region: state,
                    // City
                    locality: city,
                    // Postal Code
                    postalCode: postalCode,
                    // Country Code
                    countryCodeAlpha2: "US"
                }
            })
            .then(data => {
                const { orderId } = data;
                return captureOrder(orderId).then(({ data, err }) => {
                    if (err) {
                        this.setState({ loading: false });
                        this._parsePayPalError(
                            err.error.details ? err.error.details : err.error
                        );
                        this.savePayPalLog(
                            false,
                            null,
                            err.error.details ? err.error.details : err.error
                        );
                    } else if (data) {
                        const transaction = data.purchase_units[0].payments.captures[0];
                        if (data.status === 'COMPLETED' && transaction.status === 'COMPLETED') {
                            this.savePayPalLog(true, data);
                            this.props.setPaymentSucceeded(
                                data.id,
                                transaction.update_time
                            );
                        } else {
                            const msg = "Sorry, your transaction could not be processed. Please try again.";
                            const msgDetails = this._parsePayPalProcessorResponse(
                                data.payment_source,
                                transaction.processor_response
                            );

                            this.setState({
                                loading: false,
                                payPalError: msg,
                                payPalErrorDetails: msgDetails
                            });

                            this.savePayPalLog(false, null, {
                                ...data,
                                message:
                                    `Order has status: ${data.status}, transaction has status: ${transaction.status}`
                            });
                        }
                    } else {
                        this.setState({
                            loading: false,
                            payPalError: "Sorry, your transaction could not be processed. Please try again.",
                            payPalErrorDetails: "Unexpected error."
                        });
                    }
                });
            })
            .catch(err => {
                console.log("submit order error", err);
                this.setState({ loading: false });
                this._parsePayPalError(err);
                this.savePayPalLog(false, null, err);
            });

        return false;
    };

    render() {
        const {
            loading,
            isSdkReady,
            isFormReady,
            city,
            postalCode,
            primaryContactName,
            state,
            street1,
            payPalError,
            payPalErrorDetails
        } = this.state;

        return (
            <form
                className="card-form-wrapper"
                style={{
                    display: isSdkReady && isFormReady ? "block" : "none"
                }}
            >
                <div className="label-alternative-wrapper">
                    <div className="line" />
                    <div className="label-alternative">or</div>
                    <div className="line" />
                </div>
                <div>
                    <label htmlFor="card-number">Card Number</label>
                    <div id="card-number" className="card-field"></div>
                    <div className="row">
                        <div className="column">
                            <label htmlFor="expiration-date">
                                Expiration Date
                            </label>
                            <div
                                id="expiration-date"
                                className="card-field"
                            ></div>
                        </div>
                        <div className="column">
                            <label htmlFor="cvv">CVV</label>
                            <div id="cvv" className="card-field"></div>
                        </div>
                    </div>
                    <label htmlFor="card-holder-name">Name on Card</label>
                    <input
                        type="text"
                        id="card-holder-name"
                        name="card-holder-name"
                        autoComplete="off"
                        placeholder="Card holder name*"
                        required
                        error="Required"
                        value={primaryContactName}
                        onChange={this.handleFieldChange}
                    />
                    <div>
                        <label htmlFor="card-billing-address-street">
                            Billing Address
                        </label>
                        <input
                            type="text"
                            id="card-billing-address-street"
                            name="card-billing-address-street"
                            autoComplete="off"
                            placeholder="Street address*"
                            required
                            error="Required"
                            value={street1}
                            onChange={this.handleFieldChange}
                        />
                    </div>
                    <div className="row">
                        <div className="column">
                            <input
                                type="text"
                                id="card-billing-address-city"
                                name="card-billing-address-city"
                                autoComplete="off"
                                placeholder="City*"
                                required
                                error="Required"
                                value={city}
                                onChange={this.handleFieldChange}
                            />
                        </div>
                        <div className="column">
                            <select
                                id="card-billing-address-state"
                                name="card-billing-address-state"
                                autoComplete="billing address-level1"
                                required
                                aria-invalid="false"
                                error="Required"
                                placeholder="State"
                                value={state}
                                onChange={this.handleFieldChange}
                            >
                                <option value="">Select State*</option>
                                <option value="AL">Alabama</option>
                                <option value="AK">Alaska</option>
                                <option value="AS">American Samoa</option>
                                <option value="AZ">Arizona</option>
                                <option value="AR">Arkansas</option>
                                <option value="AA">
                                    Armed Forces Americas
                                </option>
                                <option value="AE">Armed Forces Europe</option>
                                <option value="AP">Armed Forces Pacific</option>
                                <option value="CA">California</option>
                                <option value="CO">Colorado</option>
                                <option value="CT">Connecticut</option>
                                <option value="DE">Delaware</option>
                                <option value="DC">District of Columbia</option>
                                <option value="FL">Florida</option>
                                <option value="GA">Georgia</option>
                                <option value="GU">Guam</option>
                                <option value="HI">Hawaii</option>
                                <option value="ID">Idaho</option>
                                <option value="IL">Illinois</option>
                                <option value="IN">Indiana</option>
                                <option value="IA">Iowa</option>
                                <option value="KS">Kansas</option>
                                <option value="KY">Kentucky</option>
                                <option value="LA">Louisiana</option>
                                <option value="ME">Maine</option>
                                <option value="MD">Maryland</option>
                                <option value="MA">Massachusetts</option>
                                <option value="MI">Michigan</option>
                                <option value="MN">Minnesota</option>
                                <option value="MS">Mississippi</option>
                                <option value="MO">Missouri</option>
                                <option value="MT">Montana</option>
                                <option value="NE">Nebraska</option>
                                <option value="NV">Nevada</option>
                                <option value="NH">New Hampshire</option>
                                <option value="NJ">New Jersey</option>
                                <option value="NM">New Mexico</option>
                                <option value="NY">New York</option>
                                <option value="NC">North Carolina</option>
                                <option value="ND">North Dakota</option>
                                <option value="MP">
                                    Northern Mariana Islands
                                </option>
                                <option value="OH">Ohio</option>
                                <option value="OK">Oklahoma</option>
                                <option value="OR">Oregon</option>
                                <option value="PA">Pennsylvania</option>
                                <option value="PR">Puerto Rico</option>
                                <option value="RI">Rhode Island</option>
                                <option value="SC">South Carolina</option>
                                <option value="SD">South Dakota</option>
                                <option value="TN">Tennessee</option>
                                <option value="TX">Texas</option>
                                <option value="UT">Utah</option>
                                <option value="VT">Vermont</option>
                                <option value="VI">Virgin Islands</option>
                                <option value="VA">Virginia</option>
                                <option value="WA">Washington</option>
                                <option value="WV">West Virginia</option>
                                <option value="WI">Wisconsin</option>
                                <option value="WY">Wyoming</option>
                            </select>
                        </div>
                        <div className="column">
                            <input
                                type="text"
                                id="card-billing-address-zip"
                                name="card-billing-address-zip"
                                autoComplete="off"
                                placeholder="ZIP code*"
                                required
                                error="Required"
                                value={postalCode}
                                onChange={this.handleFieldChange}
                            />
                        </div>
                    </div>

                    <div className="payment-button-wrapper">
                        <button
                            type="submit"
                            className="primary"
                            disabled={loading}
                            onClick={this.handleSubmitOrderClick}
                        >
                            Place Order
                        </button>
                    </div>
                </div>
                {payPalError && (
                    <ModalError
                        message={payPalError}
                        details={payPalErrorDetails}
                        onClick={() =>
                            this.setState({
                                payPalError: "",
                                payPalErrorDetails: ""
                            })
                        }
                    />
                )}
            </form>
        );
    }
}

const mapStateToProps = ({ order, profile }) => ({
    cartItems: order.items,
    customer: order.customerAccount,
    saleDetails: order.saleDetails,
    tax: order.tax,
    user: profile.user
});

export default withRouter(
    connect(
        mapStateToProps,
        {
            setPaymentSucceeded
        }
    )(FormPayPal)
);
