import React, { Component} from 'react';
import PropTypes from 'prop-types';

import Product from '../../components/product/Product';

import './product-list.scss';

class ProductList extends Component {

    static propTypes = {
        isLoading: PropTypes.bool,
        isModalDetailsVisible: PropTypes.bool,
        detailsProductId: PropTypes.string,
        hydrated: PropTypes.bool,
        products: PropTypes.object,
        cartItems: PropTypes.array,
        capabilities: PropTypes.object,
        onAddProduct: PropTypes.func,
        onRemoveProduct: PropTypes.func,
        onDetailsToggle: PropTypes.func
    }

    render() {

        const {
            products,
            cartItems,
            capabilities,
            isLoading,
            hydrated,
            isModalDetailsVisible,
            detailsProductId
        } = this.props;

        let productComponents = null;
        let hint = null;

        if (Object.keys(products).length > 0) {
            productComponents = Object.keys(products).map((productId, index) => {
                const product = products[productId];
                const cartItem = cartItems.find(ci => ci.id === product.id);
                return <Product
                    key={index}
                    isModalDetailsVisible={isModalDetailsVisible}
                    detailsProductId={detailsProductId}
                    product={product}
                    addedToCart={!!cartItem}
                    cartItem={cartItem}
                    onAddProduct={this.props.onAddProduct}
                    onRemoveProduct={this.props.onRemoveProduct}
                    onDetailsToggle={this.props.onDetailsToggle}
                />;
            });

            if (capabilities.ftr) {
                hint = <p className="hint">Install/Delivery Eligible Product</p>;
            }

        } else if (!isLoading && hydrated) {
            productComponents = <p className="empty-state">
                Unfortunately, there are no available products in your area
            </p>;
        }

        return (
            <main className="page-content-wrapper">
                <h1 data-title="products">Products</h1>

                <div className="product-list-wrapper">
                    {hint}
                    {productComponents}
                </div>
            </main>
        );
    }
}

export default ProductList;
