export default {
    SET_SALE_DETAILS: 'SET_SALE_DETAILS',
    GET_CUSTOMER_ACCOUNT: {
        LOADING: 'GET_CUSTOMER_ACCOUNT',
        SUCCESS: 'GET_CUSTOMER_ACCOUNT_SUCCESS',
        FAILED: 'GET_CUSTOMER_ACCOUNT_FAILED'
    },
    SET_CUSTOMER_DETAILS: 'SET_CUSTOMER_DETAILS',
    CLEAR_CUSTOMER_ACCOUNT: 'CLEAR_CUSTOMER_ACCOUNT',
    CLEAR_ORDER: 'CLEAR_ORDER',
    CLEAR_SALE_DETAILS: 'CLEAR_SALE_DETAILS',
    GET_DISCOUNT_REASONS : {
        LOADING: 'GET_DISCOUNT_REASONS',
        SUCCESS: 'GET_DISCOUNT_REASONS_SUCCESS',
        FAILED: 'GET_DISCOUNT_REASONS_FAILED'
    },
    GET_PROMO_CODE: {
        LOADING: 'GET_PROMO_CODE',
        SUCCESS: 'GET_PROMO_CODE_SUCCESS',
        FAILED: 'GET_PROMO_CODE_FAILED'
    },
    REMOVE_PROMO_CODE: 'REMOVE_PROMO_CODE',
    DISCOUNT: {
        SET: 'SET_DISCOUNT',
        REMOVE: 'REMOVE_DISCOUNT'
    },
    GET_TAX_RATES: {
        LOADING: 'GET_TAX_RATES',
        SUCCESS: 'GET_TAX_RATES_SUCCESS',
        FAILED: 'GET_TAX_RATES_FAILED'
    },
    PAYMENT_SUCCEEDED: 'PAYMENT_SUCCEEDED',
    SEND_INVOICE: {
        LOADING: 'SEND_INVOICE',
        SUCCESS: 'SEND_INVOICE_SUCCESS',
        FAILED: 'SEND_INVOICE_FAILED'
    },
    GET_INVOICE_STATUS: {
        LOADING: 'GET_INVOICE_STATUS',
        SUCCESS: 'GET_INVOICE_STATUS_SUCCESS',
        FAILED: 'GET_INVOICE_STATUS_FAILED'
    },
    CANCEL_PAYPAL_INVOICE: {
        LOADING: 'CANCEL_PAYPAL_INVOICE',
        SUCCESS: 'CANCEL_PAYPAL_INVOICE_SUCCESS',
        FAILED: 'CANCEL_PAYPAL_INVOICE_FAILED'
    },
    SUBMIT_ORDER: {
        LOADING: 'SUBMIT_ORDER',
        SUCCESS: 'SUBMIT_ORDER_SUCCESS',
        FAILED: 'SUBMIT_ORDER_FAILED'
    },
    SET_INSTALLATION_DATE: 'SET_INSTALLATION_DATE',
    SET_INSTALLATION_TIME: 'SET_INSTALLATION_TIME',
    SET_TERMS_SIGNED: 'SET_TERMS_SIGNED',
    GET_SCHEDULE_AVAILABILITY: {
        LOADING: 'GET_SCHEDULE_AVAILABILITY',
        SUCCESS: 'GET_SCHEDULE_AVAILABILITY_SUCCESS',
        FAILED: 'GET_SCHEDULE_AVAILABILITY_FAILED'
    },
    RESOLVE_ADDRESS: {
        LOADING: 'RESOLVE_ADDRESS',
        SUCCESS: 'RESOLVE_ADDRESS_SUCCESS',
        FAILED: 'RESOLVE_ADDRESS_FAILED'
    },
    SET_INVOICE: 'SET_INVOICE'
};
