import {
    ADD_ERROR,
    CLEAR_ERRORS
} from '../actions/types';

const initialState = [];

export default (state = initialState, action) => {

    switch (action.type) {
        case ADD_ERROR:
            return [ ...state, action.message];
        case CLEAR_ERRORS:
            return [];
        default:
            return state;
    }
};
