import React, { Component} from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import Modal from '../modal/Modal';

import { toggleModalZipLookup, toggleModalNoProducts } from '../../actions/catalogActions';

class ModalNoProducts extends Component {

    static propTypes = {
        history: PropTypes.object,
        zipLookup: PropTypes.object,
        toggleModalZipLookup: PropTypes.func,
        toggleModalNoProducts: PropTypes.func
    }

    handleYesClick = () => {

        this.props.toggleModalNoProducts(false, true);
        this.props.toggleModalZipLookup(true);
    }

    handleNoClick = () => {
        this.props.toggleModalNoProducts(false, true);
        this.props.history.replace({ pathname: '/' });
    }

    render() {

        const { zipLookup } = this.props;

        return (
            <Modal size={'s'}>
                <h1 data-title="No products available">No products available</h1>
                <p className="error-message-icon">
                    There are currently no products available for the zip code you entered - {zipLookup.zip}<br/>
                    Please check back for availability in the future. Do you want to enter a different zip code?
                </p>
                <p className="action-wrapper">
                    <button
                        onClick={this.handleYesClick}
                        className="primary">
                        Yes
                    </button>
                    <button
                        onClick={this.handleNoClick}
                        className="primary cancel">
                        No
                    </button>
                </p>
            </Modal>
        );
    }
}

const mapStateToProps = ({ catalog }) => ({
    zipLookup: catalog.zipLookup
});

export default withRouter(connect(mapStateToProps, {
    toggleModalZipLookup,
    toggleModalNoProducts
})(ModalNoProducts));
