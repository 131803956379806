import { createStore, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
import rootReducer from './reducers';
import { getInitialState, saveState } from './localStorage';


const persistedState = getInitialState();
const middleware = [thunk];
let store;

if (window.__REDUX_DEVTOOLS_EXTENSION__) {
    store = createStore(
        rootReducer,
        persistedState,
        compose(
            applyMiddleware(...middleware),
            window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
        )
    );
} else {
    store = createStore(
        rootReducer,
        persistedState,
        compose(
            applyMiddleware(...middleware)
        )
    );
}

store.subscribe(() => {
    saveState(store.getState());
});

export default store;
