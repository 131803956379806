import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import ClassNames from 'classnames';

import Header from '../../components/header/Header';
import Footer from '../../components/footer/Footer';
import ProductList from './components/product-list/ProductList';

import {
    changeProductQuantity,
    putProductToCart,
    removeProductFromCart,
    clearOrder
} from '../../actions/orderActions';
import { SessionTypes, FutureTruckRollItemCode } from '../../constants';

import './cart.scss';

class PageCart extends Component {

    static propTypes = {
        history: PropTypes.object,
        isOSC: PropTypes.bool,
        products: PropTypes.object,
        filteredProducts: PropTypes.object,
        cartItems: PropTypes.array,
        saleDetails: PropTypes.object,
        changeProductQuantity: PropTypes.func,
        putProductToCart: PropTypes.func,
        removeProductFromCart: PropTypes.func,
        clearOrder: PropTypes.func
    }

    constructor(props) {
        super(props);
        this.state = {};
    }

    componentDidMount() {
        const { cartItems, products } = this.props;
        const capabilities = this.getCapabilities();
        if (capabilities.ftr) {
            // add Future Truck Roll Charge to cart
            if (cartItems.filter((item) => !!item.futureTruckRoll).length > 0) {
                const alreadyAdded = !!cartItems.find((i) => i.itemCode === FutureTruckRollItemCode);
                if (!alreadyAdded) {
                    const futureTruckRollProductId = Object.keys(products).find(
                        (id) => products[id].itemCode === FutureTruckRollItemCode
                    );
                    if (futureTruckRollProductId) {
                        this.props.putProductToCart(products[futureTruckRollProductId]);
                    }
                }
            }
        }
    }

    handleCancelSaleClick = () => {

        const { isOSC } = this.props;

        this.props.clearOrder();
        if (isOSC) {
            this.props.history.replace({ pathname: '/create-order' });
        } else {
            this.props.history.replace({ pathname: '/' });
        }
    }

    handleCheckoutClick = () => {

        this.props.history.replace({ pathname: '/checkout' });
    }

    getCapabilities() {

        const { cartItems, filteredProducts, saleDetails, isOSC } = this.props;
        const capabilities = {
            ftr: false,
            checkout: false
        };

        if (cartItems.length > 0) {

            if (
                saleDetails.session === SessionTypes.TechAssistFTR ||
                saleDetails.session === SessionTypes.Consumer ||
                saleDetails.session === SessionTypes.EmployeesFF ||
                !isOSC
            ) {
                capabilities.ftr = true;
            }

            if (capabilities.ftr) {
                capabilities.checkout = cartItems.filter((item) => !!item.futureTruckRoll).length > 0;
            } else {
                capabilities.checkout = true;
            }

            if (capabilities.checkout) {
                // check if added products is available
                for (const cartItem of cartItems) {
                    if (!filteredProducts[cartItem.id] && cartItem.itemCode !== FutureTruckRollItemCode) {
                        capabilities.checkout = false;
                        break;
                    }
                }
            }
        }

        return capabilities;
    }

    render() {

        const { cartItems, filteredProducts } = this.props;
        const pageClasses = {
            'page': true,
            'page-cart': true
        };

        let productList = null;
        let emptyState = null;
        let condition = null;
        let cartActions = null;

        const capabilities = this.getCapabilities();


        if (cartItems.length > 0) {
            productList = <ProductList
                cartItems={cartItems}
                products={filteredProducts}
                changeProductQuantity={this.props.changeProductQuantity}
                removeProductFromCart={this.props.removeProductFromCart}
            />;

            cartActions = <div className="cart-actions-wrapper">
                <button className="link cancel-action" onClick={this.handleCancelSaleClick}>Cancel Sale</button>
                <button
                    disabled={!capabilities.checkout}
                    className="primary"
                    onClick={this.handleCheckoutClick}
                >
                    Checkout
                </button>
            </div>;

            if (capabilities.ftr) {
                condition = <span>
                    Please note, you must have at least one
                    <b><span className="truck-icon">Install/Delivery Eligible</span></b>
                    &nbsp;item in your cart to complete the order.
                </span>;
            }

        } else {
            emptyState = <p className="empty-state">
                Your shopping cart is empty
            </p>;
        }

        return (
            <div className={ClassNames(pageClasses)}>
                <Header />
                <div className="page-content-wrapper">

                    <div className="title-wrapper">
                        <h1 data-title="Cart">Cart</h1>
                        {condition}
                    </div>
                    {productList}
                    {emptyState}
                    {cartActions}
                </div>
                <Footer />
            </div>
        );
    }
}

const mapStateToProps = ({ catalog, order, profile }) => ({
    isOSC: !!profile.accessToken && profile.expired != null && !profile.expired,
    filteredProducts: catalog.filteredProducts,
    products: catalog.products,
    cartItems: order.items,
    saleDetails: order.saleDetails
});

export default withRouter(connect(mapStateToProps, {
    changeProductQuantity,
    removeProductFromCart,
    clearOrder,
    putProductToCart
})(PageCart));
