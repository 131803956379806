import CNST from './constants/index';

export const LOGIN = 'LOGIN';
export const LOGOUT = 'LOGOUT';
export const GET_CUSTOMER = 'GET_CUSTOMER';
// errors
export const ADD_ERROR = 'ADD_ERROR';
export const CLEAR_ERRORS = 'CLEAR_ERRORS';

export default CNST;
