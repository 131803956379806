import React, { Component} from 'react';
import PropTypes from 'prop-types';
import ClassNames from 'classnames';
import './preloader.scss';

class Preloader extends Component {

    static propTypes = {
        type: PropTypes.string,
        transparentOverlay: PropTypes.bool,
        finished: PropTypes.bool,
        message: PropTypes.string
    }

    static defaultProps = {
        type: 'overlay',
        transparentOverlay: false,
        finished: false,
        message: ''
    }

    render() {

        const { type, transparentOverlay, finished, message } = this.props;

        const preloaderClasses = {
            'preloader-wrapper': true,
            [type]: !!type,
            'finished': finished,
            transparent: transparentOverlay
        };

        let loader = null;

        if (type && type === 'overlay') {

            loader = <svg viewBox="0 0 10 10">
                <circle className="outer" cx="5" cy="5" r="4.5"/>
                <circle className="inner" cx="5" cy="5" r="3.5"/>
                <path className="check" d="M6.7705,4.0669L6.7705,4.0669c-0.0707-0.0707-0.1946-0.0707-0.2653,0L4.7365,
                    5.8356L3.8698,4.969
                    c-0.0707-0.0707-0.1946-0.0707-0.2653,0C3.5691,5.0043,3.5514,5.0574,3.5514,5.0928c0,0.0531,0.0177,
                    0.0884,0.0531,0.1238
                    L4.595,6.2247c0.0354,0.0354,0.0884,0.0531,0.1238,0.0531s0.0884-0.0177,0.1238-0.0531l1.9279-1.9102
                    C6.8412,4.2438,6.8412,4.1377,6.7705,4.0669z"/>
            </svg>;
        }


        return (
            <span className={ClassNames(preloaderClasses)}>
                {loader}
                {message ? <span>{message}</span> : null}
            </span>
        );
    }
}

export default Preloader;
