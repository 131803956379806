import React, { Component} from 'react';
import PropTypes from 'prop-types';
import Modal from '../../../../components/modal/Modal';
import TechAssistForm from '../tech-assist-form/TechAssistForm';
import { SessionOptions } from '../../../../constants';

import './modal-create-order.scss';

class ModalCreateOrder extends Component {
    static propTypes = {
        sessionType: PropTypes.string,
        lineOfBusiness: PropTypes.string,
        zipCode: PropTypes.string,
        agent: PropTypes.string,
        user: PropTypes.object
    }

    constructor(props) {
        super(props);

        this.state = {
            session: props.sessionType || ''
        };
    }

    onSessionChange = (event) => {
        this.setState({session: event.target.value});
    }

    render() {

        const { session } = this.state;
        const { user, lineOfBusiness, zipCode, agent } = this.props;

        const subFormLabel = <p className="subForm-label">
            <span>Enter additional information, please</span>
            <span className="hint">* Field is mandatory</span>
        </p>;

        return (
            <Modal size={'s'}>
                <h1 data-title="New Sale">New Sale</h1>

                <div className="authorize-admin-form">
                    <p className="form-item">
                        <label>Session type</label>
                        <select
                            value={session}
                            disabled={user.isReseller}
                            onChange={this.onSessionChange}>
                            <option value="">Select session type</option>
                            {
                                Object.keys(SessionOptions).map((sessionType, index) => {
                                    return <option key={index} value={sessionType}>
                                        {SessionOptions[sessionType]}
                                    </option>;
                                })
                            }
                        </select>
                    </p>

                    {session ? subFormLabel : null}
                    {session ? <TechAssistForm
                        sessionType={session}
                        lineOfBusiness={lineOfBusiness}
                        zipCode={zipCode}
                        agent={agent}
                    /> : null}

                </div>
            </Modal>
        );
    }
}

export default ModalCreateOrder;
