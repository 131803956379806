import React, { Component} from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import ClassNames from 'classnames';
import { isEmpty } from 'lodash';

import ProductsHelper from '../../helpers/products';

import Header from '../../components/header/Header';
import Footer from '../../components/footer/Footer';
import OrderDetails from './components/order-details/OrderDetails';
import ProductList from './components/product-list/ProductList';
import FormSubmitOrder from './components/form-submit-order/FormSubmitOrder';

import { SessionOptions } from '../../constants';

import {
    clearOrder,
    submitOrder
} from '../../actions/orderActions';

import './new-order-created.scss';

class NewOrderCreated extends Component {

    static propTypes = {
        history: PropTypes.object,
        isOSC: PropTypes.bool,
        isLoading: PropTypes.bool,
        account: PropTypes.object,
        customer: PropTypes.object,
        saleDetails: PropTypes.object,
        products: PropTypes.object,
        cartItems: PropTypes.array,
        discountReasons: PropTypes.array,
        promoCode: PropTypes.object,
        tax: PropTypes.object,
        invoice: PropTypes.object,
        workOrder: PropTypes.object,
        installationDate: PropTypes.object,
        installationTime: PropTypes.string,
        scheduleAvailabilityId: PropTypes.string,
        submitOrder: PropTypes.func,
        clearOrder: PropTypes.func
    }

    componentDidMount() {

        const { history, cartItems, workOrder, isOSC } = this.props;

        if (!isEmpty(cartItems) && isEmpty(workOrder)) {
            this.submitOrder();
        }

        if (isEmpty(cartItems) && isOSC) {
            history.replace({ pathname: '/create-order' });
            return;
        } else if (isEmpty(cartItems)) {
            history.replace({ pathname: '/' });
            return;
        }

        window.history.pushState(null, null, window.location.href);
        window.onpopstate = function() {
            window.history.go(1);
        };
    }

    componentWillUnmount() {
        window.onpopstate = null;
    }

    submitOrder = () => {

        const {
            customer,
            tax,
            account,
            cartItems,
            products,
            saleDetails,
            invoice,
            scheduleAvailabilityId,
            promoCode,
            discountReasons,
            isOSC,
            installationDate,
            installationTime
        } = this.props;

        const {
            externalId,
            accountId,
            primaryContactName,
            primaryContactPhone,
            primaryContactEmail,
            street1,
            city,
            state,
            suite,
            floor,
            postalCode
        } = customer;

        const {
            lines
        } = tax;

        const data = {
            order: {
                source: isOSC ? 'OSC Rep' : 'Consumer',
                ftrDate: installationDate,
                ftrTime: installationTime,
                amount: ProductsHelper.getCartTotalAmount(cartItems),
                technicianId: account.user.employee_number,
                workOrderNumber: externalId,
                workOrderNumberExt: saleDetails.workOrderNumber,
                accountId,
                avalaraReference: '',
                businessLine: saleDetails.lineOfBusiness,
                oscSessionType: SessionOptions[saleDetails.session] ? SessionOptions[saleDetails.session] : null,
                leadAgent: saleDetails.agent,
                scheduleAvailabilityId,
                customer: {
                    name: primaryContactName,
                    phone: primaryContactPhone,
                    email: primaryContactEmail,
                    address: street1,
                    city,
                    state,
                    suite: suite || '',
                    floor: floor || '',
                    zip: postalCode,
                    signature: ''
                }
            },
            products: []
        };
        if(invoice.type && invoice.type === 'direct'){
            data.order.paypalOrderId = invoice.id;
        } else {
            data.order.paypalInvoiceId = invoice.id;
        }

        cartItems.forEach((cartItem) => {
            let taxMaterial = 0;
            let taxLabor = 0;

            if (cartItem.hasMaterial && cartItem.materialItemCode) {
                const taxMaterialItem = lines.find(item => item.itemCode === cartItem.materialItemCode);
                if (taxMaterialItem) {
                    taxMaterial =
                        ProductsHelper.getTax(
                            taxMaterialItem,
                            cartItem.isDiscounted ? cartItem.totalDiscountMaterialPrice : cartItem.materialPrice
                        );
                }
            }
            if (cartItem.hasLabor && cartItem.laborItemCode) {
                const taxLaborItem = lines.find(item => item.itemCode === cartItem.laborItemCode);
                if (taxLaborItem) {
                    taxLabor =
                        ProductsHelper.getTax(
                            taxLaborItem,
                            cartItem.isDiscounted ? cartItem.totalDiscountLaborPrice : cartItem.laborPrice
                        );
                }
            }

            // getting discount name
            let reason = '';
            if (cartItem.discount) {
                const discountItem = discountReasons.find((i) => i.value === cartItem.discount.reason);
                if (discountItem) {
                    reason = discountItem.name;
                }
            }

            const res = {
                itemCode: cartItem.itemCode,
                names: {
                    winProductName: products[cartItem.id].names.winProductName,
                    customerProductName: products[cartItem.id].names.customerProductName
                },
                quantity: cartItem.quantity,
                amount: cartItem.isDiscounted ? cartItem.totalDiscountPrice : cartItem.price,
                tax: taxMaterial + taxLabor,
                laborSalesAmount: 0,
                origLaborSalesAmount: 0,
                laborTaxAmount: taxLabor,
                materialSalesAmount: 0,
                origMaterialSalesAmount: 0,
                materialTaxAmount: taxMaterial,
                discount: cartItem.discountAmount,
                discountPercentage: cartItem.discountPercent,
                discountReasonCode: reason,
                promoCode: promoCode ? promoCode.code : '',
                promoDiscount: cartItem.promoDiscount,
                promoCodePercentage: cartItem.promoPercent,
                discountTotal: cartItem.totalDiscount
            };

            if (cartItem.hasLabor) {
                res.origLaborSalesAmount = cartItem.laborPrice;
                if (cartItem.isDiscounted) {
                    res.laborSalesAmount = cartItem.totalDiscountLaborPrice;
                } else {
                    res.laborSalesAmount = cartItem.laborPrice;
                }
            }

            if (cartItem.hasMaterial) {
                res.origMaterialSalesAmount = cartItem.materialPrice;
                if (cartItem.isDiscounted) {
                    res.materialSalesAmount = cartItem.totalDiscountMaterialPrice;
                } else {
                    res.materialSalesAmount = cartItem.materialPrice;
                }
            }

            data.products.push(res);
        });

        data.order.tax = ProductsHelper.getTotalTax(tax);
        this.props.submitOrder(data);
    }

    handleNewSaleClick = () => {
        const { isOSC } = this.props;
        this.props.clearOrder();
        if (isOSC) {
            this.props.history.replace({ pathname: '/create-order' });
        } else {
            this.props.history.replace({ pathname: '/' });
        }
    }

    render() {

        const {
            customer,
            cartItems,
            workOrder,
            promoCode,
            tax,
            installationDate,
            installationTime,
            isLoading
        } = this.props;

        const pageClasses = {
            'page': true,
            'page-new-order': true
        };

        return (
            <div className={ClassNames(pageClasses)}>
                <Header/>
                <div className="page-content-wrapper">
                    <main>
                        {
                            isEmpty(workOrder)
                                ? <React.Fragment>
                                    <h1 data-title="Creating New Order">Creating New Order</h1>
                                    <FormSubmitOrder
                                        workOrder={workOrder}
                                        isLoading={isLoading}
                                        onSubmitOrder={this.submitOrder}
                                    />
                                </React.Fragment>
                                :
                                <React.Fragment>
                                    <h1 data-title="New order">New order #{workOrder.workOrderId}</h1>
                                    <ProductList
                                        cartItems={cartItems}
                                        promoCode={promoCode}
                                        tax={tax}
                                    />
                                    <div className="new-order-actions-wrapper">
                                        <button
                                            className="primary"
                                            onClick={this.handleNewSaleClick}
                                        >
                                            New sale
                                        </button>
                                    </div>
                                </React.Fragment>
                        }

                    </main>
                    {
                        !isEmpty(workOrder) ?
                            <aside>
                                <OrderDetails
                                    customer={customer}
                                    installationDate={installationDate}
                                    installationTime={installationTime}
                                />
                            </aside> : null
                    }

                </div>
                <Footer/>
            </div>
        );
    }
}

const mapStateToProps = ({ catalog, order, profile }) => ({
    isLoading: order.isLoading,
    account: profile,
    isOSC: !!profile.accessToken && profile.expired != null && !profile.expired,
    customer: order.customerAccount,
    products: catalog.products,
    saleDetails: order.saleDetails,
    cartItems: order.items,
    discountReasons: order.discountReasons,
    promoCode: order.promoCode,
    tax: order.tax,
    invoice: order.invoice,
    workOrder: order.workOrder,
    installationDate: order.installationDate,
    installationTime: order.installationTime,
    scheduleAvailabilityId: order.scheduleAvailabilityId
});

export default withRouter(connect(mapStateToProps, {
    submitOrder,
    clearOrder
})(NewOrderCreated));
