import React, { Component } from "react";
import ClassNames from "classnames";
import { Helmet } from "react-helmet";
import FormPayPal from "./components/form-paypal/FormPayPal";

class PageCheckout extends Component {
    static propTypes = {

    };

    constructor(props) {
        super(props);

        this.state = {};
    }

    componentDidMount() {}

    renderPaymentButtons = () => {
        const paypalForm = <FormPayPal />;

        return <>{paypalForm}</>;
    };

    render() {
        const pageClasses = {
            page: true
        };

        return (
            <div className={ClassNames(pageClasses)}>
                <Helmet>
                    <link rel="stylesheet" href="https://www.paypalobjects.com/webstatic/en_US/developer/docs/css/cardfields.css" />
                </Helmet>
                <main>{this.renderPaymentButtons()}</main>
            </div>
        );
    }
}

export default PageCheckout;
