
const isTokenPresentIn = (state) => {

    const parsed = JSON.parse(state);
    return parsed !== null
        && typeof parsed.profile !== 'undefined'
        && typeof parsed.profile.accessToken !== 'undefined';
};

export const setTokenInSession = (data) => {

    sessionStorage.setItem('state', JSON.stringify({ profile: data }));
};

export const getInitialState = () => {
    try {
        let localState = localStorage.getItem('state');
        const sessionState = sessionStorage.getItem('state');

        const isLocalSet = isTokenPresentIn(localState);
        const isSessionSet = isTokenPresentIn(sessionState);

        if (!isLocalSet && isSessionSet) {
            // Set local storage from session
            localStorage.setItem('state', sessionState);
            localState = sessionState;
        }

        if (localState === null) {
            return undefined;
        }

        return JSON.parse(localState);
    } catch (err) {
        return undefined;
    }
};

export const loadState = () => {
    try {
        let serializedState = localStorage.getItem('state');
        if (serializedState === null) {
            return undefined;
        }
        return JSON.parse(serializedState);
    } catch (err) {
        return undefined;
    }
};


export const saveState = (state) => {
    try {
        const serializedState = JSON.stringify(state);
        localStorage.setItem('state', serializedState);

    } catch {
        // ignore write errors
    }
};
