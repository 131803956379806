import { LOGIN, LOGOUT } from './types';

export function logout(redirect = true) {

    return (dispatch) => {

        dispatch({
            type: LOGOUT
        });

        if (redirect) {
            window.location.href = '/';
        }
    };
}

export function login(user) {
    return {
        type: LOGIN,
        user
    };
}
