import { cloneDeep } from 'lodash';
import TYPES from '../actions/types';

const initialState = {
    isLoading: false,
    isEmailsQueueLoading: false,
    isActionLoading: false,
    accessAllowed: false,
    emailsQueue: {}
};

export default function adminReducer(state = cloneDeep(initialState), action) {
    switch (action.type) {
        case TYPES.ADMIN.LOGIN.LOADING:
            return { ...state, isLoading: true };
        case TYPES.ADMIN.GET_EMAILS_QUEUE.LOADING:
            return { ...state, isEmailsQueueLoading: true };
        case TYPES.ADMIN.LOGIN.SUCCESS:
            return {
                ...state,
                isLoading: false,
                accessAllowed: action.success
            };
        case TYPES.ADMIN.GET_EMAILS_QUEUE.SUCCESS:
            return {
                ...state,
                isEmailsQueueLoading: false,
                emailsQueue: {
                    ...action.data,
                    refreshTokenDate: new Date(action.data.refreshTokenDate).toString(),
                    running: action.data.running ? 'Yes' : 'No',
                    paused: action.data.paused ? 'Yes' : 'No'
                }
            };
        case TYPES.ADMIN.LOGIN.FAILED:
            return { ...state, isLoading: false };
        case TYPES.ADMIN.GET_EMAILS_QUEUE.FAILED:
            return { ...state, isEmailsQueueLoading: false };
        case TYPES.ADMIN.RUN_ACTION.LOADING:
            return { ...state, isActionLoading: true };
        case TYPES.ADMIN.RUN_ACTION.SUCCESS:
            if (action.data) {
                return {
                    ...state,
                    isActionLoading: false,
                    emailsQueue: {
                        ...action.data.emailsQueue,
                        refreshTokenDate: new Date(
                            action.data.emailsQueue.refreshTokenDate
                        ).toString(),
                        running: action.data.emailsQueue.running ? 'Yes' : 'No',
                        paused: action.data.emailsQueue.paused ? 'Yes' : 'No'
                    }
                };
            }

            return {
                ...state,
                isActionLoading: false
            };

        case TYPES.ADMIN.RUN_ACTION.FAILED:
            return { ...state, isActionLoading: false };
        case TYPES.ADMIN.LOGOUT:
            return { ...state, ...initialState };
        default:
            return state;
    }
}
