import React, { Component} from 'react';
import PropTypes from 'prop-types';

import Modal from '../../../../components/modal/Modal';
import FormatHelper from '../../../../helpers/format';

import './modal-product.scss';

class ModalProduct extends Component {

    static propTypes = {
        product: PropTypes.object,
        cartItem: PropTypes.object,
        addedToCart: PropTypes.bool,
        onAddProduct: PropTypes.func,
        onRemoveProduct: PropTypes.func,
        onClose: PropTypes.func
    }

    constructor(props) {

        super(props);

        this.state = {
            activeSlide: 0,
            isSlideFirst: true,
            isSlideLast: props.product.details.images.length === 1
        };
    }

    changeSlide = (index) => {
        this.setState({
            activeSlide: index,
            isSlideFirst: index === 0,
            isSlideLast: index === (this.props.product.details.images.length - 1)
        });
    }

    getDetailsColumn(data) {
        const column = [];

        data.length && data.forEach((item, index) => {
            if (item.subHeading) {
                column.push(<h3 key={`sh_${index}`}>{item.subHeading}</h3>);
            }

            if (item.details) {
                column.push(<p key={`d_${index}`}>{item.details}</p>);
            }
        });

        return column;
    }

    render() {

        const { product, addedToCart } = this.props;

        const sliderImages = [];
        const sliderDotNav = [];

        product.details.images.forEach((item, index) => {

            const isActive = this.state.activeSlide === index;

            sliderImages.push(
                <span
                    key={index}
                    className={isActive ? 'active' : null}
                    data-index={index}
                    style={{backgroundImage: 'url(' + item.image + ')'}}>
                </span>
            );

            if (product.details.images.length > 2) {
                sliderDotNav.push(
                    <button
                        key={index}
                        className={isActive ? 'active dot-action' : 'dot-action'}
                        onClick={() => this.changeSlide(index)}
                        data-index={index}>
                    </button>
                );
            }
        });

        let addToCartButton = null;
        if (addedToCart) {
            addToCartButton = <button
                className="product-add-to-cart-action"
                onClick={this.props.onRemoveProduct}
            >
                <span className="price">{FormatHelper.formatPrice(product.totalPrice)}</span>
                Remove from cart
            </button>;
        } else {
            addToCartButton = <button
                className="product-add-to-cart-action"
                onClick={this.props.onAddProduct}
            >
                <span className="price">{FormatHelper.formatPrice(product.totalPrice)}</span>
                Add to cart
            </button>;
        }

        return (
            <Modal size={'l'} onClose={this.props.onClose}>
                <div className="modal-product-wrapper truck-eligible">
                    <h1 data-title={product.names.customerProductName}>
                        {product.names.customerProductName}
                    </h1>
                    <section className="slider">
                        <div className="images-wrapper">
                            {sliderImages}
                        </div>
                        <div className="dot-nav-wrapper">
                            {sliderDotNav}
                        </div>
                        <button
                            onClick={() => this.changeSlide(this.state.activeSlide - 1)}
                            disabled={this.state.isSlideFirst}
                            className="arrow-action show-prev">
                        </button>
                        <button
                            onClick={() => this.changeSlide(this.state.activeSlide + 1)}
                            disabled={this.state.isSlideLast}
                            className="arrow-action show-next">
                        </button>
                    </section>
                    {addToCartButton}
                    <div className="product-details-wrapper">
                        {
                            product.futureTruckRoll
                                ? <p className="truck-eligible-label">Install/Delivery Eligible</p>
                                : null
                        }
                        <div className="details-section">
                            {product.details.mainHeading1 ? <h2>{product.details.mainHeading1}</h2> : null}
                            {this.getDetailsColumn(product.details.mainHeading1Items)}
                        </div>

                        <div className="details-section">
                            {product.details.mainHeading2 ? <h2>{product.details.mainHeading2}</h2> : null}
                            {this.getDetailsColumn(product.details.mainHeading2Items)}
                            {
                                product.details.disclaimers
                                    ? <div className="disclaimer">{product.details.disclaimers}</div>
                                    : null
                            }
                        </div>
                    </div>
                </div>
            </Modal>
        );
    }
}

export default ModalProduct;
