import 'react-app-polyfill/ie9';
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { Callback } from 'react-oidc';
import { BrowserRouter, Route, Switch } from 'react-router-dom';

import store from './store';
import App from './App';
import Preloader from './components/preloader/Preloader';

import * as serviceWorker from './serviceWorker';

import userManager, { parseUserRoles } from './helpers/userManager';
import { login, logout } from './actions/loginActions';
import { getThirdPartyBrand } from './actions/catalogActions';

class LoginCallback extends Callback {
    render() {
        return (
            <Preloader type="overlay" />
        );
    }
}

ReactDOM.render(
    <Provider store={store}>
        <BrowserRouter>
            <Switch>
                <Route
                    path="/signin-oidc"
                    render={props => {
                        return <LoginCallback
                            onSuccess={async user => {
                                // `user.state` will reflect the state that was passed in via signinArgs.
                                parseUserRoles(user);

                                if (user.profile.isReseller) {
                                    const brand = await getThirdPartyBrand(user.profile.resellerType);
                                    if (brand) {
                                        user.profile.thirdPartyLogo = brand.logo;
                                    }
                                }

                                store.dispatch(login(user));

                                // check if we have create order parameters passed
                                const createOrderParams = localStorage.getItem('createOrderParams');
                                if (createOrderParams) {
                                    localStorage.removeItem('createOrderParams');
                                }

                                props.history.replace({ pathname: '/create-order', search: createOrderParams }, );
                            }}
                            onError={() => {}}
                            userManager={userManager}
                        />;
                    }}
                />
                <Route
                    path="/signout-oidc"
                    render={(routeProps) => {
                        store.dispatch(logout(false));
                        routeProps.history.replace('/');
                    }}
                />
                <App />
            </Switch>
        </BrowserRouter>
    </Provider>,
    document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
