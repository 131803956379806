import React, { Component} from 'react';
import PropTypes from 'prop-types';

import Modal from '../modal/Modal';

class ModalError extends Component {

    static propTypes = {
        message: PropTypes.string,
        details: PropTypes.string,
        onClick: PropTypes.func
    }

    handleOkClick = () => {
        this.props.onClick();
    }

    render() {
        const { message, details } = this.props;

        return (
            <Modal size={'s'}>
                <h1 data-title="Error">Error</h1>
                <p className="error-message-icon">
                    {message}
                    {
                        details ? <>
                            <br/><br/>
                            {details}
                        </> : null
                    }
                </p>
                <p className="action-wrapper">
                    <button
                        onClick={this.handleOkClick}
                        className="primary">
                        OK
                    </button>
                </p>
            </Modal>
        );
    }
}

export default ModalError;
