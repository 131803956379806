import React from 'react';
import PropTypes from 'prop-types';
import { Redirect, Route } from 'react-router-dom';

const MastecRoute = ({ component: Component, ...rest }) => {

    const isOSC = rest.isOSC || false;
    const user = rest.user || false;

    return (
        <Route
            {...rest}
            render={props => {
                if (isOSC) {
                    if (user && user.isAccessDenied) {
                        return <Redirect to={{ pathname: '/access-denied' }} />;
                    }
                } else {
                    return <Redirect to={{ pathname: '/OSCLogin' }} />;
                }

                return <Component {...props} />;
            }}
        />
    );
};

MastecRoute.propTypes = {
    component: PropTypes.func
};

export default MastecRoute;
