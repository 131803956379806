import React, { Component} from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter, NavLink } from 'react-router-dom';
import ClassNames from 'classnames';
import { isEmpty, isEqual } from 'lodash';
import { v4 as uuidv4 } from 'uuid';
import moment from 'moment';
import {PayPalButton} from "react-paypal-button-v2";

import ProductsHelper from '../../helpers/products';
import FormatHelper from '../../helpers/format';

import Header from '../../components/header/Header';

import Footer from '../../components/footer/Footer';
import FormCustomer from './components/form-customer/FormCustomer';
import FormSale from './components/form-sale/FormSale';
import ProductList from './components/product-list/ProductList';
import ModalTerms from './components/modal-terms/ModalTerms';
import ModalEmail from './components/modal-email/ModalEmail';
import Preloader from '../../components/preloader/Preloader';
import ModalZipLookUp from '../../components/modal-zip-lookup/ModalZipLookUp';
import ModalNoProducts from '../../components/modal-no-products/ModalNoProducts';
import ModalZipCodeChangeNotice from './components/modal-zipcode-change-notice/ModalZipcodeChangeNotice';
import ModalInvoiceWarning from './components/modal-invoice-warning/ModalInvoiceWarning';
import ModalError from '../../components/modal-error/ModalError';

import { SessionTypes } from '../../constants';

import { lookUpZip, toggleModalNoProducts } from '../../actions/catalogActions';

import {
    clearOrder,
    clearSaleDetails,
    getDiscountReasons,
    getPromoCode,
    setDiscount,
    removeDiscount,
    removePromoCode,
    getTaxRates,
    setPaymentSucceeded,
    setInstallationDate,
    setInstallationTime,
    setTermsSigned,
    getScheduleAvailability,
    captureOrder,
    sendPayPalLog,
    getInvoice,
    setInvoice
} from '../../actions/orderActions';

import './checkout.scss';
const clientId = process.env.REACT_APP_PAYPAL_CLIENT_ID;

class PageCheckout extends Component {

    static propTypes = {
        history: PropTypes.object,
        isLoading: PropTypes.bool,
        isGetScheduleAvailabilityLoading: PropTypes.bool,
        isOSC: PropTypes.bool,
        user: PropTypes.object,
        customer: PropTypes.object,
        zipLookup: PropTypes.object,
        saleDetails: PropTypes.object,
        cartItems: PropTypes.array,
        discountReasons: PropTypes.array,
        promoCode: PropTypes.object,
        tax: PropTypes.object,
        invoice: PropTypes.object,
        installationDate: PropTypes.object,
        installationTime: PropTypes.string,
        scheduleAvailability: PropTypes.array,
        isTermsSigned: PropTypes.bool,
        modalZipLookUpVisible: PropTypes.bool,
        modalNoProductsVisible: PropTypes.bool,
        products: PropTypes.object,
        warehousesData: PropTypes.object,
        errors: PropTypes.object,
        getDiscountReasons: PropTypes.func,
        clearOrder: PropTypes.func,
        clearSaleDetails: PropTypes.func,
        setDiscount: PropTypes.func,
        removeDiscount: PropTypes.func,
        getPromoCode: PropTypes.func,
        removePromoCode: PropTypes.func,
        getTaxRates: PropTypes.func,
        setPaymentSucceeded: PropTypes.func,
        setInstallationDate: PropTypes.func,
        setInstallationTime: PropTypes.func,
        setTermsSigned: PropTypes.func,
        getScheduleAvailability: PropTypes.func,
        lookUpZip: PropTypes.func,
        setInvoice: PropTypes.func
    }

    constructor(props) {
        super(props);

        this.state = {
            isCustomerEditable: isEmpty(props.customer),
            isTermsVisible: false,
            isEmailModalVisible: false,
            modalZipCodeChangeNoticeVisible: false,
            modalInvoiceWarningVisible: false,
            invoiceId: '',
            invoiceStatus: '',
            errors: {
                ...props.errors
            },
            payPalError: '',
            payPalErrorDetails: ''
        };
    }

    componentDidMount() {
        const { history, cartItems, isOSC, customer, saleDetails, invoice } = this.props;

        if (isEmpty(cartItems) && isOSC) {
            history.replace({ pathname: '/create-order' });
            return;
        } else if (isEmpty(cartItems)) {
            history.replace({ pathname: '/' });
            return;
        } else if (invoice && invoice.status === 'SENT') {
            history.replace({ pathname: '/checkout-with-invoice' });
            return;
        }

        const capabilities = this.getCapabilities();
        if (capabilities.calendar) {
            this.props.getScheduleAvailability(customer, cartItems, saleDetails.lineOfBusiness);
        }

        this.props.getDiscountReasons();
        this.fetchTaxRates();
    }

    componentDidUpdate(prevProps) {

        const { customer, cartItems, promoCode, invoice, saleDetails } = this.props;

        if (
            !isEqual(prevProps.customer, customer) ||
            !isEqual(prevProps.cartItems, cartItems) ||
            !isEqual(prevProps.promoCode, promoCode)
        ) {
            this.fetchTaxRates();
        }

        if (prevProps.invoice.status !== 'PAID' && invoice.status === 'PAID') {
            this.props.history.replace({ pathname: '/new-order-created' });
        }

        if (!isEqual(prevProps.customer, customer)) {
            const capabilities = this.getCapabilities();
            if (capabilities.calendar) {
                this.props.getScheduleAvailability(customer, cartItems, saleDetails.lineOfBusiness);
            }

            if (prevProps.customer.postalCode) {
                if (!customer.postalCode.includes(prevProps.customer.postalCode)) {
                    this.zipLookUp(customer.postalCode);
                }
            } else if (!customer.postalCode.includes(saleDetails.zipCode)) {
                this.zipLookUp(customer.postalCode);
            }

            // try to get possible already sent invoice
            getInvoice(customer, cartItems).then((invoice) => {
                if (invoice) {
                    this.setState({
                        invoiceId: invoice.invoiceId,
                        invoiceStatus: invoice.status,
                        modalInvoiceWarningVisible: true
                    });
                }
            });
        }
    }

    zipLookUp(zipCode) {

        if (zipCode.includes('-')) {
            zipCode = zipCode.substring(0, zipCode.indexOf('-'));
        }

        const { saleDetails, isOSC, cartItems, products, warehousesData } = this.props;

        if (zipCode) {
            this.props.lookUpZip(
                zipCode,
                saleDetails.session,
                saleDetails.lineOfBusiness,
                saleDetails.lineOfBusinessId,
                isOSC,
                { products, warehousesData },
                (success, zipLookup, filteredProducts) => {
                    if (success) {
                        const notAvailableProducts = cartItems.filter((item) => !filteredProducts[item.id]);
                        if (notAvailableProducts.length > 0) {
                            this.setState({
                                modalZipCodeChangeNoticeVisible: true,
                                noProducts: Object.keys(filteredProducts).length === 0
                            });
                        }
                    }
                }
            );
        }
    }

    onCloseModalZipCodeChangeNotice = () => {
        this.setState({
            modalZipCodeChangeNoticeVisible: false
        });
    }

    onCloseModalInvoiceWarning = () => {
        this.setState({
            modalInvoiceWarningVisible: false
        });
    }

    handleSetInvoice = () => {
        const { invoiceId, invoiceStatus } = this.state;
        const { history, setInvoice } = this.props;

        setInvoice(invoiceId, invoiceStatus);

        history.push({ pathname: '/checkout-with-invoice' });
    }

    toggleCustomerDetails = () => {
        const { isCustomerEditable } = this.state;

        this.setState({
            isCustomerEditable: !isCustomerEditable
        });
    }

    toggleTerms = () => {

        const { customer } = this.props;
        if (customer.primaryContactEmail) {
            this.setState({isTermsVisible: !this.state.isTermsVisible});
        } else {
            this.setState({
                isCustomerEditable: true,
                isEmailModalVisible: true
            });
        }
    }

    toggleEmail = () => {
        this.setState({isEmailModalVisible: !this.state.isEmailModalVisible});
    }

    onTermsSubmit = () => {
        this.toggleTerms();
        this.props.setTermsSigned();
    }

    onCustomerDetailsSubmit = () => {
        this.toggleCustomerDetails();
    }

    fetchTaxRates = () => {

        const { customer, cartItems, saleDetails } = this.props;

        if ((!isEmpty(customer) || saleDetails.zipCode) && cartItems.length > 0) {
            const {street1, city, state, postalCode} = customer || '';

            const data = {
                orderDate: moment().format('YYYY-MM-DD'),
                address: {
                    line1: street1 || '',
                    city: city || '',
                    region: state || '',
                    postalCode: postalCode || saleDetails.zipCode
                },
                products: []
            };

            cartItems.forEach((item) => {
                if (item.hasMaterial && item.materialTaxCode) {
                    let amount = item.materialPrice * item.quantity;
                    if (item.isDiscounted) {
                        amount = item.totalDiscountMaterialPrice * item.quantity;
                    }
                    const materialRes = {
                        quantity: item.quantity,
                        amount,
                        taxCode: item.materialTaxCode,
                        itemCode: item.materialItemCode
                    };
                    data.products.push(materialRes);
                }
                if (item.hasLabor && item.laborTaxCode) {
                    let amount = item.laborPrice * item.quantity;
                    if (item.isDiscounted) {
                        amount = item.totalDiscountLaborPrice * item.quantity;
                    }
                    const laborRes = {
                        quantity: item.quantity,
                        amount,
                        taxCode: item.laborTaxCode,
                        itemCode: item.laborItemCode
                    };
                    data.products.push(laborRes);
                }
            });

            this.props.getTaxRates(data);
        }
    }

    _getNames(name) {

        const names = {
            given_name: '',
            surname: ''
        };

        name = name.trim();
        const nameParts = name.split(' ');

        if (nameParts.length > 1) {

            for (let namePart of nameParts) {

                namePart = namePart.trim();

                if (namePart && !names.given_name) {
                    names.given_name = namePart;
                } else if (namePart) {
                    names.surname += `${namePart} `;
                }
            }
        } else {
            names.given_name = nameParts[0];
        }

        names.given_name = names.given_name.trim();
        names.surname = names.surname.trim();

        return names;
    }

    _parsePayPalProcessorResponse = (paymentSource, response) => {
        let message = '';
        const { avs_code, cvv_code, response_code  } = response;
        const { card } = paymentSource;
        const cardBrand = card || card.brand;

        if (avs_code) {
            switch(avs_code) {
                case 'A':
                    message = 'The address matches but the zip code does not match. ';
                    break;
                case 'E':
                    if (cardBrand === 'AMEX') {
                        message = 'The name is incorrect but the address and postal code match. ';
                    } else {
                        message = 'Not allowed for Internet or phone transactions. ';
                    }
                    break;
                case 'G':
                    message = 'Global is unavailable. Nothing matches. ';
                    break;
                case 'I':
                    message = 'International is unavailable. Not applicable. ';
                    break;
                case 'N':
                    if (cardBrand === 'AMEX') {
                        message = 'The address and postal code are both incorrect. ';
                    } else {
                        message = 'Address not match. ';
                    }
                    break;
                case 'S':
                    message = 'The service is not supported. ';
                    break;
                case 'U':
                    if (cardBrand === 'MAESTRO') {
                        // eslint-disable-next-line max-len
                        message = 'The address is not checked or the acquirer had no response. The service is not available. ';
                    } else if (cardBrand === 'AMEX') {
                        message = 'Information is not available. ';
                    } else {
                        message = 'The service is unavailable. ';
                    }
                    break;
                case 'W':
                    if (cardBrand === 'AMEX') {
                        message = 'The card holder name, address, and postal code are all incorrect. ';
                    } else {
                        message = 'Whole ZIP code. ';
                    }
                    break;
                case 'X':
                    if (cardBrand === 'AMEX') {
                        message = 'The card holder name, address, and postal code are all incorrect. ';
                    } else {
                        message = 'Exact match of the address and the nine-digit ZIP code. ';
                    }
                    break;
                case 'Y':
                    if (cardBrand === 'AMEX') {
                        message = 'The address and five-digit ZIP code match. ';
                    } else {
                        message = 'Exact match of the address and the nine-digit ZIP code. ';
                    }
                    break;
                case 'Z':
                    if (cardBrand === 'AMEX') {
                        message = 'Only the card holder postal code is correct. ';
                    } else {
                        message = 'The five-digit ZIP code matches but no address. ';
                    }
                    break;
            }
        }

        if (cvv_code) {
            switch (cvv_code) {
                case 'E':
                    message += 'CVV2: Error - unrecognized or unknown response. ';
                    break;
                case 'I':
                    message += 'CVV2: Invalid or null. ';
                    break;
                case 'M':
                    message += 'The CVV2/CSC matches. ';
                    break;
                case 'N':
                    message += 'The CVV2/CSC does not match. ';
                    break;
                case 'P':
                    message += 'The CVV2/CSC was not processed. ';
                    break;
                case 'S':
                    message += 'CVV2: The service is not supported. ';
                    break;
                case 'U':
                    message += 'CVV2: Unknown - the issuer is not certified. ';
                    break;
                case 'X':
                    message += 'CVV2: No response. ';
                    break;
            }
        }

        if (response_code) {
            switch (response_code) {
                case '0000':
                    message += '(APPROVED)';
                    break;
                case '0100':
                    message += '(REFERRAL)';
                    break;
                case '0800':
                    message += '(BAD_RESPONSE_REVERSAL_REQUIRED)';
                    break;
                case '1000':
                    message += '(PARTIAL_AUTHORIZATION)';
                    break;
                case '1300':
                    message += '(INVALID_DATA_FORMAT)';
                    break;
                case '1310':
                    message += '(INVALID_AMOUNT)';
                    break;
                case '1312':
                    message += '(INVALID_TRANSACTION_CARD_ISSUER_ACQUIRER)';
                    break;
                case '1317':
                    message += '(INVALID_CAPTURE_DATE)';
                    break;
                case '1320':
                    message += '(INVALID_CURRENCY_CODE)';
                    break;
                case '1330':
                    message += '(INVALID_ACCOUNT)';
                    break;
                case '1335':
                    message += '(INVALID_ACCOUNT_RECURRING)';
                    break;
                case '1340':
                    message += '(INVALID_TERMINAL)';
                    break;
                case '1350':
                    message += '(INVALID_MERCHANT)';
                    break;
                case '1360':
                    message += '(BAD_PROCESSING_CODE)';
                    break;
                case '1370':
                    message += '(INVALID_MCC)';
                    break;
                case '1380':
                    message += '(INVALID_EXPIRATION)';
                    break;
                case '1382':
                    message += '(INVALID_CARD_VERIFICATION_VALUE)';
                    break;
                case '1384':
                    message += '(INVALID_LIFE_CYCLE_OF_TRANSACTION)';
                    break;
                case '1390':
                    message += '(INVALID_ORDER)';
                    break;
                case '1393':
                    message += '(TRANSACTION_CANNOT_BE_COMPLETED)';
                    break;
                case '0500':
                    message += '(DO_NOT_HONOR)';
                    break;
                case '5100':
                    message += '(GENERIC_DECLINE)';
                    break;
                case '5110':
                    message += '(CVV2_FAILURE)';
                    break;
                case '5120':
                    message += '(INSUFFICIENT_FUNDS)';
                    break;
                case '5130':
                    message += '(INVALID_PIN)';
                    break;
                case '5140':
                    message += '(CARD_CLOSED)';
                    break;
                case '5150':
                    message += '(PICKUP_CARD_SPECIAL_CONDITIONS)';
                    break;
                case '5160':
                    message += '(UNAUTHORIZED_USER)';
                    break;
                case '5170':
                    message += '(AVS_FAILURE)';
                    break;
                case '5180':
                    message += '(INVALID_OR_RESTRICTED_CARD)';
                    break;
                case '5190':
                    message += '(SOFT_AVS)';
                    break;
                case '5200':
                    message += '(DUPLICATE_TRANSACTION)';
                    break;
                case '5210':
                    message += '(INVALID_TRANSACTION)';
                    break;
                case '5400':
                    message += '(EXPIRED_CARD)';
                    break;
                case '5500':
                    message += '(INCORRECT_PIN_REENTER)';
                    break;
                case '5700':
                    message += '(TRANSACTION_NOT_PERMITTED)';
                    break;
                case '5800':
                    message += '(REVERSAL_REJECTED)';
                    break;
                case '5900':
                    message += '(INVALID_ISSUE)';
                    break;
                case '5910':
                    message += '(ISSUER_NOT_AVAILABLE_NOT_RETRIABLE)';
                    break;
                case '5920':
                    message += '(ISSUER_NOT_AVAILABLE_RETRIABLE)';
                    break;
                case '6300':
                    message += '(ACCOUNT_NOT_ON_FILE)';
                    break;
                case '7600':
                    message += '(APPROVED_NON_CAPTURE)';
                    break;
                case '7700':
                    message += '(ERROR_3DS)';
                    break;
                case '7710':
                    message += '(AUTHENTICATION_FAILED)';
                    break;
                case '7800':
                    message += '(BIN_ERROR)';
                    break;
                case '7900':
                    message += '(PIN_ERROR)';
                    break;
                case '8000':
                    message += '(PROCESSOR_SYSTEM_ERROR)';
                    break;
                case '8010':
                    message += '(HOST_KEY_ERROR)';
                    break;
                case '8020':
                    message += '(CONFIGURATION_ERROR)';
                    break;
                case '8030':
                    message += '(UNSUPPORTED_OPERATION)';
                    break;
                case '8100':
                    message += '(FATAL_COMMUNICATION_ERROR)';
                    break;
                case '8110':
                    message += '(RETRIABLE_COMMUNICATION_ERROR)';
                    break;
                case '8220':
                    message += '(SYSTEM_UNAVAILABLE)';
                    break;
                case '9100':
                    message += '(DECLINED_PLEASE_RETRY)';
                    break;
                case '9500':
                    message += '(SUSPECTED_FRAUD)';
                    break;
                case '9510':
                    message += '(SECURITY_VIOLATION)';
                    break;
                case '9520':
                    message += '(LOST_OR_STOLEN)';
                    break;
                case '9530':
                    message += '(HOLD_CALL_CENTER)';
                    break;
                case '9540':
                    message += '(REFUSED_CARD)';
                    break;
                case '9600':
                    message += '(UNRECOGNIZED_RESPONSE_CODE)';
                    break;
                case '5930':
                    message += '(CARD_NOT_ACTIVATED)';
                    break;
                case 'PPMD':
                    message += '(PPMD)';
                    break;
                case 'PPCE':
                    message += '(CE_REGISTRATION_INCOMPLETE)';
                    break;
                case 'PPNT':
                    message += '(NETWORK_ERROR)';
                    break;
                case 'PPCT':
                    message += '(CARD_TYPE_UNSUPPORTED)';
                    break;
                case 'PPTT':
                    message += '(TRANSACTION_TYPE_UNSUPPORTED)';
                    break;
                case 'PPCU':
                    message += '(CURRENCY_USED_INVALID)';
                    break;
                case 'PPQC':
                    message += '(QUASI_CASH_UNSUPPORTED)';
                    break;
                case 'PPVE':
                    message += '(VALIDATION_ERROR)';
                    break;
                case 'PPVT':
                    message += '(VIRTUAL_TERMINAL_UNSUPPORTED)';
                    break;
                case 'PPDC':
                    message += '(DCC_UNSUPPORTED)';
                    break;
                case 'PPER':
                    message += '(INTERNAL_SYSTEM_ERROR)';
                    break;
                case 'PPIM':
                    message += '(ID_MISMATCH)';
                    break;
                case 'PPH1':
                    message += '(H1_ERROR)';
                    break;
                case 'PPSD':
                    message += '(STATUS_DESCRIPTION)';
                    break;
                case 'PPAG':
                    message += '(ADULT_GAMING_UNSUPPORTED)';
                    break;
                case 'PPLS':
                    message += '(LARGE_STATUS_CODE)';
                    break;
                case 'PPCO':
                    message += '(COUNTRY)';
                    break;
                case 'PPAD':
                    message += '(BILLING_ADDRESS)';
                    break;
                case 'PPAU':
                    message += '(MCC_CODE)';
                    break;
                case 'PPUC':
                    message += '(CURRENCY_CODE_UNSUPPORTED)';
                    break;
                case 'PPUR':
                    message += '(UNSUPPORTED_REVERSAL)';
                    break;
                case 'PPVC':
                    message += '(VALIDATE_CURRENCY)';
                    break;
                case 'PPS0':
                    message += '(BANKAUTH_ROW_MISMATCH)';
                    break;
                case 'PPS1':
                    message += '(BANKAUTH_ROW_SETTLED)';
                    break;
                case 'PPS2':
                    message += '(BANKAUTH_ROW_VOIDED)';
                    break;
                case 'PPS3':
                    message += '(BANKAUTH_EXPIRED)';
                    break;
                case 'PPS4':
                    message += '(CURRENCY_MISMATCH)';
                    break;
                case 'PPS5':
                    message += '(CREDITCARD_MISMATCH)';
                    break;
                case 'PPS6':
                    message += '(AMOUNT_MISMATCH)';
                    break;
                case 'PPRF':
                    message += '(INVALID_PARENT_TRANSACTION_STATUS)';
                    break;
                case 'PPEX':
                    message += '(EXPIRY_DATE)';
                    break;
                case 'PPAX':
                    message += '(AMOUNT_EXCEEDED)';
                    break;
                case 'PPDV':
                    message += '(AUTH_MESSAGE)';
                    break;
                case 'PPDI':
                    message += '(DINERS_REJECT)';
                    break;
                case 'PPAR':
                    message += '(AUTH_RESULT)';
                    break;
                case 'PPBG':
                    message += '(BAD_GAMING)';
                    break;
                case 'PPGR':
                    message += '(GAMING_REFUND_ERROR)';
                    break;
                case 'PPCR':
                    message += '(CREDIT_ERROR)';
                    break;
                case 'PPAI':
                    message += '(AMOUNT_INCOMPATIBLE)';
                    break;
                case 'PPIF':
                    message += '(IDEMPOTENCY_FAILURE)';
                    break;
                case 'PPMC':
                    message += '(BLOCKED_Mastercard)';
                    break;
                case 'PPAE':
                    message += '(AMEX_DISABLED)';
                    break;
                case 'PPFV':
                    message += '(FIELD_VALIDATION_FAILED)';
                    break;
                case 'PPII':
                    message += '(INVALID_INPUT_FAILURE)';
                    break;
                case 'PPPM':
                    message += '(INVALID_PAYMENT_METHOD)';
                    break;
                case 'PPUA':
                    message += '(USER_NOT_AUTHORIZED)';
                    break;
                case 'PPFI':
                    message += '(INVALID_FUNDING_INSTRUMENT)';
                    break;
                case 'PPEF':
                    message += '(EXPIRED_FUNDING_INSTRUMENT)';
                    break;
                case 'PPFR':
                    message += '(RESTRICTED_FUNDING_INSTRUMENT)';
                    break;
                case 'PPEL':
                    message += '(EXCEEDS_FREQUENCY_LIMIT)';
                    break;
                case 'PCVV':
                    message += '(CVV_FAILURE)';
                    break;
                case 'PPTV':
                    message += '(INVALID_VERIFICATION_TOKEN)';
                    break;
                case 'PPTE':
                    message += '(VERIFICATION_TOKEN_EXPIRED)';
                    break;
                case 'PPPI':
                    message += '(INVALID_PRODUCT)';
                    break;
                case 'PPIT':
                    message += '(INVALID_TRACE_ID)';
                    break;
                case 'PPTF':
                    message += '(INVALID_TRACE_REFERENCE)';
                    break;
                case 'PPFE':
                    message += '(FUNDING_SOURCE_ALREADY_EXISTS)';
                    break;
                case 'PPTR':
                    message += '(VERIFICATION_TOKEN_REVOKED)';
                    break;
                case 'PPTI':
                    message += '(INVALID_TRANSACTION_ID)';
                    break;
                case 'PPD3':
                    message += '(SECURE_ERROR_3DS)';
                    break;
                case 'PPPH':
                    message += '(NO_PHONE_FOR_DCC_TRANSACTION)';
                    break;
                case 'PPAV':
                    message += '(ARC_AVS)';
                    break;
                case 'PPC2':
                    message += '(ARC_CVV)';
                    break;
                case 'PPLR':
                    message += '(LATE_REVERSAL)';
                    break;
                case 'PPNC':
                    message += '(NOT_SUPPORTED_NRC)';
                    break;
                case 'PPRR':
                    message += '(MERCHANT_NOT_REGISTERED)';
                    break;
                case 'PPSC':
                    message += '(ARC_SCORE)';
                    break;
                case 'PPSE':
                    message += '(AMEX_DENIED)';
                    break;
                case 'PPUE':
                    message += '(UNSUPPORT_ENTITY)';
                    break;
                case 'PPUI':
                    message += '(UNSUPPORT_INSTALLMENT)';
                    break;
                case 'PPUP':
                    message += '(UNSUPPORT_POS_FLAG)';
                    break;
                case 'PPRE':
                    message += '(UNSUPPORT_REFUND_ON_PENDING_BC)';
                    break;
            }
        }

        return message;
    };

    getPaypalOrder() {

        const { customer, tax, cartItems } = this.props;
        const sum = ProductsHelper.getCartTotalAmount(cartItems);

        const order = {
            payer: {
                name: this._getNames(customer.primaryContactName)
                /*
                address: {
                    address_line_1:  customer.street1,
                    address_line_2: customer.suite + ' ' + customer.floor,
                    admin_area_2: customer.city,
                    admin_area_1: customer.state,
                    postal_code: customer.postalCode,
                    country_code: 'US'
                },
                email_address: customer.primaryContactEmail,
                phone: {
                    phone_number: {
                        national_number: this.getPhone()
                    }
                }
                */
            },
            purchase_units: [{
                amount: {
                    currency_code: "USD",
                    value: FormatHelper.roundNumber(sum - -ProductsHelper.getTotalTax(tax), 2)
                },
                shipping: {
                    name: {
                        full_name: customer.primaryContactName
                    }
                    /*
                    address: {
                        address_line_1: customer.street1,
                        address_line_2: customer.suite + ' ' + customer.floor,
                        admin_area_1: customer.state,
                        admin_area_2: customer.city,
                        postal_code: customer.postalCode,
                        country_code: 'US'
                    }
                    */
                },
                invoice_id: uuidv4()
            }]
        };

        return order;
    }

    getPhone() {
        const {customer} = this.props;
        let phone = customer.primaryContactPhone.replace(/\s/g, '');
        phone = phone.includes('+')
            ? phone.substring(2)
            : phone;
        return phone;
    }

    getCapabilities() {

        const {
            cartItems,
            saleDetails,
            customer,
            installationDate,
            installationTime,
            isTermsSigned,
            errors
        } = this.props;
        const { isCustomerEditable } = this.state;
        const capabilities = {
            calendar: false,
            checkout: false,
            payment: false,
            discount: false
        };

        if (cartItems.length > 0 && !isEmpty(saleDetails)) {

            capabilities.calendar = saleDetails.session !== SessionTypes.TechAssistSameDay &&
                saleDetails.session !== SessionTypes.EmployeePurchaseProgram && !isEmpty(customer);
            capabilities.checkout = !isCustomerEditable && !isEmpty(customer);

            if (capabilities.calendar && capabilities.checkout) {
                capabilities.checkout = installationDate && installationTime;
            }

            for (const cartItem of cartItems) {
                if (cartItem.discount && cartItem.discount.percent > 0 && !cartItem.discount.reason) {
                    capabilities.checkout = false;
                    break;
                }
            }

            if (!isEmpty(errors)) {
                for (const errorKey of Object.keys(errors)) {
                    if (errors[errorKey]) {
                        capabilities.checkout = false;
                        break;
                    }
                }
            }

            if (capabilities.checkout) {
                const sum = ProductsHelper.getCartTotalAmount(cartItems);
                if (sum === 0) {
                    capabilities.checkout = false;
                }
            }

            capabilities.payment = capabilities.checkout && isTermsSigned;
            capabilities.discount = !isCustomerEditable;
        }

        return capabilities;
    }

    handleCancelSaleClick = () => {
        const { isOSC } = this.props;
        this.props.clearOrder();
        if (isOSC) {
            this.props.history.replace({ pathname: '/create-order' });
        } else {
            this.props.history.replace({ pathname: '/' });
        }
    }

    handleCheckoutWithInvoice = () => {
        this.props.history.replace({ pathname: '/checkout-with-invoice' });
    }

    handleCartClick = () => {
        const { history } = this.props;
        history.replace({ pathname: '/cart' });
    }

    handleAdvancedCheckoutClick = () => {
        window.location.href = '/checkout-advanced';
    }

    onChangeZipCode = () => {
        const { isOSC } = this.props;
        this.props.clearSaleDetails(isOSC);
        if (isOSC) {
            this.props.history.replace({ pathname: '/create-order' });
        } else {
            this.props.history.replace({ pathname: '/products', state: { showZipLookup: true } });
        }
    }

    setInstallationDate = (date) => {
        this.props.setInstallationDate(date);
    }

    setInstallationTime = (time, id) => {
        this.props.setInstallationTime(time, id);
    }

    savePayPalLog = (success, paypalResponse, paypalError) => {
        const { user, customer, saleDetails, cartItems } = this.props;
        const data = {
            user,
            customer,
            businessLine: saleDetails.lineOfBusiness,
            workOrderNumber: saleDetails.workOrderNumber,
            cartItems
        };

        sendPayPalLog(success, data, paypalResponse, paypalError, 'Manual');
    }

    renderPaymentButtons = (capabilities) => {
        const { isOSC } = this.props;
        let invoiceButton = null;
        let paypalButton = null;
        const setPaymentSucceeded = (details) => {
            const transaction = details.purchase_units[0].payments.captures[0];
            this.props.setPaymentSucceeded(details.id, transaction.update_time);
        };
        const savePayPalLog = (success, paypalResponse, paypalError) => {
            this.savePayPalLog(success, paypalResponse, paypalError);
        };

        const setPayPalError = (message = '', details = '') => {
            this.setState({
                payPalError: message,
                payPalErrorDetails: details
            });
        };

        if (capabilities.payment && isOSC) {
            invoiceButton = <NavLink
                to="#"
                onClick={this.handleCheckoutWithInvoice}
                disabled={this.state.isCustomerEditable}
                className="primary pp">
                Send PayPal invoice
            </NavLink>;
        }
        if (capabilities.payment) {
            paypalButton = <div className={isOSC ? 'btn-cc' : 'btn-cc without-margin'}>
                <PayPalButton
                    style={{
                        layout: 'vertical',
                        color: 'blue',
                        shape: 'pill',
                        tagline: false,
                        label: 'pay'
                    }}
                    createOrder={(data, actions) => {
                        setPayPalError();
                        return actions.order.create(this.getPaypalOrder());
                    }}
                    onApprove={(data) => {
                        // Capture the funds from the transaction
                        return captureOrder(data.orderID).then(({ data, err }) => {
                            if (err) {
                                const errorDetail =
                                    err.error.details &&
                                    Array.isArray(err.error.details.details) &&
                                    err.error.details.details[0];

                                if (errorDetail) {
                                    const msg = 'Sorry, your transaction could not be processed. Please try again.';
                                    let msgDetails = '';
                                    if (errorDetail.description) {
                                        msgDetails += errorDetail.description;
                                    }
                                    setPayPalError(msg, msgDetails);
                                }

                                savePayPalLog(
                                    false,
                                    null,
                                    err.error.details ? err.error.details : err.error
                                );

                                /*
                                if (errorDetail && errorDetail.issue === 'INSTRUMENT_DECLINED') {
                                    return actions.restart();
                                }
                                */
                                return true;
                            } else if (data) {
                                const transaction = data.purchase_units[0].payments.captures[0];
                                if (data.status === 'COMPLETED' && transaction.status === 'COMPLETED') {
                                    savePayPalLog(true, data);
                                    setPaymentSucceeded(data);
                                } else {
                                    const msg = "Sorry, your transaction could not be processed. Please try again.";
                                    const msgDetails = this._parsePayPalProcessorResponse(
                                        data.payment_source,
                                        transaction.processor_response
                                    );
                                    setPayPalError(msg, msgDetails);
                                    savePayPalLog(false, null, {
                                        ...data,
                                        message:
                                            // eslint-disable-next-line max-len
                                            `Order has status: ${data.status}, transaction has status: ${transaction.status}`
                                    });
                                }
                            }
                        });
                    }}
                    onError={(error) => {
                        console.log('onError', error);
                        const msg = 'Sorry, your transaction could not be processed. Please try again.';
                        let msgDetails = '';
                        if (error && error.message) {
                            msgDetails += `(${error.message})`;
                        }
                        setPayPalError(msg, msgDetails);
                        savePayPalLog(
                            false,
                            null,
                            error
                        );
                    }}
                    catchError={(error) => {
                        console.log('catchError', error);
                        const msg = 'Sorry, your transaction could not be processed. Please try again.';
                        let msgDetails = '';
                        if (error && error.message) {
                            msgDetails += `(${error.message})`;
                        }
                        setPayPalError(msg, msgDetails);
                        savePayPalLog(
                            false,
                            null,
                            error
                        );
                    }}
                    onCancel={() => {

                    }}
                    options={{
                        clientId,
                        disableFunding: 'credit,venmo,sepa',
                        debug: false
                    }}
                />
            </div>;
        }

        return (
            <React.Fragment>
                {invoiceButton}
                {paypalButton}
            </React.Fragment>
        );
    }

    render() {
        const {
            user,
            cartItems,
            discountReasons,
            promoCode,
            invoice,
            tax,
            installationDate,
            installationTime,
            scheduleAvailability,
            errors,
            isOSC,
            modalZipLookUpVisible,
            modalNoProductsVisible
        } = this.props;
        const {
            modalZipCodeChangeNoticeVisible,
            modalInvoiceWarningVisible,
            noProducts,
            payPalError,
            payPalErrorDetails
        } = this.state;
        const capabilities = this.getCapabilities();
        const pageClasses = {
            'page': true,
            'page-checkout': true,
            'form-customer-visible': this.state.isCustomerEditable,
            'modal-active': this.state.isTermsVisible
        };

        return (
            <div className={ClassNames(pageClasses)}>
                <Header
                    onLogin={this.toggleAdminLogin}
                    onCartClick={this.toggleCart}
                />
                <div className="page-content-wrapper">
                    <main>
                        <div className="navigation-wrapper">
                            <NavLink
                                to="#"
                                onClick={this.handleCartClick}
                                className="back-action"
                            >
                                Back to cart
                            </NavLink>
                            {
                                !user.isReseller &&
                                <a
                                    className="advanced-checkout-action"
                                    href="#"
                                    onClick={this.handleAdvancedCheckoutClick}
                                >
                                    Switch to advanced checkout
                                </a>
                            }
                        </div>

                        <h1 data-title="Checkout">Checkout</h1>

                        <ProductList
                            setDiscount={this.props.setDiscount}
                            removeDiscount={this.props.removeDiscount}
                            getPromoCode={this.props.getPromoCode}
                            removePromoCode={this.props.removePromoCode}
                            discountReasons={discountReasons}
                            cartItems={cartItems}
                            promoCode={promoCode}
                            tax={tax}
                            capabilities={capabilities}
                            errors={errors}
                            isOSC={isOSC}
                        />

                        <div className="checkout-actions-wrapper">
                            {
                                invoice.status !== 'PAID' ?
                                    <button
                                        className="link cancel-action"
                                        onClick={this.handleCancelSaleClick}
                                    >
                                        Cancel Sale
                                    </button> : null
                            }

                            <div style={{ display: 'flex' }}>
                                {
                                    !capabilities.payment ?
                                        <button
                                            onClick={this.toggleTerms}
                                            className="primary"
                                            disabled={!capabilities.checkout}
                                        >
                                            Checkout
                                        </button> : null
                                }
                                {this.renderPaymentButtons(capabilities)}
                            </div>
                        </div>
                    </main>
                    <aside>
                        <FormSale
                            isLoading={this.props.isGetScheduleAvailabilityLoading}
                            customer={this.props.customer}
                            installationDate={installationDate}
                            installationTime={installationTime}
                            capabilities={capabilities}
                            isOSC={isOSC}
                            scheduleAvailability={scheduleAvailability}
                            onChangeDetails={this.toggleCustomerDetails}
                            onSetDate={this.setInstallationDate}
                            onSetTime={this.setInstallationTime}
                        />
                        <FormCustomer
                            isVisible={this.state.isCustomerEditable}
                            onSubmit={this.onCustomerDetailsSubmit}
                        />
                    </aside>
                </div>

                <Footer/>
                {
                    this.props.isLoading ? <Preloader type="overlay" /> : null
                }
                {this.state.isTermsVisible ? <ModalTerms
                    onSubmit={this.onTermsSubmit}
                    onClose={this.toggleTerms}
                /> : null}
                {
                    this.state.isEmailModalVisible ?
                        <ModalEmail
                            onClose={this.toggleEmail}
                        /> : null
                }
                {
                    modalZipLookUpVisible ?
                        <ModalZipLookUp
                            description={'Please enter the new zip code.'}
                            redirectToCart
                        /> : null
                }
                {
                    modalNoProductsVisible ? <ModalNoProducts/> : null
                }
                {
                    modalZipCodeChangeNoticeVisible
                        ? <ModalZipCodeChangeNotice
                            noProducts={noProducts}
                            onClose={this.onCloseModalZipCodeChangeNotice}
                        /> : null
                }
                {
                    payPalError && <ModalError
                        message={payPalError}
                        details={payPalErrorDetails}
                        onClick={() => this.setState({payPalError: '', payPalErrorDetails: ''})}
                    />
                }
                {
                    modalInvoiceWarningVisible
                        ? <ModalInvoiceWarning
                            onSetInvoice={this.handleSetInvoice}
                            onClose={this.onCloseModalInvoiceWarning}
                        /> : null
                }
            </div>
        );
    }
}

const mapStateToProps = ({ order, profile, catalog }) => ({
    isOSC: !!profile.accessToken && profile.expired != null && !profile.expired,
    user: profile.user,
    isLoading: order.isLoading,
    isGetScheduleAvailabilityLoading: order.isGetScheduleAvailabilityLoading,
    customer: order.customerAccount,
    saleDetails: order.saleDetails,
    zipLookup: catalog.zipLookup,
    cartItems: order.items,
    discountReasons: order.discountReasons,
    promoCode: order.promoCode,
    tax: order.tax,
    invoice: order.invoice,
    installationDate: order.installationDate,
    installationTime: order.installationTime,
    errors: order.errors,
    isTermsSigned: order.isTermsSigned,
    scheduleAvailability: order.scheduleAvailability,
    modalZipLookUpVisible: catalog.modalZipLookUpVisible,
    modalNoProductsVisible: catalog.modalNoProductsVisible,
    warehousesData: catalog.warehousesData,
    products: catalog.products
});

export default withRouter(connect(mapStateToProps, {
    clearOrder,
    clearSaleDetails,
    getDiscountReasons,
    setDiscount,
    removeDiscount,
    getPromoCode,
    removePromoCode,
    getTaxRates,
    setPaymentSucceeded,
    setInstallationDate,
    setInstallationTime,
    setTermsSigned,
    getScheduleAvailability,
    lookUpZip,
    toggleModalNoProducts,
    setInvoice
})(PageCheckout));
