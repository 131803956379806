import React, { Component} from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';

import Modal from '../../../../components/modal/Modal';

class ModalZipcodeChangeNotice extends Component {

    static propTypes = {
        history: PropTypes.object,
        noProducts: PropTypes.bool,
        onClose: PropTypes.func
    }

    handleOkClick = () => {
        const { history } = this.props;
        this.props.onClose();
        history.replace({ pathname: '/cart' });
    }

    render() {

        const { noProducts } = this.props;
        let noProductsMessage = 'Some products in your cart may no longer be available in the new zip code. Please verify the products in your cart.';
        let extraMessage = null;

        if (noProducts) {
            noProductsMessage = 'There are currently no products available for the zip code you entered.';
            extraMessage = 'Please check back for availability in the future.';
        }

        return (
            <Modal size={'s'}>
                <h1 data-title="Zip Code has changed">Zip Code has changed</h1>
                <p>
                    Your zip code has changed based on the address you entered.<br/>
                    {noProductsMessage}
                    {
                        extraMessage ?
                            <React.Fragment><br/>{extraMessage}</React.Fragment> : null
                    }
                </p>
                <p className="action-wrapper">
                    <button
                        onClick={this.handleOkClick}
                        className="primary">
                        Ok
                    </button>

                </p>
            </Modal>
        );
    }
}

export default withRouter(ModalZipcodeChangeNotice);
