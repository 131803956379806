import React, { Component} from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

import FormatHelper from '../../../../helpers/format';

import './order-details.scss';

class OrderDetails extends Component {

    static propTypes = {
        customer: PropTypes.object,
        installationDate: PropTypes.object,
        installationTime: PropTypes.string
    }

    render() {

        const { customer, installationDate, installationTime } = this.props;

        let addressLine1 = customer.street1;
        if (customer.suite) {
            addressLine1 += `, suite: ${customer.suite}`;
        }
        if (customer.floor) {
            addressLine1 += `, floor: ${customer.floor}`;
        }
        const addressLine2 = `${customer.city}, ${customer.state}, ${customer.postalCode}`;

        return (
            <div className="form-sale-wrapper">
                <h2>Order Details</h2>

                <p className="customer-details">
                    <span>{customer.primaryContactName}</span>
                    <span>{FormatHelper.formatPhone(customer.primaryContactPhone)}</span>
                    <span>{customer.primaryContactEmail}</span>

                    <span className="title">Installation Address</span>
                    <span>{addressLine1}<br/>{addressLine2}</span>
                </p>

                <p className="order-date">
                    <span className="title">Order Date</span>
                    <span>{moment().format('MM/DD/YYYY')}</span>
                </p>
                {
                    installationDate ?
                        <p className="installation-date">
                            <span className="title">Installation Date</span>
                            <span>{moment(installationDate).format('MM/DD/YYYY')}</span>
                        </p> : null
                }
                {
                    installationTime ?
                        <p className="installation-time">
                            <span className="title">Installation Time</span>
                            <span>{installationTime}</span>
                        </p> : null
                }
            </div>
        );
    }
}

export default OrderDetails;
