export default {
    LOGIN: {
        LOADING: 'ADMIN_LOGIN',
        SUCCESS: 'ADMIN_LOGIN_SUCCESS',
        FAILED: 'ADMIN_LOGIN_FAILED'
    },
    LOGOUT: 'ADMIN_LOGOUT',
    GET_EMAILS_QUEUE: {
        LOADING: 'GET_EMAILS_QUEUE',
        SUCCESS: 'GET_EMAILS_QUEUE_SUCCESS',
        FAILED: 'GET_EMAILS_QUEUE_FAILED'
    },
    RUN_ACTION: {
        LOADING: 'RUN_ACTION',
        SUCCESS: 'RUN_ACTION_SUCCESS',
        FAILED: 'RUN_ACTION_FAILED'
    }
};
