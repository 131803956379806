import React, { Component} from 'react';
import Header from '../../components/header/Header';
import Footer from '../../components/footer/Footer';

class PageAccessDenied extends Component {
    render() {
        return (
            <div className="page">
                <Header/>
                <main className="page-content-wrapper">
                    <div className="empty-state-wrapper">
                        <h1>Access Denied</h1>
                        <p>Unfortunately you do not have access to create orders</p>
                    </div>
                </main>
                <Footer/>
            </div>
        );
    }
}

export default PageAccessDenied;
