import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import ClassNames from 'classnames';

import Header from '../../components/header/Header';
import Footer from '../../components/footer/Footer';
import ProductList from './components/product-list/ProductList';
import Preloader from '../../components/preloader/Preloader';
import ModalZipLookUp from '../../components/modal-zip-lookup/ModalZipLookUp';
import ModalNoProduct from './components/modal-no-product/ModalNoProduct';
import ModalNoProducts from '../../components/modal-no-products/ModalNoProducts';

import { getCatalogData, toggleModalZipLookup } from '../../actions/catalogActions';
import { putProductToCart, removeProductFromCart  } from '../../actions/orderActions';
import { SessionTypes } from '../../constants';

class PageProducts extends Component {

    static propTypes = {
        location: PropTypes.object,
        isLoading: PropTypes.bool,
        isOSC: PropTypes.bool,
        productsHydrated: PropTypes.bool,
        filteredProducts: PropTypes.object,
        cartItems: PropTypes.array,
        saleDetails: PropTypes.object,
        modalZipLookUpVisible: PropTypes.bool,
        modalNoProductsVisible: PropTypes.bool,
        zipLookup: PropTypes.object,
        getCatalogData: PropTypes.func,
        putProductToCart: PropTypes.func,
        removeProductFromCart: PropTypes.func,
        toggleModalZipLookup: PropTypes.func
    }

    constructor(props) {

        super(props);

        this.state = {
            isModalDetailsVisible: false,
            detailsProductId: null,
            isModalNoProductVisible: false,
            productSelected: null
        };
    }

    componentDidMount() {

        const { saleDetails, isOSC, location, zipLookup } = this.props;
        this.props.getCatalogData(saleDetails.session, isOSC);

        if (location && location.state && location.state.showZipLookup && !zipLookup.zip && !isOSC) {
            this.props.toggleModalZipLookup(true);
        }
    }

    componentDidUpdate(prevProps) {
        const { productSelected } = this.state;
        const { zipLookup, isOSC, modalZipLookUpVisible, filteredProducts } = this.props;

        if (prevProps.modalZipLookUpVisible && !modalZipLookUpVisible) {
            // zip lookup was closed
            if (zipLookup.zip && !isOSC && productSelected) {
                if (Object.keys(filteredProducts).length === 0) {
                    return;
                }
                let isModalNoProductVisible = false;
                if (filteredProducts[productSelected.id]) {
                    this.addProductToCart(productSelected);
                } else  {
                    isModalNoProductVisible = true;
                }

                this.setState({
                    productSelected: null,
                    isModalNoProductVisible
                });
            }
        }
    }

    toggleDetails = (productId) => {
        this.setState({
            isModalDetailsVisible: !this.state.isModalDetailsVisible,
            detailsProductId: productId
        });
    }

    addProductToCart = (product) => {

        const { zipLookup, isOSC } = this.props;

        if (!zipLookup.zip && !isOSC) {
            this.setState({
                isModalDetailsVisible: false,
                productSelected: product
            });
            return this.props.toggleModalZipLookup(true);
        }

        this.props.putProductToCart(product);
    }

    removeProductFromCart = (item) => {

        this.props.removeProductFromCart(item);
    }

    getCapabilities() {

        const { saleDetails } = this.props;
        const capabilities = {
            ftr: true
        };

        if (
            saleDetails.session === SessionTypes.EmployeePurchaseProgram
        ) {
            capabilities.ftr = false;
        }

        return capabilities;
    }

    closeModalNoProduct = () => {
        this.setState({
            isModalNoProductVisible: false
        });
    }

    render() {

        const { modalZipLookUpVisible, modalNoProductsVisible, productsHydrated } = this.props;
        const { isModalNoProductVisible, isModalDetailsVisible, detailsProductId } = this.state;

        const pageClasses = {
            'page': true,
            'modal-active': isModalDetailsVisible
        };

        const capabilities = this.getCapabilities();
        let modalZipLookUp = null;
        if (modalZipLookUpVisible) {
            modalZipLookUp = <ModalZipLookUp/>;
        }

        let modalNoProduct = null;
        if (isModalNoProductVisible) {
            modalNoProduct = <ModalNoProduct onClose={this.closeModalNoProduct}/>;
        }

        let modalNoProducts = null;
        if (modalNoProductsVisible) {
            modalNoProducts = <ModalNoProducts/>;
        }

        return (
            <div className={ClassNames(pageClasses)}>
                <Header/>
                <ProductList
                    isLoading={this.props.isLoading}
                    isModalDetailsVisible={isModalDetailsVisible}
                    detailsProductId={detailsProductId}
                    products={this.props.filteredProducts}
                    cartItems={this.props.cartItems}
                    capabilities={capabilities}
                    hydrated={productsHydrated}
                    onAddProduct={this.addProductToCart}
                    onRemoveProduct={this.removeProductFromCart}
                    onDetailsToggle={this.toggleDetails}
                />
                <Footer />
                {
                    this.props.isLoading ? <Preloader type="overlay" /> : null
                }
                {modalZipLookUp}
                {modalNoProduct}
                {modalNoProducts}
            </div>
        );
    }
}

const mapStateToProps = ({ catalog, order, profile }) => {
    return {
        isOSC: !!profile.accessToken && profile.expired != null && !profile.expired,
        filteredProducts: catalog.filteredProducts,
        cartItems: order.items,
        zipLookup: catalog.zipLookup,
        saleDetails: order.saleDetails,
        modalZipLookUpVisible: catalog.modalZipLookUpVisible,
        modalNoProductsVisible: catalog.modalNoProductsVisible,
        isLoading: catalog.isLoading,
        productsHydrated: catalog.productsHydrated
    };
};

export default withRouter(connect(mapStateToProps, {
    getCatalogData,
    putProductToCart,
    removeProductFromCart,
    toggleModalZipLookup
})(PageProducts));
