import React, { Component} from 'react';
import PropTypes from 'prop-types';
import ClassNames from 'classnames';

import './filters.scss';

function FilterButton({ filter, activeFilter, onChange }) {

    const filterClasses = {
        'filter-action': true,
        'link': true,
        'active': activeFilter === filter.id
    };

    const handleFilterButtonClick = () => {
        onChange(filter);
    };

    return (
        <button
            type="button"
            className={ClassNames(filterClasses)}
            onClick={handleFilterButtonClick}>
            {filter.name}
        </button>
    );
}

FilterButton.propTypes = {
    filter: PropTypes.object,
    activeFilter: PropTypes.string,
    onChange: PropTypes.func
};

class Filters extends Component {

    static propTypes = {
        filters: PropTypes.array,
        activeFilter: PropTypes.string,
        onChange: PropTypes.func
    }

    onChange = (filter) => {

        if (this.props.onChange) {
            this.props.onChange(filter.id);
        }
    }

    render() {

        const { filters, activeFilter } = this.props;

        const filterComponents = filters && filters.map((filter, index) => {
            return <FilterButton
                key={index}
                filter={filter}
                activeFilter={activeFilter}
                onChange={this.onChange}
            />;
        });

        return (
            <div className="filter">
                <h3>Filter</h3>
                <FilterButton filter={{ id: 'All', name: 'All' }} activeFilter={activeFilter} onChange={this.onChange}/>
                {filterComponents}
            </div>
        );
    }
}

export default Filters;
