import { isEmpty } from 'lodash';
import Qs from 'qs';
import CNST from './types';
import ApiClient from '../helpers/api';
import ProductsHelper from '../helpers/products';
import { CustomersUserGroupId, OscUserGroupId, FutureTruckRollItemCode } from '../constants';


const priceFilter = (data) => {

    return data.reduce((i, el) => {
        let totalPrice = 0;
        let hasMaterialPricing = false;
        let hasLaborPricing = false;

        if (!isEmpty(el.material.pricing)) {
            hasMaterialPricing = true;
            if (el.itemCode === FutureTruckRollItemCode && el.material.pricing.length > 0) {
                el.material.pricing = [el.material.pricing[el.material.pricing.length-1]];
            } else {
                el.material.pricing = ProductsHelper.actualPrice(el.material.pricing);
            }
            if (el.material.pricing.length > 0) {
                totalPrice += el.material.pricing[0].price;
            }
        }
        if (!isEmpty(el.labor.pricing)) {
            hasLaborPricing = true;
            if (el.itemCode === FutureTruckRollItemCode && el.labor.pricing.length > 0) {
                el.labor.pricing = [el.labor.pricing[el.labor.pricing.length-1]];
            } else {
                el.labor.pricing = ProductsHelper.actualPrice(el.labor.pricing);
            }

            if (el.labor.pricing.length > 0) {
                totalPrice += el.labor.pricing[0].price;
            }
        }

        el.totalPrice = totalPrice;

        if (
            (hasMaterialPricing && el.material.pricing.length > 0)
            || (hasLaborPricing && el.labor.pricing.length > 0)) {
            i[el.id] = el;
        }

        return i;
    }, {});
};

export function getCatalogData(sessionType, isOSC) {
    return async (dispatch) => {
        dispatch({
            type: CNST.CATALOG.GET_CATALOG_DATA.LOADING
        });

        try {
            const productsUrl = `/api/products?${Qs.stringify({
                userGroups: [CustomersUserGroupId, OscUserGroupId]
            })}`;

            const responses = await Promise.all([
                ApiClient.get(productsUrl),
                ApiClient.get('/api/products/categories'),
                ApiClient.get('/api/products/warehouses')
            ]);
            let products = responses[0].data;
            const categories = responses[1].data;
            const warehousesData = responses[2].data;

            products = priceFilter(products);

            dispatch({
                type: CNST.CATALOG.GET_CATALOG_DATA.SUCCESS,
                response: { products, categories, warehousesData },
                sessionType,
                isOSC
            });

            return true;

        } catch (err) {
            console.log('error', err);
            dispatch({
                type: CNST.CATALOG.GET_CATALOG_DATA.FAILED,
                response: err
            });

            return err;
        }
    };
}


export function getBusinessLines() {

    return async (dispatch) => {
        dispatch({
            type: CNST.CATALOG.GET_BUSINESS_LINE.LOADING
        });

        try {
            const response = await ApiClient.get('/api/products/business-lines');
            const { data } = response;

            dispatch({
                type: CNST.CATALOG.GET_BUSINESS_LINE.SUCCESS,
                response: { businessLines: data }
            });

            return true;
        } catch (err) {
            console.log('error', err);
            dispatch({
                type: CNST.CATALOG.GET_BUSINESS_LINE.FAILED,
                response: err
            });
        }
    };
}

export function getCategories() {

    return async (dispatch) => {
        dispatch({
            type: CNST.CATALOG.GET_CATEGORIES.LOADING
        });

        try {
            const response = await ApiClient.get('/api/products/categories');
            const { data } = response;

            dispatch({
                type: CNST.CATALOG.GET_CATEGORIES.SUCCESS,
                response: { categories: data }
            });

            return true;
        } catch (err) {
            console.log('error', err);
            dispatch({
                type: CNST.CATALOG.GET_CATEGORIES.FAILED,
                response: err
            });
        }
    };
}

export function getProducts(sessionType, isOSC) {

    return async (dispatch) => {
        dispatch({
            type: CNST.CATALOG.GET_PRODUCTS.LOADING
        });

        try {
            const productsUrl = `/api/products?${Qs.stringify({
                userGroups: [CustomersUserGroupId, OscUserGroupId]
            })}`;
            const response = await ApiClient.get(productsUrl);
            const { data } = response;

            const result = priceFilter(data);

            dispatch({
                type: CNST.CATALOG.GET_PRODUCTS.SUCCESS,
                response: { products: result },
                sessionType,
                isOSC
            });

            return true;

        } catch (err) {
            console.log('error', err);
            dispatch({
                type: CNST.CATALOG.GET_PRODUCTS.FAILED,
                response: err
            });

            return err;
        }
    };
}

export function toggleModalZipLookup(visible) {
    return (dispatch) => {
        dispatch({
            type: CNST.CATALOG.TOGGLE_MODAL_ZIP_LOOKUP,
            visible
        });
    };
}

export function toggleModalNoProducts(visible, clearZipLookup) {
    return (dispatch) => {
        dispatch({
            type: CNST.CATALOG.TOGGLE_MODAL_NO_PRODUCTS,
            visible,
            clearZipLookup
        });
    };
}

export function lookUpZip(zip, sessionType, lineOfBusiness, lineOfBusinessId, isOSC, catalogData, cb) {

    return async (dispatch) => {
        dispatch({
            type: CNST.CATALOG.LOOKUP_ZIP.LOADING
        });

        try {
            const response = await ApiClient.get(`/api/zip-lookup/${zip}/${lineOfBusiness}`);
            const { data } = response;

            const filteredProducts = ProductsHelper.filterProducts(
                catalogData.products, 'All', isOSC, sessionType, data, catalogData.warehousesData, lineOfBusinessId);

            dispatch({
                type: CNST.CATALOG.LOOKUP_ZIP.SUCCESS,
                response: { zipLookup: data, filteredProducts }
            });

            if (cb) {
                cb(true, data, filteredProducts);
            }

            return true;
        } catch (err) {
            console.log('error', err);
            dispatch({
                type: CNST.CATALOG.LOOKUP_ZIP.FAILED,
                response: err
            });
            if (cb) {
                cb(false);
            }
        }
    };
}

export function filterProducts(categoryId, sessionType, isOSC) {

    return async (dispatch) => {
        dispatch({
            type: CNST.CATALOG.FILTER_PRODUCTS,
            categoryId,
            sessionType,
            isOSC
        });
    };
}

export function getWarehousesData(sessionType, isOSC) {

    return async (dispatch) => {
        dispatch({
            type: CNST.CATALOG.GET_WAREHOUSES_DATA.LOADING
        });

        try {
            const response = await ApiClient.get('/api/products/warehouses');
            const { data } = response;

            dispatch({
                type: CNST.CATALOG.GET_WAREHOUSES_DATA.SUCCESS,
                response: { warehousesData: data },
                sessionType,
                isOSC
            });

            return true;
        } catch (err) {
            console.log('error', err);
            dispatch({
                type: CNST.CATALOG.GET_WAREHOUSES_DATA.FAILED,
                response: err
            });
        }
    };
}

export async function getThirdPartyBrand(brand) {
    try {
        const requestUrl = `/api/third-parties/${brand}`;
        const response = await ApiClient.get(requestUrl);
        const { data } = response;

        return data;

    } catch (err) {
        console.log('error', err);
        return null;
    }
}
