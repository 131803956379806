import { cloneDeep } from 'lodash';
import { LOGIN, LOGOUT } from '../actions/types';

const initialState = {
    accessToken: '',
    user: {},
    expired: null
};

export default function (state = cloneDeep(initialState), action) {
    switch (action.type) {
        case LOGIN:
            return {
                ...state,
                accessToken: action.user.access_token,
                user: action.user.profile,
                expired: action.user.expired
            };
        case LOGOUT:
            return {
                ...state,
                ...initialState
            };
        default:
            return state;
    }
}
