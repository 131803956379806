import React, { Component} from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import DatePicker from "react-datepicker";

import Preloader from '../../../../components/preloader/Preloader';
import FormatHelper from '../../../../helpers/format';

import "react-datepicker/dist/react-datepicker.css";
import './form-sale.scss';
import './datepicker-overwrite.scss';

class FormSale extends Component {

    static propTypes = {
        isLoading: PropTypes.bool,
        isOSC: PropTypes.bool,
        customer: PropTypes.object,
        installationDate: PropTypes.object,
        installationTime: PropTypes.string,
        capabilities: PropTypes.object,
        scheduleAvailability: PropTypes.array,
        onChangeDetails: PropTypes.func,
        onSetDate: PropTypes.func,
        onSetTime: PropTypes.func
    }

    constructor(props) {
        super(props);

        this.state = {
            installationDate: null
        };
    }

    handleSelectDate = (date ) => {

        this.props.onSetDate(date);
    }

    handleChangeTime = (ev) => {

        var sel = ev.target;
        if (sel.value) {
            var selected = sel.options[sel.selectedIndex];
            var id = selected.getAttribute('data-id');

            this.props.onSetTime(sel.value, id);
        }
        else {
            this.props.onSetTime(null, null);
        }
    }

    render() {

        const {
            customer,
            installationDate,
            installationTime,
            capabilities,
            scheduleAvailability,
            isLoading
        } = this.props;

        let addressLine1 = '';
        let addressLine2 = '';
        let availabilityTimeSlots = [];

        if (customer) {
            addressLine1 = customer.street1;
            if (customer.suite) {
                addressLine1 += `, suite: ${customer.suite}`;
            }
            if (customer.floor) {
                addressLine1 += `, floor: ${customer.floor}`;
            }
            addressLine2 = `${customer.city}, ${customer.state}, ${customer.postalCode}`;
        }

        let calendarWrapper = null;

        if (capabilities.calendar || !this.props.isOSC) {

            if (!isLoading && scheduleAvailability && scheduleAvailability.length > 0) {

                if (installationDate) {
                    availabilityTimeSlots = scheduleAvailability.filter((item) => {
                        return moment(item.startDateTime).isSame(installationDate, 'day');
                    });
                }

                calendarWrapper = <div className="installation-date">
                    <span className="title">Select Installation Date</span>
                    <span>{installationDate ? moment(installationDate).format('MM/DD/YYYY') : ''}</span>

                    <div className="calendar-wrapper">
                        <DatePicker
                            inline
                            selected={installationDate ? moment(installationDate) : null}
                            minDate={new Date()}
                            filterDate={(date) => {
                                const availableDate = scheduleAvailability.find((item) => {
                                    return moment(item.startDateTime).isSame(date, 'day');
                                });
                                return !!availableDate;
                            }}
                            onSelect={this.handleSelectDate}
                        />
                    </div>
                    <select
                        value={installationTime}
                        onChange={this.handleChangeTime}
                    >
                        <option value="">Select Installation Time</option>
                        {
                            availabilityTimeSlots.map((schedule, index) => {
                                return <option
                                    key={index}
                                    value={schedule.title}
                                    data-id={schedule.availabilityId}
                                >
                                    {schedule.title}
                                </option>;
                            })
                        }
                    </select>
                </div>;
            } else if (!isLoading) {
                calendarWrapper = <div className="installation-date">
                    <p className="schedule-error-message">
                            Unfortunately we don&apos;t have time slots for this order.
                    </p>
                </div>;
            } else if (isLoading) {
                calendarWrapper = <div className="installation-date">
                    <div className="calendar-loading-wrapper">
                        <Preloader type="inline"/>
                    </div>
                </div>;
            }
        }

        return (
            <div className="form-sale-wrapper">
                <h2>New Sale</h2>
                <p className="customer-details">
                    <span>{customer ? customer.primaryContactName : ''}</span>
                    <span>{customer ? FormatHelper.formatPhone(customer.primaryContactPhone) : ''}</span>
                    <span>{customer ? customer.primaryContactEmail : ''}</span>

                    <span className="title">Installation Address</span>
                    <span>{addressLine1}<br/>{addressLine2}</span>
                    <button
                        onClick={this.props.onChangeDetails}
                        className="details-change-action link">
                        Change
                    </button>
                </p>
                <p className="order-date">
                    <span className="title">Order Date</span>
                    <span>{moment().format('MM/DD/YYYY')}</span>
                </p>
                {calendarWrapper}
            </div>
        );
    }
}

export default FormSale;
