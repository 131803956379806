import React from 'react';
import PropTypes from 'prop-types';
import { Redirect, Route } from 'react-router-dom';

const OscRoute = ({ component: Component, ...rest }) => {

    const isOSC = rest.isOSC || false;
    const user = rest.user || false;

    return (
        <Route
            {...rest}
            render={props => {
                if (isOSC) {
                    if (user && user.isAccessDenied) {
                        return <Redirect to={{ pathname: '/access-denied' }} />;
                    }

                    return <Component {...props} />;
                } else {
                    const { location } = props;
                    let searchUrlString = '';

                    if (location.pathname.includes('create-order')) {
                        // check if url has passed parameters to pre-fill while creating order
                        if (location.search) {
                            searchUrlString = location.search;
                        }
                    }

                    return <Redirect to={{ pathname: '/OSCLogin', search: searchUrlString }} />;
                }

                // return <Redirect to={{ pathname: '/' }} />;
            }}
        />
    );
};

OscRoute.propTypes = {
    location: PropTypes.object,
    component: PropTypes.func
};

export default OscRoute;
