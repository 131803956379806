import React, { Component} from 'react';
import PropTypes from 'prop-types';
import ClassNames from 'classnames';
import { isEmpty } from 'lodash';

import './form-submit-order.scss';

class FormCheckPayment extends Component {

    static propTypes = {
        isLoading: PropTypes.bool,
        workOrder: PropTypes.object,
        onSubmitOrder: PropTypes.func
    }

    constructor(props) {
        super(props);

        this.state = {
            checkingStatus: 'loading'
        };
    }

    componentDidUpdate(prevProps) {
        const { workOrder, isLoading } = this.props;

        if (prevProps.isLoading && !isLoading && isEmpty(workOrder) ) {
            this.setState({
                checkingStatus: 'failed'
            });
        }
    }

    render() {

        const { workOrder, isLoading } = this.props;
        const { checkingStatus } = this.state;

        let reSubmitButton,
            loading = null;

        if (isEmpty(workOrder) && !isLoading) {

            reSubmitButton = <button
                className="primary"
                onClick={this.props.onSubmitOrder}
            >
                Resubmit Order
            </button>;
        }

        const loaderClasses = {
            'loader-wrapper' : true,
            [checkingStatus] : true
        };

        loading = <div className={ClassNames(loaderClasses)}>
            <p className="message-loading">Creating Work Order</p>
            <p className="message-failed">
                Payment has been received but order submission has failed. Click below to resubmit order.
            </p>
        </div>;

        const formClasses = {
            'form-check-payment-wrapper': true
        };

        return (
            <div className={ClassNames(formClasses)}>
                <div className="submit-order-wrapper">
                    {reSubmitButton}
                    {loading}
                </div>
            </div>
        );
    }
}

export default FormCheckPayment;
