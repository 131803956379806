import React, { Component} from 'react';
import PropTypes from 'prop-types';
import { NavLink, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import Modal from '../modal/Modal';
import Filters from '../filters/Filters';

import { filterProducts } from '../../actions/catalogActions';

import './footer.scss';
import MastecLogo from '../../assets/images/mastec_logo.svg';

class Footer extends Component {

    static propTypes = {
        history: PropTypes.object,
        location: PropTypes.object,
        isOSC: PropTypes.bool.isRequired,
        categories: PropTypes.array,
        productFilters: PropTypes.object,
        saleDetails: PropTypes.object,
        filterProducts: PropTypes.func
    }

    constructor(props) {
        super(props);
        this.state = {
            isContactVisible: false
        };

        this.toggleContactModal = this.toggleContactModal.bind(this);
    }

    toggleContactModal() {
        this.setState({isContactVisible: !this.state.isContactVisible});
    }

    handleFiltersChange = (categoryId) => {

        const { saleDetails, isOSC } = this.props;
        this.props.filterProducts(categoryId, saleDetails.session, isOSC);
    }

    handleProductsClick = () => {

        const { history } = this.props;
        history.replace({ pathname: '/products' });
    }

    handleCartClick = () => {

        const { history } = this.props;
        history.replace({ pathname: '/cart' });
    }

    render() {

        let contactModal = null;

        const { location, categories, productFilters } = this.props;
        const { isContactVisible } = this.state;

        if (isContactVisible) {
            contactModal = <Modal size="s" onClose={this.toggleContactModal}>
                <h1 data-title="Contact">Contact</h1>
                <p>OneStep Hotline: <a href='tel:877.233.6034' className="link">877.233.6034</a></p>
                <p>
                    Email: <a
                        href='mailto:SmartTechExpert@mastec.com'
                        className="link">
                        SmartTechExpert@mastec.com
                    </a>
                </p>
            </Modal>;
        }

        let productLink,
            cartLink = null;

        if (location.pathname === '/') {
            cartLink = <NavLink to="#" onClick={this.handleCartClick} className="link">View Cart</NavLink>;
            productLink = <NavLink to="#" onClick={this.handleProductsClick} className="link">Products & Services</NavLink>;
        }

        if (location.pathname === "/products") {
            cartLink = <NavLink to="#" onClick={this.handleCartClick} className="link">View Cart</NavLink>;
            productLink = <Filters
                filters={categories}
                activeFilter={productFilters.categoryId}
                onChange={this.handleFiltersChange}
            />;
        }

        if (location.pathname === "/cart") {
            productLink = <NavLink to="#" onClick={this.handleCartClick} className="link">Products & Services</NavLink>;
        }

        return (
            <footer className='footer-wrapper'>
                <p>
                    <img alt="Mastec" className="logo" src={MastecLogo}/>
                    {productLink}
                    {cartLink}
                </p>

                <p>
                    <button
                        onClick={this.toggleContactModal}
                        className="secondary">
                        Contact
                    </button>
                    <small>
                        Copyright 2019. MasTec Advanced Technologies. <br/>
                        All Rights Reserved.&nbsp;
                        <a
                            target="_blank"
                            rel="noopener noreferrer"
                            href="http://legal.mastecat.com/onestep/dataprivacy.html"
                            className="link">
                            Privacy Policy »
                        </a>
                    </small>
                </p>
                {contactModal}
            </footer>
        );
    }
}

const mapStateToProps = ({ catalog, order, profile }) => ({
    isOSC: !!profile.accessToken && profile.expired != null && !profile.expired,
    categories: catalog.categories,
    productFilters: catalog.filters,
    saleDetails: order.saleDetails
});


export default withRouter(connect(mapStateToProps, { filterProducts })(Footer));
