import React, { Component} from 'react';
import Header from '../../components/header/Header';
import Footer from '../../components/footer/Footer';

class PageNotFound extends Component {
    render() {
        return (
            <div className="page">
                <Header/>
                <main className="page-content-wrapper">
                    <div className="empty-state-wrapper">
                        <h1>404</h1>
                        <p>We couldn&apos;t not find the page you were looking for</p>
                    </div>
                </main>
                <Footer/>
            </div>
        );
    }
}

export default PageNotFound;
