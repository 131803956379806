import React, { Component} from 'react';
import PropTypes from 'prop-types';
import ClassNames from 'classnames';

import './form-invoice-email.scss';

class FormInvoiceEmail extends Component {

    static propTypes = {
        isLoading: PropTypes.bool,
        email: PropTypes.string,
        onSubmit: PropTypes.func
    }

    constructor(props) {
        super(props);
        this.state = {
            email: props.email
        };
    }

    onChange = (e) => {
        this.setState({[e.target.name]: e.target.value});
    }

    onSubmit = () => {
        const { email } = this.state;
        this.props.onSubmit(email);
    }

    render() {

        const formClasses = {
            'form-paypal-invoice-wrapper': true
        };

        return (
            <div className={ClassNames(formClasses)}>
                <p className="message"></p>

                <p className="form-item">
                    <input
                        type="email"
                        name='email'
                        value={this.state.email}
                        onChange={this.onChange}
                        placeholder="Your email"/>
                </p>
                <p className="form-action-wrapper">
                    <button
                        onClick={this.onSubmit}
                        className="primary">
                        Send invoice
                    </button>
                </p>
            </div>
        );
    }
}

export default FormInvoiceEmail;
