import React, { Component} from 'react';
import PropTypes from 'prop-types';
import FormatHelper from '../../../../helpers/format';
import { FutureTruckRollItemCode, BuriedCableItemCode } from '../../../../constants';

import './product-list.scss';

class ProductList extends Component {

    static propTypes = {
        cartItems: PropTypes.array,
        products: PropTypes.object,
        changeProductQuantity: PropTypes.func,
        removeProductFromCart: PropTypes.func
    }

    changeQuantity(id, productCode, quantity, useTextQuantity) {
        this.props.changeProductQuantity(id, productCode, quantity, useTextQuantity);
    }

    render() {

        let rows = null;
        let subtotal = 0;

        const { cartItems, products } = this.props;

        if (cartItems.length) {

            rows = cartItems.map((item, index) => {
                const isFutureTrackRoll = item.itemCode === FutureTruckRollItemCode;
                const available = !!products[item.id] || isFutureTrackRoll;
                const useTextQuantity = products[item.id] ? products[item.id].useTextQuantity : false;
                const maxQuantity = item.itemCode === BuriedCableItemCode || useTextQuantity ? 99999 : 99;
                if (available) {
                    subtotal = subtotal + item.price * item.quantity;
                }

                return <tr key={index}>
                    <td>
                        <h3 className="title">
                            {item.title}<br/>
                            {
                                !available ?
                                    <span className="no-available">
                                        This item is no longer available in your area. Click the X to remove it from the cart.
                                    </span> : null

                            }
                        </h3>
                        {
                            item.futureTruckRoll
                                ? <p className="truck-eligible-label">Install/Delivery Eligible</p>
                                : null
                        }
                    </td>
                    <td>
                        {
                            available && !isFutureTrackRoll ?
                                <p className='quantity-selection-wrapper'>
                                    <button
                                        disabled={item.quantity === 1}
                                        className="primary"
                                        onClick={() => this.changeQuantity(item.id, item.itemCode, item.quantity - 1)}> - </button>


                                    <input value={item.quantity} onChange={(ev) => {
                                        this.changeQuantity(item.id, item.itemCode, ev.target.value, useTextQuantity);
                                    }}/>

                                    <button
                                        disabled={item.quantity === maxQuantity}
                                        className="primary"
                                        onClick={() => this.changeQuantity(item.id, item.itemCode, item.quantity + 1)}> + </button>

                                </p> : null
                        }

                    </td>
                    <td data-title="Price:">{available ? FormatHelper.formatPrice(item.price) : '-'}</td>
                    <td data-title="Total:">
                        {available ? FormatHelper.formatPrice(item.price * item.quantity) : '-'}
                    </td>
                    <td>
                        {
                            !isFutureTrackRoll ?
                                <button
                                    className="product-remove-action"
                                    onClick={() => this.props.removeProductFromCart(item)}
                                /> : null
                        }

                    </td>
                </tr>;
            });
        }

        return (
            <div className="table-cart-wrapper">
                <table>
                    <thead>
                        <tr>
                            <th>Name</th>
                            <th>Quantity</th>
                            <th>Price</th>
                            <th>Total</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        {rows}
                    </tbody>
                    <tfoot>
                        <tr>
                            <td colSpan={2} className="mobile-hidden"></td>
                            <td>Subtotal</td>
                            <td colSpan={3}>{FormatHelper.formatPrice(subtotal)}</td>
                        </tr>
                    </tfoot>
                </table>
            </div>
        );
    }
}

export default ProductList;
