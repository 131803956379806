import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ClassNames from 'classnames';
import { isEqual, debounce } from 'lodash';
import FormatHelper from '../../../../helpers/format';
import ProductHelper from '../../../../helpers/products';

import './product-list.scss';

class ProductList extends Component {

    static propTypes = {
        isOSC: PropTypes.bool,
        cartItems: PropTypes.array,
        discountReasons: PropTypes.array,
        promoCode: PropTypes.object,
        tax: PropTypes.object,
        capabilities: PropTypes.object,
        errors: PropTypes.object,
        setDiscount: PropTypes.func,
        removeDiscount: PropTypes.func,
        getPromoCode: PropTypes.func,
        removePromoCode: PropTypes.func
    }

    constructor(props) {
        super(props);

        const errors = {};
        const discountValues = props.cartItems.reduce((values, item) => {
            if(item.discount) {
                values[item.id] = item.discount.percent;
                if (item.discount.percent > 0 && !item.discount.reason) {
                    errors[`discountReason_${item.id}`] = 'Discount reason is required field';
                }
            }
            return values;
        }, {});

        this.state = {
            showPromoCodeForm: !!props.promoCode,
            promoCode: props.promoCode ? props.promoCode.code : '',
            discountValues,
            errors: {
                ...props.errors,
                ...errors
            }
        };

        this.setDiscount = debounce(props.setDiscount, 500);
    }

    componentDidUpdate(prevProps) {
        const { errors } = this.props;
        const { errors: stateErrors } = this.state;
        if (!isEqual(prevProps.errors, errors)) {
            this.setState({
                errors: {
                    ...stateErrors,
                    ...errors
                }
            });
        }
    }

    onChangeDiscountPercent = (ev) => {

        const productId = ev.target.dataset.id;
        const cartItem = this.props.cartItems.find((cartItem) => cartItem.id === productId);
        const { value } = ev.target;

        if (value.length <= 2 || value === '100') {

            if (value === '') {
                return this.props.removeDiscount(productId);
            }

            let reason = cartItem.discount.reason;
            let percent = value;
            let reasonError = '';
            if (value === '' || value === '0') {
                reason = '';
            } else if (!reason) {
                reasonError = 'Discount reason is required field';
            }

            this.setState({
                discountValues: {
                    ...this.state.discountValues,
                    [productId]: percent
                },
                errors: {
                    ...this.state.errors,
                    [`discountReason_${productId}`]: reasonError
                }
            });
            this.setDiscount(productId, percent, reason);
        }
    }

    onChangeDiscountReason = (ev) => {

        const productId = ev.target.dataset.id;
        const cartItem = this.props.cartItems.find((cartItem) => cartItem.id === productId);
        const { value } = ev.target;

        let reasonError = '';
        let reason = value;
        if (cartItem.discount.percent > 0 && !value) {
            reasonError = 'Discount reason is required field';
        }
        this.setState({
            errors: {
                ...this.state.errors,
                [`discountReason_${productId}`]: reasonError
            }
        });
        if (cartItem.discount.percent === 0 && value) {
            reason = '';
        }
        this.props.setDiscount(productId, cartItem.discount.percent, reason);
    }

    onChange = (ev) => {

        const { name, value } = ev.target;

        if (name === 'promoCode' && !value) {
            this.handleRemovePromoCodeClick();
        }

        this.setState({
            [name]: value,
            errors: {
                ...this.state.errors,
                [name]: ''
            }
        });
    }

    handleAddDiscountClick = (ev) => {
        const productId = ev.target.dataset.id;
        this.setState({
            discountValues: {
                ...this.state.discountValues,
                [productId]: 0
            },
            errors: {
                ...this.state.errors,
                [`discountReason_${productId}`]: ''
            }
        });
        this.props.setDiscount(productId, 0, '');
    }

    handleRemoveDiscountClick = (ev) => {
        const productId = ev.target.dataset.id;
        const { discountValues } = this.state;
        delete discountValues[productId];
        this.setState({
            discountValues
        });
        this.props.removeDiscount(productId);
    }

    handleApplyPromoCodeClick = () => {
        const { promoCode } = this.state;
        this.props.getPromoCode(promoCode);
    }

    handleRemovePromoCodeClick = () => {
        this.props.removePromoCode();
        this.setState({
            showPromoCodeForm: false,
            promoCode: ''
        });
    }

    togglePromoCodeForm = () => {
        this.setState({
            showPromoCodeForm: !this.state.showPromoCodeForm
        });
    }

    render() {

        let rows = null;
        let subtotal = 0;
        let total = 0;
        let totalPromoDiscount = 0;

        const {
            cartItems,
            discountReasons,
            tax,
            capabilities
        } = this.props;
        const { showPromoCodeForm, promoCode, discountValues, errors } = this.state;

        const totalTax = ProductHelper.getTotalTax(tax);

        if (cartItems.length) {
            rows = cartItems.map((item, index) => {
                let addDiscountButton,
                    removeDiscountButton,
                    discountForm,
                    discountedPrice,
                    discountedRowTotal = null;

                let price = FormatHelper.formatPrice(item.price);
                let rowTotal = FormatHelper.formatPrice(item.price * item.quantity);
                totalPromoDiscount += item.promoDiscount * item.quantity;

                if (item.discount && item.discount.percent === 0) {
                    // we begin discount, but not entered any discount value
                    price = <del>{price}</del>;
                    rowTotal = <del>{rowTotal}</del>;
                    discountedPrice = FormatHelper.formatPrice(item.price);
                    discountedRowTotal = FormatHelper.formatPrice(item.price * item.quantity);
                }

                if (item.isDiscounted && item.discountAmount > 0) {
                    subtotal = subtotal + item.discountPrice * item.quantity;
                    discountedPrice = FormatHelper.formatPrice(item.discountPrice);
                    discountedRowTotal = FormatHelper.formatPrice(item.discountPrice * item.quantity);
                    price = <del>{price}</del>;
                    rowTotal = <del>{rowTotal}</del>;
                } else {
                    subtotal = subtotal + item.price * item.quantity;
                }

                if (item.discount && this.props.isOSC) {
                    removeDiscountButton = <button
                        data-id={item.id}
                        onClick={this.handleRemoveDiscountClick}
                        disabled={!capabilities.discount}
                        className="remove-discount-action">
                    </button>;

                    discountForm = <div className="form-discount-wrapper">
                        {removeDiscountButton}
                        <input
                            name="discountPercent"
                            data-id={item.id}
                            value={discountValues[item.id]}
                            placeholder="%"
                            onChange={this.onChangeDiscountPercent}
                        />
                        <div className="form-item">
                            <select
                                name={`discountReason_${item.id}`}
                                className={errors[`discountReason_${item.id}`] ? 'error' : ''}
                                data-id={item.id}
                                value={item.discount.reason}
                                onChange={this.onChangeDiscountReason}
                            >
                                <option value="">Discount Reason</option>
                                {
                                    discountReasons.map((discountReason, index) =>
                                        <option key={index} value={discountReason.value}>{discountReason.name}</option>)
                                }
                            </select>
                            <span className="error-message">
                                {errors[`discountReason_${item.id}`]}
                            </span>
                        </div>
                    </div>;
                } else if (this.props.isOSC && item.maxDiscount) {
                    addDiscountButton = <button
                        data-id={item.id}
                        onClick={this.handleAddDiscountClick}
                        disabled={!capabilities.discount}
                        className="link add-discount-action">
                        Add discount %
                    </button>;
                }

                const rowClasses = {
                    'discounted': item.isDiscounted
                };

                return <tr key={index} className={ClassNames(rowClasses)}>
                    <td className="title">
                        {item.title}<br />
                        {addDiscountButton}
                        {discountForm}
                    </td>
                    <td data-title="Quantity:">{item.quantity}</td>
                    <td data-title="Price:">
                        {price}
                        {discountedPrice}
                    </td>
                    <td data-title="Total:">
                        {rowTotal}
                        {discountedRowTotal}
                        {removeDiscountButton}
                    </td>
                </tr>;
            });
        }

        let promocodeRow,
            addPromoCodeButton,
            promocodeForm = null;

        let removePromoCode = <button
            onClick={this.handleRemovePromoCodeClick}
            disabled={!capabilities.discount}
            className="remove-promocode-action">
        </button>;


        if (showPromoCodeForm) {
            promocodeForm = <div className="form-promocode-wrapper">
                {removePromoCode}
                <div className="form-item">
                    <input
                        placeholder="Enter promo code here"
                        className={errors.promoCode ? 'error' : ''}
                        value={promoCode}
                        name="promoCode"
                        onChange={this.onChange}
                        type='text'
                    />
                    <span className="error-message">
                        {errors.promoCode}
                    </span>
                    <button
                        disabled={!promoCode}
                        className="apply-promocode-action"
                        onClick={this.handleApplyPromoCodeClick}
                    />
                </div>
            </div>;

            if (this.props.promoCode && totalPromoDiscount > 0) {
                promocodeRow = <tr>
                    <td colSpan={2}></td>
                    <td>Promo code - {this.props.promoCode.percentOf}%</td>
                    <td colSpan={3}>
                        - {FormatHelper.formatPrice(totalPromoDiscount)}
                        {removePromoCode}
                    </td>
                </tr>;
            } else if (this.props.promoCode) {
                promocodeRow = <tr>
                    <td/>
                    <td colSpan={3}>
                        <span>That promo code does not apply to the items in your cart.</span>
                        {removePromoCode}
                    </td>
                </tr>;
            }

        } else {
            addPromoCodeButton = <button
                className="link add-promocode-action"
                disabled={!capabilities.discount}
                onClick={this.togglePromoCodeForm}>Add promocode</button>;
        }

        total = subtotal;
        if (tax && totalTax) {
            total += totalTax;
        }
        total -= totalPromoDiscount;

        const isZeroTotal = total === 0;
        const isTaxError = !!errors.tax;

        return (
            <div className="checkout-products-wrapper">
                <table>
                    <thead>
                        <tr>
                            <th>Name</th>
                            <th>Quantity</th>
                            <th>Price</th>
                            <th>Total</th>
                        </tr>
                    </thead>
                    <tbody>
                        {rows}
                    </tbody>
                    <tfoot>
                        <tr>
                            <td colSpan={2} className="promocode-column">
                                {addPromoCodeButton}
                                {promocodeForm}
                            </td>
                            <td>Subtotal</td>
                            <td colSpan={3}>{FormatHelper.formatPrice(subtotal)}</td>
                        </tr>
                        {promocodeRow}
                        <tr>
                            <td colSpan={2}></td>
                            <td className={isTaxError ? "error" : ""}>Tax</td>
                            <td className={isTaxError ? "error" : ""} colSpan={3}>
                                {totalTax ? FormatHelper.formatPrice(totalTax) : '-'}
                            </td>
                        </tr>
                        {
                            isTaxError &&
                            <tr>
                                <td className="error" colSpan={4}>
                                    {errors.tax}
                                </td>
                            </tr>
                        }
                        <tr className="grand-total">
                            <td colSpan={2}></td>
                            <td className={isZeroTotal ? "error" : ""}>Total</td>
                            <td className={isZeroTotal ? "error" : ""} colSpan={3}>
                                {FormatHelper.formatPrice(total)}
                            </td>
                        </tr>
                        {
                            isZeroTotal &&
                            <tr>
                                <td className="error" colSpan={4}>
                                    The total amount of the order must be greater than zero
                                </td>
                            </tr>
                        }
                    </tfoot>
                </table>
            </div>
        );
    }
}

export default ProductList;
