import React, { Component} from 'react';
import PropTypes from 'prop-types';
import ModalProduct from '../../components/modal-product/ModalProduct';
import FormatHelper from '../../../../helpers/format';

import './product.scss';
import TruckIcon from '../../../../assets/images/icons/truck.svg';

class Product extends Component {

    static propTypes = {
        isModalDetailsVisible: PropTypes.bool,
        detailsProductId: PropTypes.string,
        product: PropTypes.object,
        cartItem: PropTypes.object,
        addedToCart: PropTypes.bool,
        onAddProduct: PropTypes.func,
        onRemoveProduct: PropTypes.func,
        onDetailsToggle: PropTypes.func
    }

    toggleDetails = () => {
        this.props.onDetailsToggle(this.props.product.id);
    }

    handleAddToCart = () => {
        const { product } = this.props;
        this.props.onAddProduct(product);
    }

    handleRemoveFromCart = () => {
        const { cartItem } = this.props;
        this.props.onRemoveProduct(cartItem);
    }

    render() {
        const  { product, addedToCart, isModalDetailsVisible, detailsProductId } = this.props;

        const productModal = isModalDetailsVisible && product.id === detailsProductId
            ? <ModalProduct
                product={product}
                addedToCart={addedToCart}
                onAddProduct={this.handleAddToCart}
                onRemoveProduct={this.handleRemoveFromCart}
                onClose={this.toggleDetails}
            />
            : null;

        let addToCartButton = null;
        if (addedToCart) {
            addToCartButton = <button
                onClick={this.handleRemoveFromCart}
                className="product-add-to-cart-action primary"
            >
                <span className="desc">Remove from cart</span>
                <span className="price">{FormatHelper.formatPrice(product.totalPrice)}</span>
            </button>;
        } else {
            addToCartButton = <button
                onClick={this.handleAddToCart}
                className="product-add-to-cart-action primary"
            >
                <span className="desc">Add to cart</span>
                <span className="price">{FormatHelper.formatPrice(product.totalPrice)}</span>
            </button>;
        }

        let ftrIcon = null;
        if (product.futureTruckRoll) {
            ftrIcon = <img alt="Future Truck Roll" className="truck-icon" src={TruckIcon}/>;
        }


        return (
            <React.Fragment>
                <div className="product">
                    <div className="product-image-wrapper" onClick={this.toggleDetails}>
                        {
                            product.details.images && product.details.images.length > 0
                                ? <span
                                    className="product-image"
                                    style={{ backgroundImage: "url(" + product.details.images[0].image + ")" }}
                                />
                                : <span className="product-image"/>
                        }
                    </div>

                    <h3 onClick={this.toggleDetails}>{product.names.customerProductName}</h3>

                    <button
                        onClick={this.toggleDetails}
                        className="product-details-action link">
                        View details
                    </button>
                    <span className="product-price">
                        {FormatHelper.formatPrice(product.totalPrice)}
                        {ftrIcon}
                    </span>
                    {addToCartButton}
                </div>
                {productModal}
            </React.Fragment>
        );
    }
}

export default Product;
