import React, { Component} from 'react';
import ClassNames from 'classnames';
import { NavLink, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { isEmpty, isEqual } from 'lodash';

import {
    nameRegEx,
    phoneRegExp,
    cityRegEx,
    uppercaseRegExp,
    stateRegEx,
    emailRegEx,
    zipRegEx
} from '../../../../helpers/fieldValidation';
import FormatHelper from '../../../../helpers/format';

import { setCustomerDetails, resolveAddress } from '../../../../actions/orderActions';
import { toggleModalZipLookup } from '../../../../actions/catalogActions';

import './form-customer.scss';

class FormCustomer extends Component {

    static propTypes = {
        history: PropTypes.object,
        isOSC: PropTypes.bool,
        isExternal: PropTypes.bool,
        isVisible: PropTypes.bool,
        customer: PropTypes.object,
        zipCode: PropTypes.string,
        setCustomerDetails: PropTypes.func,
        onSubmit: PropTypes.func,
        toggleModalZipLookup: PropTypes.func,
        resolveAddress: PropTypes.func
    }

    constructor(props) {
        super(props);

        if (isEmpty(props.customer)) {
            this.state = {
                city: '',
                floor: '',
                postalCode: props.zipCode || '',
                primaryContactEmail: '',
                primaryContactName: '',
                primaryContactPhone: '',
                primaryContactPhoneType: 1,
                state: '',
                street1: '',
                suite: '',
                isVisibleFirst: true,
                errors: {}
            };
        } else {
            this.state = {
                ...props.customer,
                errors: {}
            };
        }
    }

    componentDidUpdate(prevProps) {
        if (!isEqual(prevProps.customer, this.props.customer)) {
            this.setState({
                ...this.props.customer
            });
        }
    }

    onChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value,
            errors: {
                ...this.state.errors,
                [e.target.name]: ''
            }
        });
    }

    validate() {

        const errors = {};
        const {
            city,
            primaryContactEmail,
            primaryContactName,
            primaryContactPhone,
            state,
            street1,
            postalCode
        } = this.state;

        if (primaryContactName) {
            if (!nameRegEx.test(primaryContactName)) {
                errors.primaryContactName = 'Jon Doe';
            }
        } else {
            errors.primaryContactName = 'Name is required field';
        }

        if (primaryContactPhone) {
            if (!phoneRegExp.test(primaryContactPhone)) {
                errors.primaryContactPhone = 'Must be in format (000)000-0000';
            }
        } else {
            errors.primaryContactPhone = 'Phone is required field';
        }

        if (primaryContactEmail) {
            if (!emailRegEx.test(primaryContactEmail)) {
                errors.primaryContactEmail = 'Must be in format test@test.com';
            }
        } else {
            errors.primaryContactEmail = 'Email is required field';
        }

        if (!street1) {
            errors.street1 = 'Installation address is required field';
        }

        if (city) {
            if (!cityRegEx.test(city)) {
                errors.city = 'New York';
            }
        } else {
            errors.city = 'City is required field';
        }

        if (state) {
            if (state.length > 2) {
                errors.state = 'Must be two letters';
            } else if (!uppercaseRegExp.test(state)) {
                errors.state = 'Must be in uppercase';
            } else if (!stateRegEx.test(state)) {
                errors.state = 'NY';
            }
        } else {
            errors.state = 'State is required field';
        }

        if (postalCode) {
            if (!zipRegEx.test(postalCode)) {
                errors.postalCode = 'Must be in format XXXXX or XXXXX-XXXX';
            }
        } else {
            errors.postalCode = 'Zip code is required field';
        }

        this.setState({
            errors: {
                ...errors
            }
        });

        return Object.keys(errors).length === 0;
    }

    handleCartClick = () => {
        const { history } = this.props;
        history.replace({ pathname: '/cart' });
    }

    showModalZipLookup = () => {
        this.props.toggleModalZipLookup(true);
    }

    onCustomerDetailsSubmit = () => {
        if (this.validate()) {

            const { street1, city, state, postalCode } = this.state;
            const customer = { ...this.state };
            delete customer.errors;
            delete customer.isVisibleFirst;

            // try to resolve address
            this.setState({
                errors: {
                    ...this.state.errors,
                    'resolveAddressError': ''
                }
            });
            this.props.resolveAddress({
                line1: street1,
                city,
                region: state,
                postalCode
            }).then((results) => {
                if (results.resolved && !results.error) {
                    customer.postalCode =
                        results.validatedPostalCode ? results.validatedPostalCode : customer.postalCode;

                    customer.primaryContactPhone = FormatHelper.parsePhoneValue(customer.primaryContactPhone);

                    this.props.setCustomerDetails(customer);
                    this.props.onSubmit();
                } else {
                    let errorMessage = 'The address is not valid. Please enter an alternate address.<br/>';
                    if (results.details && results.details.messages) {
                        results.details.messages.forEach((messageDesc) => {
                            errorMessage += messageDesc.details;
                            errorMessage += '<br/>';
                        });
                    } else if (results.error) {
                        errorMessage += results.error.message;
                    }
                    this.setState({
                        errors: {
                            ...this.state.errors,
                            'resolveAddressError': errorMessage
                        }
                    });
                }
            });
        }
    }

    render() {
        const {
            isVisibleFirst,
            primaryContactName,
            primaryContactPhone,
            primaryContactEmail,
            street1,
            suite,
            floor,
            city,
            state,
            postalCode,
            errors
        } = this.state;
        const { isExternal } = this.props;
        const formClasses = {
            'form-customer-wrapper': true,
            'visible': this.props.isVisible
        };

        let cancelAction = <button
            className="link cancel-form-action"
            onClick={this.onCalcel}>
            Cancel
        </button>;

        if (isVisibleFirst) {
            cancelAction = <NavLink
                to="#"
                onClick={this.handleCartClick}
                className="link cancel-form-action"
            >
                Cancel
            </NavLink>;
        }

        let resolveAddressError = null;
        if (errors.resolveAddressError) {
            resolveAddressError = <div
                className="error-wrapper"
                dangerouslySetInnerHTML={{ __html: errors.resolveAddressError }}
            />;
        }

        // address

        return (
            <div className={ClassNames(formClasses)}>
                <h2>Customer details</h2>
                <p className="form-item">
                    <input
                        value={primaryContactName}
                        onChange={this.onChange}
                        name="primaryContactName"
                        placeholder="Name*"
                        className={errors.workOrderNumber ? 'error' : ''}
                    />
                    <span className="error-message">
                        {errors.primaryContactName}
                    </span>
                </p>

                <p className="form-item">
                    <input
                        className={errors.primaryContactPhone ? 'error' : ''}
                        value={primaryContactPhone}
                        onChange={this.onChange}
                        name='primaryContactPhone'
                        placeholder="Phone*"
                    />
                    <span className="error-message">
                        {errors.primaryContactPhone}
                    </span>
                </p>

                <p className="form-item indent-buttom">
                    <input
                        className={errors.primaryContactEmail ? 'error' : ''}
                        value={primaryContactEmail}
                        onChange={this.onChange}
                        name='primaryContactEmail'
                        placeholder="Email*"
                    />
                    <span className="error-message">
                        {errors.primaryContactEmail}
                    </span>
                </p>

                <p className="form-item">
                    <input
                        className={errors.street1 ? 'error' : ''}
                        value={street1}
                        onChange={this.onChange}
                        name='street1'
                        placeholder="Installation address*"
                        disabled={isExternal && street1}
                    />
                    <span className="error-message">
                        {errors.street1}
                    </span>
                </p>

                <div className="two-columns indent-buttom">
                    <p className="form-item">
                        <input
                            value={suite}
                            onChange={this.onChange}
                            name='suite'
                            placeholder="Suite"
                            disabled={isExternal && suite}
                        />
                    </p>

                    <p className="form-item">
                        <input
                            value={floor}
                            onChange={this.onChange}
                            name='floor'
                            placeholder="Floor"
                            disabled={isExternal && floor}
                        />
                    </p>
                </div>

                <p className="form-item">
                    <input
                        className={errors.city ? 'error' : ''}
                        value={city}
                        onChange={this.onChange}
                        name='city'
                        placeholder="City*"
                        disabled={isExternal && city}
                    />
                    <span className="error-message">
                        {errors.city}
                    </span>
                </p>

                <div className="two-columns">
                    <p className="form-item">

                        <input
                            className={errors.state ? 'error' : ''}
                            value={state}
                            onChange={this.onChange}
                            name='state'
                            placeholder="State*"
                            disabled={isExternal && state}
                        />
                        <span className="error-message">
                            {errors.state}
                        </span>
                    </p>

                    <p className="form-item">
                        <input
                            className={errors.postalCode ? 'error' : ''}
                            name="postalCode"
                            value={postalCode}
                            disabled
                        />
                        <span className="error-message">
                            {errors.postalCode}
                        </span>
                        {
                            !isExternal ?
                                <button
                                    onClick={this.showModalZipLookup}
                                    className="link change-zip-action">
                                        Change Zip code
                                </button> : null
                        }

                    </p>
                </div>

                <p className="form-action">
                    {cancelAction}
                    <button
                        className="primary"
                        onClick={this.onCustomerDetailsSubmit}>
                        Save
                    </button>
                </p>
                {resolveAddressError}
            </div>
        );
    }
}

const mapStateToProps = ({ order, profile }) => ({
    isOSC: !!profile.accessToken && profile.expired != null && !profile.expired,
    customer: order.customerAccount,
    isExternal: order.isExternal,
    zipCode: order.saleDetails.zipCode
});

export default withRouter(connect(mapStateToProps, {
    resolveAddress,
    setCustomerDetails,
    toggleModalZipLookup
})(FormCustomer));
