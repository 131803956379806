import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import { connect } from "react-redux";

import PageHome from "./pages/home/Home";
import CreateOrder from "./pages/create-order/CreateOrder";
import PageProducts from "./pages/products/Products";
import PageCart from "./pages/cart/Cart";
import PageCheckout from "./pages/checkout/Checkout";
import PageCheckoutAdvanced from "./pages/checkout-advanced/Checkout";
import PageCheckoutMobile from "./pages/checkout-mobile/Checkout";
import PageCheckoutWithInvoice from "./pages/checkout-with-invoice/CheckoutWithInvoice";
import NewOrderCreated from "./pages/new-order-created/NewOrderCreated";
import AdminPage from "./pages/admin/Admin";
import PageNotFound from "./pages/page-not-found/PageNotFound";
import PageAccessDenied from "./pages/page-access-denied/PageAccessDenied";
import LoginRoute from "./routes/loginRoute";
import MastecRoute from "./routes/mastecRoute";
import OscRoute from "./routes/oscRoute";
import ScrollTop from "./components/scroll-top/ScrollTop";

import "./app.scss";

class App extends Component {
    static propTypes = {
        isOSC: PropTypes.bool,
        user: PropTypes.object,
    };

    render() {
        const { isOSC, user } = this.props;

        return (
            <BrowserRouter>
                <Fragment>
                    <Switch>
                        <MastecRoute
                            exact
                            path="/"
                            component={PageHome}
                            isOSC={isOSC}
                            user={user}
                        />
                        <LoginRoute exact path="/OSCLogin" />
                        <OscRoute
                            exact
                            path="/create-order"
                            component={CreateOrder}
                            isOSC={isOSC}
                            user={user}
                        />
                        <MastecRoute
                            exact
                            path="/products"
                            component={PageProducts}
                            isOSC={isOSC}
                            user={user}
                        />
                        <MastecRoute
                            exact
                            path="/cart"
                            component={PageCart}
                            isOSC={isOSC}
                            user={user}
                        />
                        <MastecRoute
                            exact
                            path="/checkout"
                            component={PageCheckout}
                            isOSC={isOSC}
                            user={user}
                        />
                        <MastecRoute
                            exact
                            path="/checkout-advanced"
                            component={PageCheckoutAdvanced}
                            isOSC={isOSC}
                            user={user}
                        />
                        <Route
                            exact
                            path="/checkout-mobile"
                            component={PageCheckoutMobile}
                        />
                        <OscRoute
                            exact
                            path="/checkout-with-invoice"
                            component={PageCheckoutWithInvoice}
                            isOSC={isOSC}
                            user={user}
                        />
                        <MastecRoute
                            exact
                            path="/new-order-created"
                            component={NewOrderCreated}
                            isOSC={isOSC}
                            user={user}
                        />
                        <Route exact path="/admin" component={AdminPage} />
                        <Route
                            exact
                            path="/access-denied"
                            component={PageAccessDenied}
                        />
                        <Route component={PageNotFound} />
                    </Switch>
                    <ScrollTop />
                </Fragment>
            </BrowserRouter>
        );
    }
}

function mapStateToProps({ profile }) {
    return {
        isOSC:
            !!profile.accessToken &&
            profile.expired != null &&
            !profile.expired,
        user: profile.user,
    };
}

export default connect(mapStateToProps, null)(App);
