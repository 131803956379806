import { combineReducers } from 'redux';
import loginReducer from './loginReducer';
import errorReducer from './errorReducer';
import catalogReducer from './catalogReducer';
import orderReducer from './orderReducer';
import adminReducer from './adminReducer';

export default combineReducers({
    profile: loginReducer,
    errors: errorReducer,
    catalog: catalogReducer,
    order: orderReducer,
    admin: adminReducer
});
