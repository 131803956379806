export const SessionOptions = {
    '1': 'Tech Assist - Same Day',
    '2': 'Tech Assist - FTR',
    '3': 'Consumer (Includes Lead Gen)',
    '4': 'Employees/F&F',
    '5': 'Employee Purchase Program'
};

export const SessionTypes = {
    TechAssistSameDay: '1',
    TechAssistFTR: '2',
    Consumer: '3',
    EmployeesFF: '4',
    EmployeePurchaseProgram: '5'
};

export const FutureTruckRollItemCode = 'SC-9990';
export const BuriedCableItemCode = 'CO-BURYC';

export const CustomersUserGroupId = '5d7a5a2d7d0c7f0011e0ed4b';
export const OscUserGroupId = '5d7a5a2d7d0c7f0011e0ed4c';
export const TechUserGroupId = '5d7a5a2d7d0c7f0011e0ed4d';

export const ResellerRole = 'SmartConnectSales:Reseller';
export const CanAccessRole = 'SmartConnectSales:CanAccessCallCenterInterface';

export const EmployeePurchaseProgramSessionId = '5daedcb69fa1680012c205bf';
