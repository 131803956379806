import moment from 'moment';
import { isEmpty } from 'lodash';

import {
    SessionTypes,
    CustomersUserGroupId,
    OscUserGroupId,
    TechUserGroupId,
    FutureTruckRollItemCode
} from '../constants';
import FormatHelper from './format';

const getWarehouseCategories = (zipLookup, warehousesData) => {

    let warehouseCategories = null;

    if (zipLookup.warehouse && !isEmpty(warehousesData)) {
        warehouseCategories = [];
        const warehouse = warehousesData.warehouses.find((w) => w.name === zipLookup.warehouse);
        if (warehouse) {
            warehouseCategories = warehouse.warehouseCategories;
        }
    }

    return warehouseCategories;
};

const filterByWarehouseCategory = (product, warehouseCategories, zipLookup, isOSC) => {

    if (!zipLookup.warehouse && !isOSC) {
        return true;
    }

    const { warehouseCategory } = product;

    if (warehouseCategory && warehouseCategories) {
        return warehouseCategories.includes(warehouseCategory);
    }

    return false;
};

const filterByUserGroup = (product, groupId) => {

    const { userGroups } = product;

    if (userGroups && Array.isArray(userGroups)) {
        return userGroups.includes(groupId);
    }

    return false;
};

class ProductsHelper {

    static actualPrice(pricing) {
        return pricing
            .filter((o) => {
                if (o.endDate) {
                    return moment().isBetween(o.startDate, o.endDate);
                }

                return moment().isAfter(o.startDate);
            })
            .sort(o => moment(o.startDate))
            .reverse();
    }

    static calculateDiscount(product, discountPercent, promoCode) {
        const discount = {
            isDiscounted: false,
            discountPrice: 0,               // price after dicount
            discountPercent: 0,             // discount percent applied
            discountAmount: 0,              // discount amount
            promoPercent: 0,                // promo percent
            promoDiscount: 0,               // promo discount amount
            totalDiscount: 0,               // total discount amount
            totalDiscountPrice: 0,          // total price after discount/promo
            totalDiscountMaterialPrice: 0,  // total material price after discount/promo
            totalDiscountLaborPrice: 0,     // total labor price after discount/promo

            reachedMaxDiscount: false
        };

        let maxPossibleDiscountAmount = 0;
        let price = product.price;
        let materialPrice = 0;
        let laborPrice = 0;

        if (product.hasMaterial) {
            materialPrice = product.materialPrice;
        }

        if (product.hasLabor) {
            laborPrice = product.laborPrice;
        }
        if (product.maxDiscount) { // calculate discount only if max discount limit is set
            maxPossibleDiscountAmount = product.maxDiscount / 100 * price;

            // check max discount limit
            if (discountPercent > product.maxDiscount) {
                discountPercent = product.maxDiscount;
                discount.reachedMaxDiscount = true;
            }

            if (discountPercent > 0) {
                // manual discount was applied, calculate percentage and amounts
                discount.discountPercent = discountPercent;
                discount.discountAmount = discountPercent / 100 * price;
                discount.discountPrice = price - discount.discountAmount;

                price = discount.discountPrice;

                if (product.hasMaterial) {
                    materialPrice = materialPrice - (discountPercent / 100 * materialPrice);
                }

                if (product.hasLabor) {
                    laborPrice = laborPrice - (discountPercent / 100 * laborPrice);
                }
            } else {
                discount.discountPrice = price;
            }

            if (product.promo && promoCode && promoCode.percentOf && !discount.reachedMaxDiscount) {
                // calculate promo amount and percent
                let promoDiscount = (promoCode.percentOf / 100 * price);
                const totalDiscount = discount.discountAmount + promoDiscount;
                if (totalDiscount > maxPossibleDiscountAmount) {
                    promoDiscount = maxPossibleDiscountAmount - discount.discountAmount;
                    discount.reachedMaxDiscount = true;
                }

                if (discount.reachedMaxDiscount) {
                    discount.promoPercent = product.maxDiscount - discount.discountPercent;
                    if (product.hasMaterial) {
                        materialPrice = product.materialPrice - (product.maxDiscount / 100 * product.materialPrice);
                    }

                    if (product.hasLabor) {
                        laborPrice = product.laborPrice - (product.maxDiscount / 100 * product.laborPrice);
                    }
                } else {
                    discount.promoPercent = promoCode.percentOf;

                    if (product.hasMaterial) {
                        materialPrice = materialPrice - (discount.promoPercent / 100 * materialPrice);
                    }

                    if (product.hasLabor) {
                        laborPrice = laborPrice - (discount.promoPercent / 100 * laborPrice);
                    }
                }
                discount.promoDiscount = FormatHelper.roundNumber(promoDiscount, 2);
            }

            if (discount.discountAmount > 0 || discount.promoDiscount) {

                discount.isDiscounted = true;
                discount.totalDiscount = FormatHelper.roundNumber(discount.discountAmount + discount.promoDiscount, 2);

                if (product.hasMaterial) {
                    discount.totalDiscountMaterialPrice = FormatHelper.roundNumber(materialPrice, 2);
                }

                if (product.hasLabor) {
                    discount.totalDiscountLaborPrice = FormatHelper.roundNumber(laborPrice, 2);
                }

                discount.totalDiscountPrice =
                    FormatHelper.roundNumber(discount.totalDiscountMaterialPrice + discount.totalDiscountLaborPrice, 2);

                discount.promoDiscount = discount.discountPrice - discount.totalDiscountPrice;
            }
        }

        return discount;
    }

    static filterProducts(products, categoryId, isOSC, sessionType, zipLookup, warehousesData, lineOfBusinessId) {
        const warehouseCategories = getWarehouseCategories(zipLookup, warehousesData);

        return Object.keys(products).reduce(
            (filteredProducts, productId) => {
                const product = products[productId];
                const { techPurchaseEligible } = product;

                // don't show In Home Delivery product
                if (product.itemCode === FutureTruckRollItemCode) {
                    return filteredProducts;
                }

                // check if product in selected category
                if (
                    (
                        product.categories &&
                        Array.isArray(product.categories) && product.categories.includes(categoryId)
                    ) ||
                    categoryId === 'All'
                ) {

                    // check if product is excluded by business line
                    if (
                        lineOfBusinessId &&
                        product.excludeFromLob &&
                        product.excludeFromLob.length &&
                        product.excludeFromLob.includes(lineOfBusinessId)
                    ) {
                        return filteredProducts;
                    }

                    if (sessionType && sessionType === SessionTypes.TechAssistSameDay) {
                        if (filterByUserGroup(product, TechUserGroupId) && !techPurchaseEligible) {
                            filteredProducts[productId] = product;
                        }
                        return filteredProducts;
                    }

                    if (isOSC && !filterByUserGroup(product, OscUserGroupId)) {
                        return filteredProducts;
                    }

                    if (!isOSC && !filterByUserGroup(product, CustomersUserGroupId)) {
                        return filteredProducts;
                    }

                    if ( sessionType && sessionType === SessionTypes.EmployeePurchaseProgram && techPurchaseEligible) {
                        // for EmployeePurchaseProgram show only techPurchaseEligible products
                        filteredProducts[productId] = product;
                    } else if (
                        sessionType && sessionType !== SessionTypes.EmployeePurchaseProgram && !techPurchaseEligible
                    ) {
                        // for other sessions show all except techPurchaseEligible products
                        if (filterByWarehouseCategory(product, warehouseCategories, zipLookup, isOSC)) {
                            filteredProducts[productId] = product;
                        }

                    } else if (!sessionType) {
                        // don't have session type - show all
                        if (filterByWarehouseCategory(product, warehouseCategories, zipLookup, isOSC)) {
                            filteredProducts[productId] = product;
                        }
                    }
                }
                return filteredProducts;
            }, {});
    }

    static getCartTotalAmount(cartItems) {
        let totalAmount = 0;
        cartItems.forEach((item) => {
            let totalPrice = item.quantity * FormatHelper.roundNumber(item.price, 2);
            if (item.isDiscounted) {
                totalPrice = item.quantity * FormatHelper.roundNumber(item.totalDiscountPrice, 2);
            }
            totalAmount += FormatHelper.roundNumber(totalPrice, 2);
        });

        return FormatHelper.roundNumber(totalAmount, 2);
    }

    static getTotalTax(taxData) {
        // we need to calculate the tax in the same manner that PayPal is,
        // so split out the items, calculate the rate, multiply by the taxableAmount and round to 2 decimal places
        let taxSum = 0;

        if (taxData && taxData.lines) {
            taxData.lines.forEach((item) => {
                if (item.taxableAmount > 0) {
                    let rate = 0;

                    item.details.forEach((detail) => {
                        if (detail.rate > 0) {
                            rate += detail.rate;
                        }
                    });

                    if (rate > 0) {
                        let taxable = rate * item.taxableAmount;
                        taxSum += FormatHelper.roundNumber(taxable, 2);
                    }
                }
            });
        }
        return taxSum;
    }

    static getTax(taxLine) {
        // we need to calculate the tax in the same manner that PayPal is,
        // so split out the items, calculate the rate, multiply by the taxableAmount and round to 2 decimal places
        let taxSum = 0;
        let rate = 0;
        if (taxLine && taxLine.details && taxLine.taxableAmount) {
            taxLine.details.forEach((item) => {
                if (item.taxableAmount > 0) {
                    rate += item.rate;
                }
            });

            if (rate > 0) {
                let taxable = rate * taxLine.taxableAmount;
                taxSum = FormatHelper.roundNumber(taxable, 2);
            }

        }
        return taxSum;
    }
}

export default ProductsHelper;
