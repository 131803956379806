import React, { Component} from 'react';
import PropTypes from 'prop-types';
import Modal from '../../../../components/modal/Modal';

import './modal-terms.scss';

class ModalTerms extends Component {

    static propTypes = {
        onSubmit: PropTypes.func,
        onClose: PropTypes.func
    }

    constructor(props) {
        super(props);
        this.state = {
            checkOne: false,
            checkTwo: false
        };

        this.onChange = this.onChange.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
    }

    onChange(e) {
        this.setState({[e.target.id]: e.target.checked});
    }

    onSubmit() {
        if (this.state.checkOne && this.state.checkTwo) {
            this.props.onSubmit();
        }
    }

    render() {

        return (
            <Modal size={'l'} onClose={this.props.onClose}>
                <h1 data-title="Terms &amp; Conditions">Terms & Conditions</h1>

                <p>
                    Throughout these Terms and Conditions “you” and “your” refers to the customer(s) requesting
                    and/or purchasing MasTec’s products and services. “MasTec”, “we”, “us” and “our” refers to
                    MasTec North America, Inc., its affiliates, parents and subsidiaries, and its authorized
                    representatives, agents and contractors.
                </p>
                <p>
                    Right to Cancel this Transaction, Refund and Return of Products.
                    You may cancel this transaction at any time prior to midnight of the third business
                    day after the date of this transaction. Attached is a Notice of Cancellation form that
                    provides an explanation of this right.
                </p>
                <p>
                    If you cancel this transaction, MasTec will, within ten (10) business days after
                    receipt of your notice, refund all payments (except for any tips) you made to MasTec for the transaction and
                    notify you whether MasTec intends to have you return the products
                    MasTec sold to you or if MasTec intends to abandon the products.
                </p>
                <p>
                    If MasTec does notify you it intends to have you return any products,
                    you will be notified by MasTec to make the products available for pickup by a
                    MasTec representative at your residence in substantially as good condition as
                    when received by you or notified of the method for shipping the products
                    back to MasTec at MasTec’s expense and risk. If you fail to return products
                    for which MasTec has requested you return, then you will remain liable for
                    performance of all of these Terms and Conditions and payment will be charged back for the products.
                </p>
                <p>
                    Manufacturer is Responsible for its Products.
                    The products MasTec sells to you in your residence are manufactured by other companies.
                    If you have any problems with a product sold to you by MasTec after the cancellation time period,
                    you should contact the manufacturer directly for assistance.
                </p>
                <p>
                    Electronic Signature and Record.
                    MasTec and you agree that your assent and agreement to the transaction and these
                    Terms and Conditions, as well as the terms and conditions on the link below,
                    is in the form of an electronic signature by checking the boxes below and executing an
                    electronic version of your written signature acknowledging you have read and understood
                    these Terms and Conditions, and the terms and conditions in the hyperlink below,
                    and are expressly agreeing to them at the time of the transaction.
                    MasTec and you further agree that an electronic record of the executed Terms and Conditions,
                    retained by MasTec and e-mailed to an e-mail address you provided to MasTec,
                    are the sole original records of this agreement and that the parties do not wish
                    to receive a paper form of the Terms and Conditions.
                </p>

                <p className="checkbox-control">
                    <input
                        checked={this.state.checkOne}
                        onChange={this.onChange}
                        type="checkbox"
                        id="checkOne"
                    />
                    <label htmlFor="checkOne">
                        By checking this box you acknowledge that the MasTec representative has
                        orally informed you of your right to cancel this transaction within
                        THREE BUSINESS DAYS from the date of this transaction.
                    </label>
                </p>

                <p className="checkbox-control">
                    <input
                        checked={this.state.checkTwo}
                        onChange={this.onChange}
                        type="checkbox"
                        id="checkTwo"
                    />
                    <label htmlFor="checkTwo">
                        By checking this box you acknowledge that you have read the Terms and
                        Conditions located on the receipt as well as those at&nbsp;
                        <a href="http://legal.mastecat.com/onestep/termsandconditions.html" className="link">
                            http://legal.mastecat.com/onestep/termsandconditions.html
                        </a>
                        &nbsp;fully and carefully and expressly agree to all of them.
                    </label>
                </p>

                <button
                    onClick={this.onSubmit}
                    disabled={!(this.state.checkOne && this.state.checkTwo)}
                    className="primary">
                    Submit
                </button>
            </Modal>
        );
    }
}

export default ModalTerms;
