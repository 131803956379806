import React, { Component} from 'react';
import PropTypes from 'prop-types';
import ClassNames from 'classnames';
import FormatHelper from '../../../../helpers/format';
import ProductHelper from '../../../../helpers/products';

import './product-list.scss';

class ProductList extends Component {

    static propTypes = {
        cartItems: PropTypes.array,
        promoCode: PropTypes.object,
        tax: PropTypes.object
    }

    render() {

        const { cartItems, promoCode, tax } = this.props;
        let rows = null;
        let subtotal = 0;
        let total = 0;
        let totalPromoDiscount = 0;

        const totalTax = ProductHelper.getTotalTax(tax);

        if (cartItems.length) {
            rows = cartItems.map((item, index) => {

                let discountedPrice,
                    discountedRowTotal = null;

                let price = FormatHelper.formatPrice(item.price);
                let rowTotal = FormatHelper.formatPrice(item.price * item.quantity);
                totalPromoDiscount += item.promoDiscount * item.quantity;

                if (item.isDiscounted && item.discountAmount > 0) {
                    subtotal = subtotal + item.discountPrice * item.quantity;
                    discountedPrice = FormatHelper.formatPrice(item.discountPrice);
                    discountedRowTotal = FormatHelper.formatPrice(item.discountPrice * item.quantity);
                    price = <del>{price}</del>;
                    rowTotal = <del>{rowTotal}</del>;
                } else {
                    subtotal = subtotal + item.price * item.quantity;
                }

                const rowClasses = {
                    'discounted': item.isDiscounted
                };

                return <tr key={index} className={ClassNames(rowClasses)}>
                    <td className="title">
                        {item.title}
                    </td>
                    <td data-title="Quantity:">{item.quantity}</td>
                    <td data-title="Price:">
                        {price}
                        {discountedPrice}
                    </td>
                    <td data-title="Total:">
                        {rowTotal}
                        {discountedRowTotal}
                    </td>
                </tr>;
            });
        }

        total = subtotal;

        let promocodeRow = null;
        if (tax && totalTax) {
            total += totalTax;
        }
        total -= totalPromoDiscount;

        if (promoCode) {
            promocodeRow = <tr>
                <td colSpan={3}>Promo code - {this.props.promoCode.percentOf}%</td>
                <td>
                - {FormatHelper.formatPrice(totalPromoDiscount)}
                </td>
            </tr>;
        }

        return (

            <div className="order-products-wrapper">
                <table>
                    <thead>
                        <tr>
                            <th>Name</th>
                            <th>Quantity</th>
                            <th>Price</th>
                            <th>Total</th>
                        </tr>
                    </thead>
                    <tbody>
                        {rows}
                    </tbody>
                    <tfoot>
                        <tr>
                            <td colSpan={2}></td>
                            <td>Subtotal</td>
                            <td colSpan={3}>{FormatHelper.formatPrice(subtotal)}</td>
                        </tr>
                        {promocodeRow}
                        <tr>
                            <td colSpan={2}></td>
                            <td>Tax</td>
                            <td colSpan={3}>{totalTax ? FormatHelper.formatPrice(totalTax) : '-'}</td>
                        </tr>
                        <tr className="grand-total">
                            <td colSpan={2}></td>
                            <td>Total</td>
                            <td colSpan={3}>{FormatHelper.formatPrice(total)}</td>
                        </tr>
                    </tfoot>
                </table>
            </div>
        );
    }
}

export default ProductList;
