export default {
    GET_CATALOG_DATA: {
        LOADING: 'GET_CATALOG_DATA',
        SUCCESS: 'GET_CATALOG_DATA_SUCCESS',
        FAILED: 'GET_CATALOG_DATA_FAILED'
    },
    GET_BUSINESS_LINE: {
        LOADING: 'GET_BUSINESS_LINE',
        SUCCESS: 'GET_BUSINESS_LINE_SUCCESS',
        FAILED: 'GET_BUSINESS_LINE_FAILED'
    },
    GET_PRODUCTS: {
        LOADING: 'GET_PRODUCTS',
        SUCCESS: 'GET_PRODUCTS_SUCCESS',
        FAILED: 'GET_PRODUCTS_FAILED'
    },
    GET_CATEGORIES: {
        LOADING: 'GET_CATEGORIES',
        SUCCESS: 'GET_CATEGORIES_SUCCESS',
        FAILED: 'GET_CATEGORIES_FAILED'
    },
    TOGGLE_MODAL_ZIP_LOOKUP: 'TOGGLE_MODAL_ZIP_LOOKUP',
    TOGGLE_MODAL_NO_PRODUCTS: 'TOGGLE_MODAL_NO_PRODUCTS',
    LOOKUP_ZIP: {
        LOADING: 'LOOKUP_ZIP',
        SUCCESS: 'LOOKUP_ZIP_SUCCESS',
        FAILED: 'LOOKUP_ZIP_FAILED'
    },
    FILTER_PRODUCTS: 'FILTER_PRODUCTS',
    GET_WAREHOUSES_DATA: {
        LOADING: 'GET_WAREHOUSES_DATA',
        SUCCESS: 'GET_WAREHOUSES_DATA_SUCCESS',
        FAILED: 'GET_WAREHOUSES_DATA_FAILED'
    }
};
