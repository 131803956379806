import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ClassNames from 'classnames';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { isEmpty, isEqual } from 'lodash';

import Preloader from '../../../../components/preloader/Preloader';

import { getWorkOrder, setSaleDetails, cleanWorkOrder } from '../../../../actions/orderActions';
import { lookUpZip, getCatalogData } from '../../../../actions/catalogActions';

import './tech-assist-form.scss';
import { SessionTypes, EmployeePurchaseProgramSessionId } from '../../../../constants';

class TechAssistForm extends Component {

    static propTypes = {
        history: PropTypes.object,
        isExternal: PropTypes.bool,
        isOSC: PropTypes.bool,
        customer: PropTypes.object,
        user: PropTypes.object,
        sessionType: PropTypes.string,
        lineOfBusiness: PropTypes.string,
        zipCode: PropTypes.string,
        agent: PropTypes.string,
        saleDetails: PropTypes.object,
        isLoading: PropTypes.bool,
        businessLines: PropTypes.array,
        errors: PropTypes.object,
        catalogErrors: PropTypes.object,
        noService: PropTypes.bool,
        zipLookup: PropTypes.object,
        products: PropTypes.object,
        warehousesData: PropTypes.object,
        getWorkOrder: PropTypes.func,
        lookUpZip: PropTypes.func,
        setSaleDetails: PropTypes.func,
        getCatalogData: PropTypes.func,
        cleanWorkOrder: PropTypes.func
    }

    constructor(props) {
        super(props);
        this.state = this.getState();
    }

    getState() {
        const {
            lineOfBusiness,
            lineOfBusinessId,
            workOrderNumber,
            zipCode,
            agent
        } = this.props.saleDetails;

        const state = {
            lineOfBusiness: lineOfBusiness || '',
            lineOfBusinessId: lineOfBusinessId || '',
            workOrderNumber: workOrderNumber || '',
            zipCode: this.props.customer.postalCode || zipCode || this.props.zipCode || '',
            agent: agent || this.props.agent || '',
            step: 1,
            errors: {
                ...this.props.errors
            }
        };

        if (lineOfBusiness && zipCode && agent) {
            state.step = 2;
        }

        return state;
    }

    componentDidMount() {
        const { sessionType, isOSC } = this.props;
        this.props.getCatalogData(sessionType, isOSC);

        this.setBusinessLine();
    }

    componentDidUpdate(prevProps) {
        const { errors, customer, sessionType, businessLines } = this.props;
        if (!isEqual(prevProps.errors, errors)) {
            this.setState({
                errors: {
                    ...errors
                }
            });
        }
        if (prevProps.customer.postalCode !== customer.postalCode) {
            this.setState({
                zipCode: customer.postalCode || ''
            });
        }

        if (prevProps.sessionType !== sessionType) {
            this.setState({
                lineOfBusiness: '',
                lineOfBusinessId: ''
            });
        }

        if (prevProps.businessLines.length === 0 && businessLines.length > 0) {
            this.setBusinessLine();
        }
    }

    setBusinessLine() {
        const { user, lineOfBusiness, businessLines } = this.props;
        const { lineOfBusiness: selectedLineOfBusiness, zipCode, agent } = this.state;

        if (
            businessLines.length > 0 &&
            !selectedLineOfBusiness &&
            (lineOfBusiness || (user.isReseller && user.lineOfBusiness))
        ) {
            const lineOfBusinessToSearch = lineOfBusiness ||
                (user.isReseller && user.lineOfBusiness ? user.lineOfBusiness : '');
            if (lineOfBusinessToSearch) {
                const foundLineOfBusiness = businessLines.find((line) => line.name === lineOfBusinessToSearch);
                if ((foundLineOfBusiness && zipCode && agent) || (user.isReseller && user.lineOfBusiness)) {
                    this.setState({
                        lineOfBusiness: foundLineOfBusiness.name,
                        lineOfBusinessId: foundLineOfBusiness.id,
                        step: 2
                    });
                } else if (foundLineOfBusiness) {
                    this.setState({
                        lineOfBusiness: foundLineOfBusiness.name,
                        lineOfBusinessId: foundLineOfBusiness.id
                    });
                }
            }
        }
    }

    handleChange = (ev) => {
        const { user } = this.props;
        let { value, name } = ev.target;
        const updatedState = {};

        if (name === 'zipCode' && value.length > 5) {
            value = this.state.zipCode;
        }

        if (name === 'agent' && value.length > (user.isReseller ? 20 : 6)) {
            value = this.state.agent;
        }

        if (name === 'lineOfBusiness') {
            // lookup selected line of business name by id
            const { businessLines } = this.props;
            const selectedBusinessLine = businessLines.find((line) => line.id === value);
            updatedState.lineOfBusinessId = value;
            value = selectedBusinessLine.name;
        }

        updatedState[name] = value;
        updatedState.errors = {
            ...this.state.errors,
            [name]: ''
        };

        if (name === 'workOrderNumber') {
            updatedState.step =  1;

            if (!value) {
                this.props.cleanWorkOrder();
            }
        }

        this.setState(updatedState);
    }

    handleContinueClick = () => {

        const { sessionType, products, warehousesData } = this.props;
        const { step, lineOfBusiness, lineOfBusinessId, workOrderNumber, zipCode, agent } = this.state;

        if (step === 1 && workOrderNumber) {
            this.props.getWorkOrder(lineOfBusiness, workOrderNumber).then(() => {
                this.setState({ step: 2 });
            });
        } else if(step === 1) {
            this.setState({ step: 2 });
        }

        if (step === 2 && zipCode) {

            if (
                (sessionType === SessionTypes.TechAssistFTR || sessionType === SessionTypes.TechAssistSameDay) && !agent
            ) {
                return;
            }

            this.props.lookUpZip(
                zipCode,
                sessionType,
                lineOfBusiness,
                lineOfBusinessId,
                true,
                { products, warehousesData },
                (success, zipLookup, filteredProducts) => {
                    if (success && Object.keys(filteredProducts).length > 0) {
                        this.props.setSaleDetails({
                            session: sessionType,
                            lineOfBusiness,
                            lineOfBusinessId,
                            workOrderNumber,
                            zipCode,
                            agent
                        });
                        this.props.history.replace({ pathname: '/products' });
                    }
                });
        }
    }

    render() {

        let isActionDisabled = false;
        let filteredBusinessLines = null;
        const {
            sessionType,
            businessLines,
            catalogErrors,
            customer,
            user,
            isExternal,
            isLoading,
            noService
        } = this.props;
        const { step, lineOfBusiness, lineOfBusinessId, workOrderNumber, zipCode, agent, errors } = this.state;

        if (step === 1 && !lineOfBusiness) {
            isActionDisabled = true;
        } else if (step === 2 && (!zipCode || !lineOfBusiness)) {
            isActionDisabled = true;
        } else if (step === 2 && zipCode && lineOfBusiness) {

            if (
                (
                    sessionType === SessionTypes.TechAssistFTR ||
                    sessionType === SessionTypes.TechAssistSameDay ||
                    user.isReseller
                ) && !agent
            ) {
                isActionDisabled = true;
            }
        }

        let agentPlaceholder = 'Lead Agent';
        if (sessionType === SessionTypes.TechAssistFTR || sessionType === SessionTypes.TechAssistSameDay) {
            agentPlaceholder = 'Lead Agent*';
        }

        if (user.isReseller) {
            agentPlaceholder = 'Transaction ID*';
        }

        if (sessionType === SessionTypes.EmployeePurchaseProgram) {
            filteredBusinessLines = businessLines.filter((bl) => bl.id === EmployeePurchaseProgramSessionId);
        } else {
            filteredBusinessLines = businessLines;
        }

        const formClasses = {
            'tech-assist-form': true,
            'area-not-covered': !isEmpty(catalogErrors) || noService
        };

        return (
            <div className={ClassNames(formClasses)}>
                <p className="form-item">
                    <select
                        name="lineOfBusiness"
                        disabled={user.isReseller}
                        onChange={this.handleChange}
                        value={lineOfBusinessId}
                    >
                        <option>Select Line of Business*</option>
                        {
                            filteredBusinessLines.map((businessLine, index) => {
                                return <option key={index} value={businessLine.id}>{businessLine.name}</option>;
                            })
                        }
                    </select>
                </p>

                <div className="form-item">
                    <input
                        name="workOrderNumber"
                        disabled={user.isReseller}
                        className={errors.workOrderNumber ? 'error' : ''}
                        onChange={this.handleChange}
                        value={workOrderNumber}
                        placeholder="Work Order Number"
                    />
                    <span className="error-message">
                        {errors.workOrderNumber}
                    </span>
                </div>

                {
                    this.state.step === 2 ? <p className="form-item">
                        <input
                            name="zipCode"
                            disabled={isExternal && zipCode}
                            onChange={this.handleChange}
                            value={zipCode}
                            placeholder="Zip Code of service address*"
                        />
                    </p> : null
                }

                {
                    this.state.step === 2 ? <p className="form-item">
                        <input
                            name="agent"
                            onChange={this.handleChange}
                            value={agent}
                            placeholder={agentPlaceholder}
                        />
                    </p> : null
                }

                <p className="form-action-wrapper">
                    <p className="customer-info">
                        {
                            isExternal ?
                                <React.Fragment>
                                    {customer.primaryContactName}<br/>
                                    {`${customer.street1}`}<br/>
                                    {`${customer.city}, ${customer.state} ${customer.postalCode}`}<br/>
                                    {`Tech ID: ${user ? user.employee_number : ''}`}
                                </React.Fragment> : null
                        }
                    </p>
                    <button
                        className="primary"
                        disabled={isActionDisabled}
                        onClick={this.handleContinueClick}
                    >
                        Continue
                    </button>
                </p>

                {
                    isLoading ? <Preloader type="overlay" /> : null
                }
                <p className="coverage-error-message">
                        Unfortunately, that zip code is outside our service area.<br/>
                        We will not be able to connect with you at this time.
                </p>
            </div>
        );
    }
}

const mapStateToProps = ({ catalog, order, profile }) => ({
    isOSC: !!profile.accessToken && profile.expired != null && !profile.expired,
    user: profile.user,
    customer: order.customerAccount,
    isExternal: order.isExternal,
    businessLines: catalog.businessLines,
    saleDetails: order.saleDetails,
    isLoading: order.isLoading || catalog.isLoading,
    errors: order.errors,
    catalogErrors: catalog.errors,
    noService: catalog.noService,
    zipLookup: catalog.zipLookup,
    warehousesData: catalog.warehousesData,
    products: catalog.products
});

export default withRouter(connect(mapStateToProps, {
    getWorkOrder,
    cleanWorkOrder,
    lookUpZip,
    setSaleDetails,
    getCatalogData
})(TechAssistForm));
