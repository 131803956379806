import ACCOUNT from './account';
import CATALOG from './catalog';
import ORDER from './order';
import CART from './cart';
import ADMIN from './admin';

export default {
    ACCOUNT,
    CATALOG,
    ORDER,
    CART,
    ADMIN
};
