import React, { Component} from 'react';
import PropTypes from 'prop-types';
import ClassNames from 'classnames';
import Modal from '../../../../components/modal/Modal';
import './modal-email.scss';

class ModalEmail extends Component {

    static propTypes = {
        onClose: PropTypes.func
    }

    render() {

        const formClasses = {
            'authorize-user-form': true
        };

        return (
            <Modal onClose={this.props.onClose} size={'s'}>
                <h1 data-title="Please enter your email">Please enter your email</h1>
                <p>To continue you need to specify customer email in details</p>
                <div className={ClassNames(formClasses)}>
                    <p className="form-action-wrapper">
                        <button
                            onClick={this.props.onClose}
                            className="primary">
                            Ok
                        </button>
                    </p>
                </div>
            </Modal>
        );
    }
}

export default ModalEmail;
