import React, { Component} from 'react';
import PropTypes from 'prop-types';
import ClassNames from 'classnames';
import './modal.scss';

class Modal extends Component {

    static propTypes = {
        children: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
        size: PropTypes.string,
        twoColumn: PropTypes.bool,
        onClose: PropTypes.func
    }

    render() {

        const modalClasses = {
            'modal-wrapper': true,
            ['size-' + this.props.size] : this.props.size,
            'two-column':this.props.twoColumn
        };

        const closeAction = this.props.onClose ?
            <button onClick={this.props.onClose} className="modal-close-action"></button> : null;

        return (
            <div className={ClassNames(modalClasses)}>
                <div className='modal'>
                    {closeAction}
                    <div className="scroll-wrapper">
                        {this.props.children}
                    </div>
                </div>
            </div>
        );
    }
}

export default Modal;
