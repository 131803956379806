import React, { Component} from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';

import Modal from '../../../../components/modal/Modal';

class ModalInvoiceWarning extends Component {

    static propTypes = {
        onSetInvoice: PropTypes.func,
        onClose: PropTypes.func
    }

    handleOkClick = () => {
        const { onSetInvoice } = this.props;
        onSetInvoice();
    }

    handleCancelClick = () => {
        const { onClose } = this.props;
        onClose();
    }

    render() {
        return (
            <Modal size={'s'}>
                <h1 data-title="Invoice Already Sent">Invoice Already Sent</h1>
                <p>
                    It is possible that for this order the invoice was already sent.
                    Do you want to go to the invoice payment check page.
                </p>
                <p className="action-wrapper">
                    <button
                        onClick={this.handleOkClick}
                        className="primary">
                        Ok
                    </button>
                    <button
                        onClick={this.handleCancelClick}
                        className="primary">
                        Cancel
                    </button>
                </p>
            </Modal>
        );
    }
}

export default withRouter(ModalInvoiceWarning);
