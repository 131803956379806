import React, { Component} from 'react';
import PropTypes from 'prop-types';
import ClassNames from 'classnames';

import './form-check-payment.scss';

class FormCheckPayment extends Component {

    static propTypes = {
        isLoading: PropTypes.bool,
        email: PropTypes.string,
        invoice: PropTypes.object,
        onCheck: PropTypes.func
    }

    constructor(props) {
        super(props);

        this.state = {
            checkingStatus: ''
        };
    }

    componentDidUpdate(prevProps) {
        const { invoice, isLoading } = this.props;

        if (prevProps.isLoading && !isLoading && (invoice.status === 'SENT' || invoice.status === 'SCHEDULED') ) {
            this.setState({
                checkingStatus: 'failed'
            });
        }
        else if (!prevProps.isLoading && isLoading && (invoice.status === 'SENT' || invoice.status === 'SCHEDULED')) {
            this.setState({
                checkingStatus: 'loading'
            });
        }
        else if (prevProps.isLoading && !isLoading && invoice.status === 'PAID' ) {
            this.setState({
                checkingStatus: 'success'
            });
        } else if (prevProps.isLoading && !isLoading && invoice.status === 'CANCELLED' ) {
            this.setState({
                checkingStatus: 'cancelled'
            });
        }
    }

    render() {

        const { invoice, isLoading, email } = this.props;
        const { checkingStatus } = this.state;

        let checkButton,
            loading = null;

        if ( (invoice.status === 'SENT' || invoice.status === 'SCHEDULED') && !isLoading) {

            checkButton = <button
                className="primary"
                onClick={this.props.onCheck}
            >
                Check payment
            </button>;
        }

        const loaderClasses = {
            'loader-wrapper' : true,
            [checkingStatus] : true
        };

        loading = <div className={ClassNames(loaderClasses)}>
            <p className="message-loading">Please wait, we are checking</p>
            <p className="message-failed">Order has not been paid yet, check later</p>
            <p className="message-success">Congratulations, your order has been successfully paid</p>
            <p className="message-cancelled">
                The invoice has been cancelled. Please send invoice again or cancel the order
            </p>
        </div>;

        const formClasses = {
            'form-check-payment-wrapper': true
        };

        return (
            <div className={ClassNames(formClasses)}>
                <div className="check-payment-wrapper">

                    <p className="message">
                        Invoice has been sent to <b>{email}</b>. <br/>
                    </p>
                    {checkButton}
                    {loading}
                </div>
            </div>
        );
    }
}

export default FormCheckPayment;
