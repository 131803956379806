import React, { Component} from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { NavLink, withRouter } from 'react-router-dom';

import './hero.scss';

class Hero extends Component {

    static propTypes = {
        history: PropTypes.object
    }

    handleProductsClick = () => {

        const { history } = this.props;
        history.replace({ pathname: '/products' });
    }

    render() {
        return (
            <div className="hero">
                <h2>
                    <b>Get Connected with </b>
                    Smart Home Product & Installation
                </h2>

                <p>
                    All the latest technology and home products in one place and connected by our friendly,
                    prompt and knowledgeable service professionals.
                </p>

                <p>
                    <i>Smart products.</i> <i>Smart choices.</i><br/>
                    <i>Smart people.</i> <i>Smart solutions.</i>
                </p>
                <p className="hero-action">
                    <NavLink to="#" onClick={this.handleProductsClick} className="primary">SHOP PRODUCTS</NavLink>
                </p>
            </div>
        );
    }
}

export default withRouter(connect(null,null)(Hero));
