import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import Header from '../../components/header/Header';
import Footer from '../../components/footer/Footer';
import Hero from '../../components/hero/Hero';

import { getCategories } from '../../actions/catalogActions';

class PageHome extends Component {

    static propTypes = {
        getCategories: PropTypes.func
    }

    componentDidMount() {
        this.props.getCategories();
    }

    render() {
        return (
            <div className="page">
                <Header />
                <Hero />
                <Footer />
            </div>
        );
    }
}

export default withRouter(connect(null, {
    getCategories
})(PageHome));
