import { cloneDeep } from 'lodash';
import CNST from '../actions/types';
import {
    LOGOUT
} from '../actions/types';
import ProductsHelper from '../helpers/products';

const initialState = {
    lineOfBusinessId: '',
    businessLines: [],
    categories: [],
    products: {},
    productsHydrated: false,
    filters: {
        categoryId: 'All'
    },
    filteredProducts: {},
    warehousesData: {},
    zipLookup: {},
    errors: {},
    isLoading: false,
    modalZipLookUpVisible: false,
    modalNoProductsVisible: false,
    noService: false
};


export default function catalogReducer(state = cloneDeep(initialState), action) {
    switch (action.type) {
        case LOGOUT:
            return {
                ...state,
                ...initialState
            };
        case CNST.CATALOG.GET_BUSINESS_LINE.SUCCESS: {
            const { businessLines } = action.response;
            return { ...state, ...{ errors: {}, businessLines, isLoading: false } };
        }
        case CNST.CATALOG.GET_CATALOG_DATA.SUCCESS: {
            const { products, categories, warehousesData } = action.response;
            return {
                ...state,
                errors: {},
                isLoading: false,
                products,
                filteredProducts: ProductsHelper.filterProducts(
                    products,
                    state.filters.categoryId,
                    action.isOSC,
                    action.sessionType,
                    state.zipLookup,
                    warehousesData,
                    state.lineOfBusinessId
                ),
                productsHydrated: true,
                categories,
                warehousesData
            };
        }
        case CNST.CATALOG.GET_CATEGORIES.SUCCESS: {
            const { categories } = action.response;
            return { ...state, ...{ errors: {}, categories, isLoading: false } };
        }
        case CNST.CATALOG.GET_PRODUCTS.SUCCESS: {
            const { products } = action.response;
            return {
                ...state,
                ...{
                    errors: {},
                    products,
                    filteredProducts: ProductsHelper.filterProducts(
                        products,
                        state.filters.categoryId,
                        action.isOSC,
                        action.sessionType,
                        state.zipLookup,
                        state.warehousesData,
                        state.lineOfBusinessId
                    ),
                    isLoading: false,
                    productsHydrated: true
                }
            };
        }
        case CNST.CATALOG.GET_WAREHOUSES_DATA.SUCCESS: {
            const { warehousesData } = action.response;
            return {
                ...state,
                ...{
                    errors: {},
                    warehousesData,
                    filteredProducts: ProductsHelper.filterProducts(
                        state.products,
                        state.filters.categoryId,
                        action.isOSC,
                        action.sessionType,
                        state.zipLookup,
                        state.warehousesData,
                        state.lineOfBusinessId
                    ),
                    isLoading: false
                }
            };
        }
        case CNST.CATALOG.LOOKUP_ZIP.SUCCESS: {
            let noService = false;
            let { zipLookup, filteredProducts } = action.response;

            if (Object.keys(filteredProducts).length === 0) {
                noService = true;
            }

            return {
                ...state,
                errors: {},
                filteredProducts,
                zipLookup,
                isLoading: false,
                noService
            };
        }
        case CNST.CATALOG.GET_BUSINESS_LINE.FAILED:
        case CNST.CATALOG.GET_PRODUCTS.FAILED:
        case CNST.CATALOG.GET_CATALOG_DATA.FAILED:
        case CNST.CATALOG.GET_CATEGORIES.FAILED:
        case CNST.CATALOG.GET_WAREHOUSES_DATA.FAILED:
            return { ...state, ...{ errors: action.response.error, isLoading: false } };
        case CNST.CATALOG.LOOKUP_ZIP.FAILED:
            return { ...state, ...{ errors: action.response.error, noService: true, isLoading: false } };
        case CNST.CATALOG.GET_BUSINESS_LINE.LOADING:
        case CNST.CATALOG.GET_CATALOG_DATA.LOADING:
        case CNST.CATALOG.GET_PRODUCTS.LOADING:
        case CNST.CATALOG.GET_CATEGORIES.LOADING:
        case CNST.CATALOG.LOOKUP_ZIP.LOADING:
            return { ...state, ...{ isLoading: true, errors: {}, noService: false } };
        case CNST.CATALOG.GET_WAREHOUSES_DATA.LOADING:
            return { ...state, ...{ isLoading: true, errors: {} } };
        case CNST.CATALOG.TOGGLE_MODAL_ZIP_LOOKUP: {
            const { visible } = action;
            let { noService } = state;
            if (visible) {
                noService = false;
            }
            return {
                ...state,
                modalZipLookUpVisible: visible,
                noService
            };
        }
        case CNST.CATALOG.TOGGLE_MODAL_NO_PRODUCTS: {
            const { visible, clearZipLookup } = action;
            if (clearZipLookup) {
                return {
                    ...state,
                    modalNoProductsVisible: visible,
                    zipLookup: {}
                };
            }
            return {
                ...state,
                modalNoProductsVisible: visible
            };
        }
        case CNST.CATALOG.FILTER_PRODUCTS: {
            const { categoryId } = action;
            return {
                ...state,
                filters: {
                    categoryId
                },
                filteredProducts: ProductsHelper.filterProducts(
                    state.products,
                    categoryId,
                    action.isOSC,
                    action.sessionType,
                    state.zipLookup,
                    state.warehousesData,
                    state.lineOfBusinessId
                )
            };
        }
        case CNST.ORDER.CLEAR_ORDER:
            return {
                ...initialState
            };
        case CNST.ORDER.CLEAR_SALE_DETAILS:
            return {
                ...state,
                lineOfBusinessId: '',
                zipLookup: {}
            };
        case CNST.ORDER.SET_SALE_DETAILS:
            return {
                ...state,
                lineOfBusinessId: action.data.lineOfBusinessId
            };
        default:
            return state;
    }
}
