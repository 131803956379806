import React, { Component} from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter, NavLink } from 'react-router-dom';
import ClassNames from 'classnames';
import { isEmpty, isEqual } from 'lodash';
import moment from 'moment';

import ProductsHelper from '../../helpers/products';

import Header from '../../components/header/Header';

import Footer from '../../components/footer/Footer';
import FormCustomer from './components/form-customer/FormCustomer';
import FormSale from './components/form-sale/FormSale';
import FormPayPal from './components/form-paypal/FormPayPal';
import ProductList from './components/product-list/ProductList';
import ModalTerms from './components/modal-terms/ModalTerms';
import ModalEmail from './components/modal-email/ModalEmail';
import Preloader from '../../components/preloader/Preloader';
import ModalZipLookUp from '../../components/modal-zip-lookup/ModalZipLookUp';
import ModalNoProducts from '../../components/modal-no-products/ModalNoProducts';
import ModalZipCodeChangeNotice from './components/modal-zipcode-change-notice/ModalZipcodeChangeNotice';
import ModalInvoiceWarning from '../checkout/components/modal-invoice-warning/ModalInvoiceWarning';

import { SessionTypes } from '../../constants';

import { lookUpZip, toggleModalNoProducts } from '../../actions/catalogActions';

import {
    clearOrder,
    clearSaleDetails,
    getDiscountReasons,
    getPromoCode,
    setDiscount,
    removeDiscount,
    removePromoCode,
    getTaxRates,
    setInstallationDate,
    setInstallationTime,
    setTermsSigned,
    getScheduleAvailability,
    getInvoice,
    setInvoice
} from '../../actions/orderActions';

import './checkout.scss';

class PageCheckout extends Component {

    static propTypes = {
        history: PropTypes.object,
        isLoading: PropTypes.bool,
        isGetScheduleAvailabilityLoading: PropTypes.bool,
        isOSC: PropTypes.bool,
        user: PropTypes.object,
        customer: PropTypes.object,
        zipLookup: PropTypes.object,
        saleDetails: PropTypes.object,
        cartItems: PropTypes.array,
        discountReasons: PropTypes.array,
        promoCode: PropTypes.object,
        tax: PropTypes.object,
        invoice: PropTypes.object,
        installationDate: PropTypes.object,
        installationTime: PropTypes.string,
        scheduleAvailability: PropTypes.array,
        isTermsSigned: PropTypes.bool,
        modalZipLookUpVisible: PropTypes.bool,
        modalNoProductsVisible: PropTypes.bool,
        products: PropTypes.object,
        warehousesData: PropTypes.object,
        errors: PropTypes.object,
        getDiscountReasons: PropTypes.func,
        clearOrder: PropTypes.func,
        clearSaleDetails: PropTypes.func,
        setDiscount: PropTypes.func,
        removeDiscount: PropTypes.func,
        getPromoCode: PropTypes.func,
        removePromoCode: PropTypes.func,
        getTaxRates: PropTypes.func,
        setInstallationDate: PropTypes.func,
        setInstallationTime: PropTypes.func,
        setTermsSigned: PropTypes.func,
        getScheduleAvailability: PropTypes.func,
        lookUpZip: PropTypes.func,
        setInvoice: PropTypes.func
    }

    constructor(props) {
        super(props);

        this.state = {
            isCustomerEditable: isEmpty(props.customer),
            isTermsVisible: false,
            isEmailModalVisible: false,
            modalZipCodeChangeNoticeVisible: false,
            errors: {
                ...props.errors
            }
        };
    }

    componentDidMount() {
        const { history, cartItems, isOSC, customer, saleDetails, invoice } = this.props;

        if (isEmpty(cartItems) && isOSC) {
            history.replace({ pathname: '/create-order' });
            return;
        } else if (isEmpty(cartItems)) {
            history.replace({ pathname: '/' });
            return;
        } else if (invoice && invoice.status === 'SENT') {
            history.replace({ pathname: '/checkout-with-invoice' });
            return;
        }

        const capabilities = this.getCapabilities();
        if (capabilities.calendar) {
            this.props.getScheduleAvailability(customer, cartItems, saleDetails.lineOfBusiness);
        }

        this.props.getDiscountReasons();
        this.fetchTaxRates();
    }

    componentDidUpdate(prevProps) {

        const { customer, cartItems, promoCode, invoice, saleDetails } = this.props;

        if (
            !isEqual(prevProps.customer, customer) ||
            !isEqual(prevProps.cartItems, cartItems) ||
            !isEqual(prevProps.promoCode, promoCode)
        ) {
            this.fetchTaxRates();
        }

        if (prevProps.invoice.status !== 'PAID' && invoice.status === 'PAID') {
            this.props.history.replace({ pathname: '/new-order-created' });
        }

        if (!isEqual(prevProps.customer, customer)) {
            const capabilities = this.getCapabilities();
            if (capabilities.calendar) {
                this.props.getScheduleAvailability(customer, cartItems, saleDetails.lineOfBusiness);
            }

            if (prevProps.customer.postalCode) {
                if (!customer.postalCode.includes(prevProps.customer.postalCode)) {
                    this.zipLookUp(customer.postalCode);
                }
            } else if (!customer.postalCode.includes(saleDetails.zipCode)) {
                this.zipLookUp(customer.postalCode);
            }

            // try to get possible already sent invoice
            getInvoice(customer, cartItems).then((invoice) => {
                if (invoice) {
                    this.setState({
                        invoiceId: invoice.invoiceId,
                        invoiceStatus: invoice.status,
                        modalInvoiceWarningVisible: true
                    });
                }
            });
        }
    }

    zipLookUp(zipCode) {

        if (zipCode.includes('-')) {
            zipCode = zipCode.substring(0, zipCode.indexOf('-'));
        }

        const { saleDetails, isOSC, cartItems, products, warehousesData } = this.props;

        if (zipCode) {
            this.props.lookUpZip(
                zipCode,
                saleDetails.session,
                saleDetails.lineOfBusiness,
                saleDetails.lineOfBusinessId,
                isOSC,
                { products, warehousesData },
                (success, zipLookup, filteredProducts) => {
                    if (success) {
                        const notAvailableProducts = cartItems.filter((item) => !filteredProducts[item.id]);
                        if (notAvailableProducts.length > 0) {
                            this.setState({
                                modalZipCodeChangeNoticeVisible: true,
                                noProducts: Object.keys(filteredProducts).length === 0
                            });
                        }
                    }
                }
            );
        }
    }

    onCloseModalZipCodeChangeNotice = () => {
        this.setState({
            modalZipCodeChangeNoticeVisible: false
        });
    }

    onCloseModalInvoiceWarning = () => {
        this.setState({
            modalInvoiceWarningVisible: false
        });
    }

    handleSetInvoice = () => {
        const { invoiceId, invoiceStatus } = this.state;
        const { history, setInvoice } = this.props;

        setInvoice(invoiceId, invoiceStatus);

        history.push({ pathname: '/checkout-with-invoice' });
    }

    toggleCustomerDetails = () => {
        const { isCustomerEditable } = this.state;

        this.setState({
            isCustomerEditable: !isCustomerEditable
        });
    }

    toggleTerms = () => {

        const { customer } = this.props;
        if (customer.primaryContactEmail) {
            this.setState({isTermsVisible: !this.state.isTermsVisible});
        } else {
            this.setState({
                isCustomerEditable: true,
                isEmailModalVisible: true
            });
        }
    }

    toggleEmail = () => {
        this.setState({isEmailModalVisible: !this.state.isEmailModalVisible});
    }

    onTermsSubmit = () => {
        this.toggleTerms();
        this.props.setTermsSigned();
    }

    onCustomerDetailsSubmit = () => {
        this.toggleCustomerDetails();
    }

    fetchTaxRates = () => {

        const { customer, cartItems, saleDetails } = this.props;

        if ((!isEmpty(customer) || saleDetails.zipCode) && cartItems.length > 0) {
            const {street1, city, state, postalCode} = customer || '';

            const data = {
                orderDate: moment().format('YYYY-MM-DD'),
                address: {
                    line1: street1 || '',
                    city: city || '',
                    region: state || '',
                    postalCode: postalCode || saleDetails.zipCode
                },
                products: []
            };

            cartItems.forEach((item) => {
                if (item.hasMaterial && item.materialTaxCode) {
                    let amount = item.materialPrice * item.quantity;
                    if (item.isDiscounted) {
                        amount = item.totalDiscountMaterialPrice * item.quantity;
                    }
                    const materialRes = {
                        quantity: item.quantity,
                        amount,
                        taxCode: item.materialTaxCode,
                        itemCode: item.materialItemCode
                    };
                    data.products.push(materialRes);
                }
                if (item.hasLabor && item.laborTaxCode) {
                    let amount = item.laborPrice * item.quantity;
                    if (item.isDiscounted) {
                        amount = item.totalDiscountLaborPrice * item.quantity;
                    }
                    const laborRes = {
                        quantity: item.quantity,
                        amount,
                        taxCode: item.laborTaxCode,
                        itemCode: item.laborItemCode
                    };
                    data.products.push(laborRes);
                }
            });

            this.props.getTaxRates(data);
        }
    }

    getCapabilities() {

        const {
            cartItems,
            saleDetails,
            customer,
            installationDate,
            installationTime,
            isTermsSigned,
            errors
        } = this.props;
        const { isCustomerEditable } = this.state;
        const capabilities = {
            calendar: false,
            checkout: false,
            payment: false,
            discount: false
        };

        if (cartItems.length > 0 && !isEmpty(saleDetails)) {

            capabilities.calendar = saleDetails.session !== SessionTypes.TechAssistSameDay &&
                saleDetails.session !== SessionTypes.EmployeePurchaseProgram && !isEmpty(customer);
            capabilities.checkout = !isCustomerEditable && !isEmpty(customer);

            if (capabilities.calendar && capabilities.checkout) {
                capabilities.checkout = installationDate && installationTime;
            }

            for (const cartItem of cartItems) {
                if (cartItem.discount && cartItem.discount.percent > 0 && !cartItem.discount.reason) {
                    capabilities.checkout = false;
                    break;
                }
            }

            if (!isEmpty(errors)) {
                for (const errorKey of Object.keys(errors)) {
                    if (errors[errorKey]) {
                        capabilities.checkout = false;
                        break;
                    }
                }
            }

            if (capabilities.checkout) {
                const sum = ProductsHelper.getCartTotalAmount(cartItems);
                if (sum === 0) {
                    capabilities.checkout = false;
                }
            }

            capabilities.payment = capabilities.checkout && isTermsSigned;
            capabilities.discount = !isCustomerEditable;
        }

        return capabilities;
    }

    handleCancelSaleClick = () => {
        const { isOSC } = this.props;
        this.props.clearOrder();
        if (isOSC) {
            this.props.history.replace({ pathname: '/create-order' });
        } else {
            this.props.history.replace({ pathname: '/' });
        }
    }

    handleCheckoutWithInvoice = () => {
        this.props.history.replace({ pathname: '/checkout-with-invoice' });
    }

    handleCartClick = () => {
        const { history } = this.props;
        history.replace({ pathname: '/cart' });
    }

    handleStandardCheckoutClick = () => {
        window.location.href = '/checkout';
    }

    onChangeZipCode = () => {
        const { isOSC } = this.props;
        this.props.clearSaleDetails(isOSC);
        if (isOSC) {
            this.props.history.replace({ pathname: '/create-order' });
        } else {
            this.props.history.replace({ pathname: '/products', state: { showZipLookup: true } });
        }
    }

    setInstallationDate = (date) => {
        this.props.setInstallationDate(date);
    }

    setInstallationTime = (time, id) => {
        this.props.setInstallationTime(time, id);
    }

    renderPaymentButtons = (capabilities) => {
        const { isOSC } = this.props;
        let invoiceButton = null;
        let paypalForm = null;

        if (capabilities.payment && isOSC) {
            invoiceButton = <div className='payment-button-wrapper'>
                <NavLink
                    to="#"
                    onClick={this.handleCheckoutWithInvoice}
                    disabled={this.state.isCustomerEditable}
                    className="primary pp">
                    Send PayPal invoice
                </NavLink>
            </div>;
        }
        if (capabilities.payment) {
            paypalForm = <FormPayPal />;
        }

        return (
            <>
                {invoiceButton}
                {paypalForm}
            </>
        );
    }

    render() {
        const {
            cartItems,
            discountReasons,
            promoCode,
            invoice,
            tax,
            installationDate,
            installationTime,
            scheduleAvailability,
            errors,
            isOSC,
            modalZipLookUpVisible,
            modalNoProductsVisible
        } = this.props;
        const {
            modalZipCodeChangeNoticeVisible,
            modalInvoiceWarningVisible,
            noProducts
        } = this.state;
        const capabilities = this.getCapabilities();
        const pageClasses = {
            'page': true,
            'page-checkout': true,
            'form-customer-visible': this.state.isCustomerEditable,
            'modal-active': this.state.isTermsVisible
        };

        return (
            <div className={ClassNames(pageClasses)}>
                <Header
                    onLogin={this.toggleAdminLogin}
                    onCartClick={this.toggleCart}
                />
                <div className="page-content-wrapper">
                    <main>
                        <div className="navigation-wrapper">
                            <NavLink
                                to="#"
                                onClick={this.handleCartClick}
                                className="back-action"
                            >
                                Back to cart
                            </NavLink>
                            <a
                                className="standard-checkout-action"
                                href="#"
                                onClick={this.handleStandardCheckoutClick}
                            >
                                Switch to standard checkout
                            </a>
                        </div>

                        <h1 data-title="Checkout">Checkout</h1>

                        <ProductList
                            setDiscount={this.props.setDiscount}
                            removeDiscount={this.props.removeDiscount}
                            getPromoCode={this.props.getPromoCode}
                            removePromoCode={this.props.removePromoCode}
                            discountReasons={discountReasons}
                            cartItems={cartItems}
                            promoCode={promoCode}
                            tax={tax}
                            capabilities={capabilities}
                            errors={errors}
                            isOSC={isOSC}
                        />

                        <div className="checkout-actions-wrapper">
                            {
                                invoice.status !== 'PAID' ?
                                    <button
                                        className="link cancel-action"
                                        onClick={this.handleCancelSaleClick}
                                    >
                                        Cancel Sale
                                    </button> : null
                            }

                            <div className="payment-wrapper">
                                {
                                    !capabilities.payment ?
                                        <div className='payment-button-wrapper'>
                                            <button
                                                onClick={this.toggleTerms}
                                                className="primary"
                                                disabled={!capabilities.checkout}
                                            >
                                                Checkout
                                            </button>
                                        </div> : null
                                }
                                {this.renderPaymentButtons(capabilities)}
                            </div>
                        </div>
                    </main>
                    <aside>
                        <FormSale
                            isLoading={this.props.isGetScheduleAvailabilityLoading}
                            customer={this.props.customer}
                            installationDate={installationDate}
                            installationTime={installationTime}
                            capabilities={capabilities}
                            isOSC={isOSC}
                            scheduleAvailability={scheduleAvailability}
                            onChangeDetails={this.toggleCustomerDetails}
                            onSetDate={this.setInstallationDate}
                            onSetTime={this.setInstallationTime}
                        />
                        <FormCustomer
                            isVisible={this.state.isCustomerEditable}
                            onSubmit={this.onCustomerDetailsSubmit}
                        />
                    </aside>
                </div>

                <Footer/>
                {
                    this.props.isLoading ? <Preloader type="overlay" /> : null
                }
                {this.state.isTermsVisible ? <ModalTerms
                    onSubmit={this.onTermsSubmit}
                    onClose={this.toggleTerms}
                /> : null}
                {
                    this.state.isEmailModalVisible ?
                        <ModalEmail
                            onClose={this.toggleEmail}
                        /> : null
                }
                {
                    modalZipLookUpVisible ?
                        <ModalZipLookUp
                            description={'Please enter the new zip code.'}
                            redirectToCart
                        /> : null
                }
                {
                    modalNoProductsVisible ? <ModalNoProducts/> : null
                }
                {
                    modalZipCodeChangeNoticeVisible
                        ? <ModalZipCodeChangeNotice
                            noProducts={noProducts}
                            onClose={this.onCloseModalZipCodeChangeNotice}
                        /> : null
                }
                {
                    modalInvoiceWarningVisible
                        ? <ModalInvoiceWarning
                            onSetInvoice={this.handleSetInvoice}
                            onClose={this.onCloseModalInvoiceWarning}
                        /> : null
                }
            </div>
        );
    }
}

const mapStateToProps = ({ order, profile, catalog }) => ({
    isOSC: !!profile.accessToken && profile.expired != null && !profile.expired,
    user: profile.user,
    isLoading: order.isLoading,
    isGetScheduleAvailabilityLoading: order.isGetScheduleAvailabilityLoading,
    customer: order.customerAccount,
    saleDetails: order.saleDetails,
    zipLookup: catalog.zipLookup,
    cartItems: order.items,
    discountReasons: order.discountReasons,
    promoCode: order.promoCode,
    tax: order.tax,
    invoice: order.invoice,
    installationDate: order.installationDate,
    installationTime: order.installationTime,
    errors: order.errors,
    isTermsSigned: order.isTermsSigned,
    scheduleAvailability: order.scheduleAvailability,
    modalZipLookUpVisible: catalog.modalZipLookUpVisible,
    modalNoProductsVisible: catalog.modalNoProductsVisible,
    warehousesData: catalog.warehousesData,
    products: catalog.products
});

export default withRouter(connect(mapStateToProps, {
    clearOrder,
    clearSaleDetails,
    getDiscountReasons,
    setDiscount,
    removeDiscount,
    getPromoCode,
    removePromoCode,
    getTaxRates,
    setInstallationDate,
    setInstallationTime,
    setTermsSigned,
    getScheduleAvailability,
    lookUpZip,
    toggleModalNoProducts,
    setInvoice
})(PageCheckout));
