import { isEmpty } from 'lodash';
import moment from 'moment';
import ApiClient from '../helpers/api';
import CNST from './types';

export function getWorkOrder(businessLine, workOrderNumber) {
    return async (dispatch) => {
        dispatch({
            type: CNST.ORDER.GET_CUSTOMER_ACCOUNT.LOADING
        });

        try {
            const response = await ApiClient.get(`/api/orders/${businessLine}/${workOrderNumber}`);

            dispatch({
                type: CNST.ORDER.GET_CUSTOMER_ACCOUNT.SUCCESS,
                response
            });

            return true;
        } catch (err) {
            console.log('error', err);
            dispatch({
                type: CNST.ORDER.GET_CUSTOMER_ACCOUNT.FAILED,
                response: {
                    error: {
                        workOrderNumber: `Work Order not found.
                            Please try again or enter zip code to proceed without existing Work Order.`
                    }
                }
            });

            return err;
        }
    };
}

export function cleanWorkOrder() {
    return {
        type: CNST.ORDER.CLEAR_CUSTOMER_ACCOUNT
    };
}

export function setCustomerDetails(data) {
    return {
        type: CNST.ORDER.SET_CUSTOMER_DETAILS,
        data
    };
}

export function getDiscountReasons() {
    return async (dispatch) => {
        dispatch({
            type: CNST.ORDER.GET_DISCOUNT_REASONS.LOADING
        });

        try {
            const response = await ApiClient.get('/api/discount-reasons');
            const { data } = response;

            dispatch({
                type: CNST.ORDER.GET_DISCOUNT_REASONS.SUCCESS,
                response: { discountReasons: data }
            });

            return true;
        } catch (err) {
            console.log('error', err);
            dispatch({
                type: CNST.ORDER.GET_DISCOUNT_REASONS.FAILED,
                response: err
            });

            return err;
        }
    };
}

export function getPromoCode(code) {
    return async (dispatch) => {
        dispatch({
            type: CNST.ORDER.GET_PROMO_CODE.LOADING
        });

        try {
            const response = await ApiClient.get(`/api/promo-codes/${code}`);
            if (response && response.data && response.data.length > 0) {
                const promoCode = response.data[0];
                let isActive = true;
                const { dateRange } = promoCode;
                if (dateRange && (dateRange.startDate || dateRange.endDate)) {
                    if (dateRange.startDate && dateRange.endDate) {
                        isActive = moment().isBetween(dateRange.startDate, dateRange.endDate);
                    } else if (dateRange.startDate) {
                        isActive = moment().isAfter(dateRange.startDate);
                    } else if (dateRange.endDate) {
                        isActive = moment().isBefore(dateRange.endDate);
                    }
                }

                if (isActive) {
                    dispatch({
                        type: CNST.ORDER.GET_PROMO_CODE.SUCCESS,
                        response: { promoCode }
                    });
                } else {
                    dispatch({
                        type: CNST.ORDER.GET_PROMO_CODE.FAILED,
                        response: {
                            error: {
                                promoCode: 'Promo Code not active. Please enter another one.'
                            }
                        }
                    });
                }
            } else {
                dispatch({
                    type: CNST.ORDER.GET_PROMO_CODE.FAILED,
                    response: {
                        error: {
                            promoCode: 'Promo Code not found. Please enter another one.'
                        }
                    }
                });
            }

            return true;
        } catch (err) {
            console.log('error', err);
            dispatch({
                type: CNST.ORDER.GET_PROMO_CODE.FAILED,
                response: err
            });

            return err;
        }
    };
}

export function removePromoCode() {
    return {
        type: CNST.ORDER.REMOVE_PROMO_CODE
    };
}

export function setDiscount(productId, percent, reason) {
    return {
        type: CNST.ORDER.DISCOUNT.SET,
        productId,
        percent,
        reason
    };
}

export function removeDiscount(productId) {
    return {
        type: CNST.ORDER.DISCOUNT.REMOVE,
        productId
    };
}

export function setSaleDetails(data) {
    return {
        type: CNST.ORDER.SET_SALE_DETAILS,
        data
    };
}

export function putProductToCart(product) {
    const cartItem = {
        id: product.id,
        title: product.names.customerProductName,
        itemCode: product.itemCode,
        price: product.totalPrice,
        futureTruckRoll: product.futureTruckRoll,
        maxDiscount: product.maxDiscount,
        promo: product.promo,
        quantity: 1
    };

    if (!isEmpty(product.material.pricing)) {
        cartItem.hasMaterial = true;
        cartItem.materialPrice = product.material.pricing[0].price;
        cartItem.materialTaxCode = product.material.avalaraTaxCode;
        cartItem.materialItemCode = product.material.itemCode;
    } else {
        cartItem.hasMaterial = false;
    }

    if (!isEmpty(product.labor.pricing)) {
        cartItem.hasLabor = true;
        cartItem.laborPrice = product.labor.pricing[0].price;
        cartItem.laborTaxCode = product.labor.avalaraTaxCode;
        cartItem.laborItemCode = product.labor.itemCode;
    } else {
        cartItem.hasLabor = false;
    }

    return {
        type: CNST.CART.PUT_PRODUCT_TO_CART,
        item: cartItem
    };
}

export function changeProductQuantity(id, productCode, quantity, useTextQuantity) {
    return {
        type: CNST.CART.CHANGE_PRODUCT_QUANTITY,
        id,
        productCode,
        quantity,
        useTextQuantity
    };
}

export function removeProductFromCart(item) {
    return {
        type: CNST.CART.REMOVE_PRODUCT_FROM_CART,
        item
    };
}

export function clearOrder() {
    return {
        type: CNST.ORDER.CLEAR_ORDER
    };
}

export function clearSaleDetails(isOSC) {
    return {
        type: CNST.ORDER.CLEAR_SALE_DETAILS,
        isOSC
    };
}

export function getTaxRates(data = {}) {
    return async (dispatch) => {
        dispatch({
            type: CNST.ORDER.GET_TAX_RATES.LOADING
        });

        try {
            const response = await ApiClient.post('/api/avatax/taxrates', data);
            dispatch({
                type: CNST.ORDER.GET_TAX_RATES.SUCCESS,
                response: response.data
            });
            return true;
        } catch (err) {
            console.log('error', err);
            dispatch({
                type: CNST.ORDER.GET_TAX_RATES.FAILED,
                response: err
            });

            return err;
        }
    };
}

export function sendInvoice(invoiceData) {
    return async (dispatch) => {
        dispatch({
            type: CNST.ORDER.SEND_INVOICE.LOADING
        });

        try {
            const response = await ApiClient.post('/api/paypal/invoice/send', invoiceData);
            const { data } = response;
            const invoice = { id: data.id, status: data.status, date: moment().format() };
            dispatch({
                type: CNST.ORDER.SEND_INVOICE.SUCCESS,
                invoice
            });

            return true;
        } catch (err) {
            console.log('error', err);
            dispatch({
                type: CNST.ORDER.SEND_INVOICE.FAILED,
                response: err
            });

            return err;
        }
    };
}

export async function getInvoice(customer, products) {
    const data = {
        customer: {
            name: customer.primaryContactName,
            phone: customer.primaryContactPhone,
            email: customer.primaryContactEmail,
            address: customer.street1,
            city: customer.city,
            region: customer.state,
            postalCode: customer.postalCode
        },
        products: products.map((p) => ({
            name: p.title,
            quantity: p.quantity
        }))
    };

    try {
        const response = await ApiClient.post('/api/orders/invoice', data);
        return response.success ? response.data : null;
    } catch {
        return null;
    }
}

export function setInvoice(invoiceId, status) {
    return async (dispatch) => {
        const invoice = { id: invoiceId, status, date: moment().format() };
        dispatch({
            type: CNST.ORDER.SET_INVOICE,
            invoice
        });

        return true;
    };
}

export function getInvoiceStatus(id) {
    return async (dispatch) => {
        dispatch({
            type: CNST.ORDER.GET_INVOICE_STATUS.LOADING
        });

        try {
            const response = await ApiClient.get(`/api/paypal/invoice/${id}/status`);
            const { data } = response;
            const invoice = { id, status: data.status, date: moment().format() };
            dispatch({
                type: CNST.ORDER.GET_INVOICE_STATUS.SUCCESS,
                invoice
            });

            return true;
        } catch (err) {
            console.log('error', err);
            dispatch({
                type: CNST.ORDER.GET_INVOICE_STATUS.FAILED,
                response: err
            });
            return err;
        }
    };
}

export function cancelPaypalInvoice(id) {
    return async (dispatch) => {
        dispatch({
            type: CNST.ORDER.CANCEL_PAYPAL_INVOICE.LOADING
        });

        try {
            const response = await ApiClient.get(`/api/paypal/invoice/${id}/cancel`);
            const { success } = response;
            const invoice = { id: '', status: '', date: '' };

            if (success) {
                dispatch({
                    type: CNST.ORDER.CANCEL_PAYPAL_INVOICE.SUCCESS,
                    invoice
                });
            }

            if (!success) {
                dispatch({
                    type: CNST.ORDER.CANCEL_PAYPAL_INVOICE.FAILED
                });
            }

            return success;
        } catch (err) {
            console.log('error', err);
            dispatch({
                type: CNST.ORDER.CANCEL_PAYPAL_INVOICE.FAILED,
                response: err
            });

            return err;
        }
    };
}

export function submitOrder(orderData = {}) {
    return async (dispatch) => {
        dispatch({
            type: CNST.ORDER.SUBMIT_ORDER.LOADING
        });

        try {
            const response = await ApiClient.put('/api/orders', orderData);
            const { data, success } = response;

            dispatch({
                type: CNST.ORDER.SUBMIT_ORDER.SUCCESS,
                response: { data }
            });

            return success;
        } catch (err) {
            console.log('error', err);
            dispatch({
                type: CNST.ORDER.SUBMIT_ORDER.FAILED,
                response: err
            });

            return err;
        }
    };
}

export function setPaymentSucceeded(paymentId, date) {
    return {
        type: CNST.ORDER.PAYMENT_SUCCEEDED,
        paymentId,
        date
    };
}

export function setInstallationDate(date) {
    return {
        type: CNST.ORDER.SET_INSTALLATION_DATE,
        date
    };
}

export function setInstallationTime(time, id) {
    return {
        type: CNST.ORDER.SET_INSTALLATION_TIME,
        time,
        id
    };
}

export function setTermsSigned() {
    return {
        type: CNST.ORDER.SET_TERMS_SIGNED
    };
}

export function getScheduleAvailability(customer, cartItems, lineOfBusiness) {
    return async (dispatch) => {
        dispatch({
            type: CNST.ORDER.GET_SCHEDULE_AVAILABILITY.LOADING
        });

        try {
            const fromDate = moment().add(1, 'day').startOf('day'); // tomorrow begin
            const toDate = moment(fromDate).add(90, 'day'); // + 90 day

            const orderData = {
                account: {
                    lineOfBusiness,
                    streetAddress1: customer.street1,
                    streetAddress2: '',
                    city: customer.city,
                    state: customer.state,
                    suite: customer.suite,
                    floor: customer.floor,
                    postalCode: customer.postalCode
                },
                saleProducts: [],
                fromDate: fromDate.toISOString(),
                toDate: toDate.toISOString()
            };

            cartItems.forEach((item) => {
                orderData.saleProducts.push({
                    productCode: item.itemCode,
                    quantity: item.quantity
                });
            });

            const response = await ApiClient.post('/api/availability', orderData);
            const { data } = response;

            dispatch({
                type: CNST.ORDER.GET_SCHEDULE_AVAILABILITY.SUCCESS,
                response: { scheduleAvailability: data }
            });

            return true;
        } catch (err) {
            console.log('error', err);
            dispatch({
                type: CNST.ORDER.GET_SCHEDULE_AVAILABILITY.FAILED,
                response: err
            });

            return err;
        }
    };
}

export function resolveAddress(address) {
    return async (dispatch) => {
        dispatch({
            type: CNST.ORDER.RESOLVE_ADDRESS.LOADING
        });

        try {
            const response = await ApiClient.post('/api/avatax/addresses/resolve', address);
            const { data } = response;

            let validatedPostalCode = '';
            if (
                data.resolved &&
                data.details &&
                data.details.validatedAddresses &&
                Array.isArray(data.details.validatedAddresses) &&
                data.details.validatedAddresses.length > 0
            ) {
                validatedPostalCode = data.details.validatedAddresses[0].postalCode;
            }

            data.validatedPostalCode = validatedPostalCode;

            dispatch({
                type: CNST.ORDER.RESOLVE_ADDRESS.SUCCESS,
                response: data
            });

            return data;
        } catch (err) {
            console.log('error', err);
            dispatch({
                type: CNST.ORDER.RESOLVE_ADDRESS.FAILED,
                response: err
            });

            return err;
        }
    };
}

export async function getClientToken(cb) {
    try {
        const response = await ApiClient.get('api/paypal/token');
        const { data } = response;
        if (cb) {
            cb(null, data);
        }
        return {
            data,
            err: null
        };
    } catch (err) {
        if (cb) {
            cb(err);
        }
        return { err };
    }
}

export async function createOrder(order) {
    try {
        const response = await ApiClient.put('api/paypal/orders', { order });
        const { data } = response;

        return {
            data,
            err: null
        };
    } catch (err) {
        return { err };
    }
}

export async function captureOrder(orderId) {
    try {
        const response = await ApiClient.post(`api/paypal/orders/${orderId}/capture`);
        const { data } = response;

        return {
            data,
            err: null
        };
    } catch (err) {
        return { err };
    }
}

export async function sendPayPalLog(success, data, paypalResponse, paypalError, action = 'Manual') {
    try {
        const {
            internalOrderId,
            orderId,
            invoiceId,
            transactionId,
            transactionUpdateDate,
            user,
            customer,
            businessLine,
            workOrderNumber,
            cartItems
        } = data;
        const logItem = {
            internalOrderId: internalOrderId || null,
            orderId: orderId || null,
            invoiceId: invoiceId || null,
            transactionId: transactionId || null,
            transactionUpdateDate: transactionUpdateDate || null,
            date: new Date().toISOString(),
            businessLine,
            workOrderNumber,
            employeeNumber: user.employee_number,
            employeeName: user.display_name,
            source: 'OSC Rep',
            status: success ? 'success' : 'error',
            customer: {
                ...customer,
                email: ''
            },
            products: cartItems.map((cartItem) => ({
                name: cartItem.title,
                itemCode: cartItem.itemCode,
                description: '',
                quantity: cartItem.quantity,
                materialAmount: cartItem.hasMaterial ? cartItem.materialPrice : 0,
                laborAmount: cartItem.hasLabor ? cartItem.laborPrice : 0
            })),
            action,
            errorMessage:
                paypalError && typeof paypalError === 'object' && paypalError.message
                    ? paypalError.message
                    : '',
            paypalResponse:
                paypalResponse && typeof paypalResponse !== 'string'
                    ? JSON.stringify(paypalResponse)
                    : paypalResponse,
            paypalError:
                paypalError && typeof paypalError !== 'string'
                    ? JSON.stringify(paypalError)
                    : paypalError
        };

        await ApiClient.post('/api/paypal/logs', { data: logItem });
    } catch (err) {
        // try again with delay
        setTimeout(() => {
            sendPayPalLog(success, data, paypalResponse, paypalError, action);
        }, 5000);
    }
}
