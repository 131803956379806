
class FormatHelper {

    static formatPrice(number) {

        var formatter = new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: 'USD'
        });

        return formatter.format(number);
    }

    static roundNumber(num, scale) {
        if (!("" + num).includes("e")) {
            return +(Math.round(num + "e+" + scale) + "e-" + scale);
        } else {
            var arr = ("" + num).split("e");
            var sig = "";
            if (+arr[1] + scale > 0) {
                sig = "+";
            }
            return +(Math.round(+arr[0] + "e" + sig + (+arr[1] + scale)) + "e-" + scale);
        }
    }

    static getPrecision(precisionValue) {

        return Math.pow(10, precisionValue);
    }

    static capitalizeFirstLetter(string) {
        return string.charAt(0).toUpperCase() + string.slice(1);
    }

    static toTitleCase(str) {

        if (str) {
            return str.toLowerCase()
                .split(' ')
                .map((s) => s.charAt(0).toUpperCase() + s.substring(1))
                .join(' ');
        }

        return str;
    }

    static formatPhone(phone) {
        let formattedPhone = phone;
        if (phone && phone.length === 10) {
            formattedPhone = `${phone.substring(0, 3)}.${phone.substring(3, 6)}.${phone.substring(6)}`;
        }
        return formattedPhone;
    }

    static parsePhoneValue(phone) {
        let parsedPhone = phone;
        if (parsedPhone) {
            // replace all non digits
            parsedPhone = parsedPhone.replace(/\D/g, '');
        }
        return parsedPhone;
    }

    static formatCustomerInfo(data) {

        if (data.primaryContactName) {
            data.primaryContactName = FormatHelper.toTitleCase(data.primaryContactName.trim());
        }

        if (data.street1) {
            data.street1 = FormatHelper.toTitleCase(data.street1.trim());
        }

        if (data.city) {
            data.city = FormatHelper.capitalizeFirstLetter(data.city.trim());
        }

        if (data.state) {
            data.state = data.state.trim().toUpperCase();
        }

        if (data.primaryContactPhone) {
            let phone = data.primaryContactPhone.replace(/\s/g, '');
            phone = phone.includes('+')
                ? phone.substring(2)
                : phone;
            data.primaryContactPhone = phone;
        }

        return data;
    }
}

export default FormatHelper;
