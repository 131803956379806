import TYPES from './types';
import ApiClient from '../helpers/api';

export function login(password) {
    return async (dispatch) => {
        dispatch({
            type: TYPES.ADMIN.LOGIN.LOADING
        });

        try {
            const response = await ApiClient.post('/api/admin/login', { password });
            const { data } = response;

            dispatch({
                type: TYPES.ADMIN.LOGIN.SUCCESS,
                success: data.success
            });

            return true;
        } catch (err) {
            console.log('error', err);
            dispatch({
                type: TYPES.ADMIN.LOGIN.FAILED,
                response: err
            });
        }
    };
}

export function logout() {
    return async (dispatch) => {
        dispatch({
            type: TYPES.ADMIN.LOGOUT
        });
    };
}

export function getEmailsQueue() {
    return async (dispatch) => {
        dispatch({
            type: TYPES.ADMIN.GET_EMAILS_QUEUE.LOADING
        });

        try {
            const response = await ApiClient.get('/api/admin/emails-queue');
            const { data } = response;

            dispatch({
                type: TYPES.ADMIN.GET_EMAILS_QUEUE.SUCCESS,
                data
            });

            return true;
        } catch (err) {
            console.log('error', err);
            dispatch({
                type: TYPES.ADMIN.GET_EMAILS_QUEUE.FAILED,
                response: err
            });
        }
    };
}

export function startEmailsQueue() {
    return async (dispatch) => {
        dispatch({
            type: TYPES.ADMIN.RUN_ACTION.LOADING
        });

        try {
            const response = await ApiClient.post('/api/admin/emails-queue/start');
            const { data } = response;

            if (!data.success) {
                window.alert('Start email queue not succeeded');
            }

            dispatch({
                type: TYPES.ADMIN.RUN_ACTION.SUCCESS,
                data
            });

            return true;
        } catch (err) {
            console.log('error', err);
            window.alert(`Start email queue failed: ${err}`);
            dispatch({
                type: TYPES.ADMIN.RUN_ACTION.FAILED,
                response: err
            });
        }
    };
}

export function stopEmailsQueue() {
    return async (dispatch) => {
        dispatch({
            type: TYPES.ADMIN.RUN_ACTION.LOADING
        });

        try {
            const response = await ApiClient.post('/api/admin/emails-queue/stop');
            const { data } = response;

            if (!data.success) {
                window.alert('Stop email queue not succeeded');
            }

            dispatch({
                type: TYPES.ADMIN.RUN_ACTION.SUCCESS,
                data
            });

            return true;
        } catch (err) {
            console.log('error', err);
            window.alert(`Stop email queue failed: ${err}`);
            dispatch({
                type: TYPES.ADMIN.RUN_ACTION.FAILED,
                response: err
            });
        }
    };
}

export function sendTestEmail(email) {
    return async (dispatch) => {
        dispatch({
            type: TYPES.ADMIN.RUN_ACTION.LOADING
        });

        try {
            const response = await ApiClient.post('/api/admin/send-test-email', { email });
            const { data } = response;

            if (!data.success) {
                window.alert('Send test email not succeeded');
            }

            dispatch({
                type: TYPES.ADMIN.RUN_ACTION.SUCCESS,
                data
            });

            return true;
        } catch (err) {
            console.log('error', err);
            window.alert(`Send test email failed: ${err}`);
            dispatch({
                type: TYPES.ADMIN.RUN_ACTION.FAILED,
                response: err
            });
        }
    };
}

export function downloadEmailsQueue() {
    return async (dispatch) => {
        dispatch({
            type: TYPES.ADMIN.RUN_ACTION.LOADING
        });

        try {
            const response = await ApiClient.download('/api/admin/emails-queue/download');

            const a = document.createElement('a');
            a.href = URL.createObjectURL(response);
            a.setAttribute('download', 'queue.json');
            a.click();

            dispatch({
                type: TYPES.ADMIN.RUN_ACTION.SUCCESS
            });

            return true;
        } catch (err) {
            console.log('error', err);
            window.alert(`Save email queue failed: ${err}`);
            dispatch({
                type: TYPES.ADMIN.RUN_ACTION.FAILED,
                response: err
            });
        }
    };
}

export function restoreEmailsQueue() {
    return async (dispatch) => {
        dispatch({
            type: TYPES.ADMIN.RUN_ACTION.LOADING
        });

        try {
            const response = await ApiClient.post('/api/admin/emails-queue/restore');
            const { data } = response;

            if (!data.success) {
                window.alert('Restore email queue not succeeded');
            }

            dispatch({
                type: TYPES.ADMIN.RUN_ACTION.SUCCESS,
                data
            });

            return true;
        } catch (err) {
            console.log('error', err);
            window.alert(`Restore email queue failed: ${err}`);
            dispatch({
                type: TYPES.ADMIN.RUN_ACTION.FAILED,
                response: err
            });
        }
    };
}
