import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { NavLink, withRouter } from 'react-router-dom';
import Filters from '../filters/Filters';
import ClassNames from 'classnames';
import UserManager from '../../helpers/userManager';

import './header.scss';

import { logout } from '../../actions/loginActions';
import { toggleModalZipLookup, filterProducts } from '../../actions/catalogActions';

class Header extends Component {

    static propTypes = {
        history: PropTypes.object,
        location: PropTypes.object,
        isOSC: PropTypes.bool.isRequired,
        user: PropTypes.object,
        zipLookup: PropTypes.object,
        categories: PropTypes.array,
        modalZipLookUpVisible: PropTypes.bool,
        productFilters: PropTypes.object,
        saleDetails: PropTypes.object,
        totalCartQuantity: PropTypes.number,
        logout: PropTypes.func.isRequired,
        toggleModalZipLookup: PropTypes.func,
        filterProducts: PropTypes.func
    }

    constructor(props) {
        super(props);

        this.state = {
            isMobileMenuVisible: false
        };

        this.toggleMobileMenu = this.toggleMobileMenu.bind(this);
    }

    handleLogoutClick = async () => {
        const { isOSC } = this.props;
        this.props.logout(false);
        if (isOSC) {
            UserManager.signoutRedirect();
        }
    }

    handleProductsClick = () => {

        const { history } = this.props;
        history.replace({ pathname: '/products' });
        if (this.state.isMobileMenuVisible) {
            this.toggleMobileMenu();
        }
    }

    handleCartClick = () => {

        const { history } = this.props;
        history.replace({ pathname: '/cart' });
    }

    handleHomeClick = () => {
        const { history, isOSC } = this.props;
        if(isOSC){
            history.replace({ pathname: '/create-order' });
        }
        else{
            history.replace({ pathname: '/' });
        }
    }

    toggleMobileMenu() {
        this.setState({isMobileMenuVisible: !this.state.isMobileMenuVisible});
    }

    handleFiltersChange = (categoryId) => {

        const { saleDetails, isOSC } = this.props;
        this.props.filterProducts(categoryId, saleDetails.session, isOSC);
    }

    render() {

        const { location, categories, productFilters, user, isOSC, totalCartQuantity } = this.props;

        let productLink = null;
        let cartLink = null;
        let logo = null;

        const quantity = totalCartQuantity ? <span className="quantity">{totalCartQuantity}</span> : null;

        if (location.pathname === "/") {
            productLink = <NavLink
                to="#"
                className="link products"
                onClick={this.handleProductsClick}
            >
                Products & Services
            </NavLink>;

            cartLink = <NavLink
                to="#"
                onClick={this.handleProductsClick}
                className="cart secondary">
                <span className="text">View cart</span>
                <span className="icon"></span>
            </NavLink>;
        }

        if (location.pathname === "/products") {
            productLink = <Filters
                filters={categories}
                activeFilter={productFilters.categoryId}
                onChange={this.handleFiltersChange}
            />;
            cartLink = <NavLink to="#" onClick={this.handleCartClick} className="cart secondary">
                {quantity}
                <span className="text">View cart</span>
                <span className="icon"></span>
            </NavLink>;
        }

        if (location.pathname === "/cart") {
            cartLink = <NavLink to="#" onClick={this.handleCartClick} disabled className="cart secondary active">
                {quantity}
                <span className="text">Cart</span>
                <span className="icon"></span>
            </NavLink>;
            productLink = <NavLink
                to="#"
                onClick={this.handleProductsClick}
                className="link products"
            >
                Products & Services
            </NavLink>;
        }

        let logout = null;
        if (isOSC) {
            logout = <button type="button" className="link logout" onClick={this.handleLogoutClick}>Logout</button>;
        }

        const mobileMenuClasses = {
            'mobile-menu-wrapper': true,
            'visible': this.state.isMobileMenuVisible
        };

        const headerClasses = {
            'zip-modal-visible' : this.props.modalZipLookUpVisible,
            'product-page': location.pathname === "/products"
        };

        const mobileMenuButton =
            location.pathname === "/" || location.pathname === "/cart" || location.pathname === "/products"
                ? <button className="mobile-menu-icon link" onClick={this.toggleMobileMenu}></button> : null;

        if (user && user.isReseller) {
            logo = <div className="logos-wrapper">
                <NavLink className="logo-one-step" to="#" onClick={this.handleHomeClick} />
                <div className="divider"/>
                <NavLink
                    className="logo-third-party"
                    style={{ backgroundImage: `url("${user.thirdPartyLogo}")` }}
                    to="#"
                    onClick={this.handleHomeClick}
                />
            </div>;
        } else {
            logo = <NavLink className="logo-one-step" to="#" onClick={this.handleHomeClick} />;
        }

        return (
            <header className={ClassNames(headerClasses)}>
                {logo}
                <div className="sub-nav-wrapper">
                    <div className={ClassNames(mobileMenuClasses)}>
                        {productLink}
                    </div>
                    {logout}
                    {cartLink}
                    {mobileMenuButton}
                </div>
            </header>
        );
    }
}

const mapStateToProps = ({ profile, catalog, order }) => ({
    isOSC: !!profile.accessToken && profile.expired != null && !profile.expired,
    user: profile.user,
    profile,
    token: profile.accessToken,
    zipLookup: catalog.zipLookup,
    categories: catalog.categories,
    productFilters: catalog.filters,
    saleDetails: order.saleDetails,
    modalZipLookUpVisible: catalog.modalZipLookUpVisible,
    totalCartQuantity: order.totalCartQuantity
});

export default withRouter(connect(mapStateToProps,
    {
        logout,
        toggleModalZipLookup,
        filterProducts
    }
)(Header));
