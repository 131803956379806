import React, { Component} from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter, NavLink } from 'react-router-dom';
import ClassNames from 'classnames';
import moment from 'moment';

import ProductsHelper from '../../helpers/products';

import Header from '../../components/header/Header';
import Footer from '../../components/footer/Footer';
import FormInvoiceEmail from './components/form-invoice-email/FormInvoiceEmail';
import FormCheckPayment from './components/form-check-payment/FormCheckPayment';

import Preloader from '../../components/preloader/Preloader';

import {
    clearOrder,
    sendInvoice,
    cancelPaypalInvoice,
    getInvoiceStatus
} from '../../actions/orderActions';

import './checkout-with-invoice.scss';

class PageCheckout extends Component {

    static propTypes = {
        history: PropTypes.object,
        isLoading: PropTypes.bool,
        isOSC: PropTypes.bool,
        customer: PropTypes.object,
        saleDetails: PropTypes.object,
        cartItems: PropTypes.array,
        tax: PropTypes.object,
        invoice: PropTypes.object,
        clearOrder: PropTypes.func,
        sendInvoice: PropTypes.func,
        cancelPaypalInvoice: PropTypes.func,
        getInvoiceStatus: PropTypes.func
    }

    constructor(props) {
        super(props);

        this.state = {
            forceInvoiceEmailForm: false,
            changedEmail: ''
        };
    }

    componentDidUpdate(prevProps) {

        const { invoice } = this.props;

        if (prevProps.invoice.status !== 'PAID' && invoice.status === 'PAID') {
            window.setTimeout(() => {
                this.props.history.replace({ pathname: '/new-order-created' });
            }, 1000);
        }
    }

    sendInvoice = (changedEmail) => {
        const { customer, tax } = this.props;
        const { forceInvoiceEmailForm } = this.state;
        let invoiceProducts = this.getProducts();
        let currentDate = moment().format('YYYY-MM-DD');

        const phone = this.getPhone();

        const invoiceData = {
            purchaseDate: currentDate,
            totalTaxAmount: ProductsHelper.getTotalTax(tax),
            customer: {
                name: customer.primaryContactName,
                phone,
                email: changedEmail || customer.primaryContactEmail,
                billingAddress: {
                    line1: customer.street1,
                    city: customer.city,
                    region: customer.state,
                    postalCode: customer.postalCode
                }
            },
            products: invoiceProducts
        };
        this.props.sendInvoice(invoiceData).then(() => {
            if (forceInvoiceEmailForm) {
                this.setState({
                    forceInvoiceEmailForm: false
                });
            }
        });

        if (changedEmail) {
            this.setState({
                changedEmail
            });
        }
    }

    getProducts() {
        const { cartItems } = this.props;
        let productList = [];

        cartItems.forEach((item) => {
            let materialAmount = 0;
            let laborAmount = 0;

            if (item.hasMaterial) {
                materialAmount = item.materialPrice;
                if (item.isDiscounted) {
                    materialAmount = item.totalDiscountMaterialPrice;
                }
            }

            if (item.hasLabor) {
                laborAmount = item.laborPrice;
                if (item.isDiscounted) {
                    laborAmount = item.totalDiscountLaborPrice;
                }
            }

            let product = {
                name: item.title,
                description: '',
                quantity: item.quantity,
                materialAmount,
                laborAmount
            };
            productList.push(product);
        });

        return productList;
    }

    getInvoiceStatus = () => {
        const { invoice } = this.props;
        this.props.getInvoiceStatus(invoice.id);
    }

    getPhone() {
        const {customer} = this.props;
        let phone = customer.primaryContactPhone.replace(/\s/g, '');
        phone = phone.includes('+')
            ? phone.substring(2)
            : phone;
        return phone;
    }

    handleResentInvoiceClick = () => {
        this.setState({
            forceInvoiceEmailForm: true
        });
    }

    handleCancelSaleClick = () => {
        const { invoice, isOSC } = this.props;
        if (invoice.status === 'SENT') {
            this.props.cancelPaypalInvoice(invoice.id);
        }
        this.props.clearOrder();

        if (isOSC) {
            this.props.history.replace({ pathname: '/create-order' });
        } else {
            this.props.history.replace({ pathname: '/' });
        }
    }

    handleCheckoutClick = () => {
        this.props.history.replace({ pathname: '/checkout' });
    }

    render() {

        const { invoice, customer, isLoading } = this.props;
        const { forceInvoiceEmailForm, changedEmail } = this.state;

        const pageClasses = {
            'page': true,
            'page-checkout-with-invoice': true,
            'back-action-hidden':  invoice.status === 'SENT'
        };

        return (
            <div className={ClassNames(pageClasses)}>
                <Header/>
                <div className="page-content-wrapper">
                    <main>
                        {
                            invoice.status !== 'SENT' ?
                                <NavLink
                                    to="#"
                                    onClick={this.handleCheckoutClick}
                                    className="back-action">
                                    Back to checkout
                                </NavLink>
                                : null
                        }

                        <h1 data-title="Payment via PayPal">Payment via PayPal invoice</h1>

                        {invoice.id === '' || forceInvoiceEmailForm ?
                            <FormInvoiceEmail
                                isLoading={isLoading}
                                email={changedEmail || customer.primaryContactEmail}
                                onSubmit={this.sendInvoice}
                            />
                            : <FormCheckPayment
                                isLoading={isLoading}
                                invoice={invoice}
                                email={changedEmail || customer.primaryContactEmail}
                                onCheck={this.getInvoiceStatus}
                            />
                        }
                    </main>

                    <div className="checkout-actions-wrapper">
                        {
                            invoice.status !== 'PAID' ?
                                <button
                                    className="link cancel-action"
                                    onClick={this.handleCancelSaleClick}
                                >
                                    Cancel Sale
                                </button> : null
                        }

                        {
                            invoice.id && invoice.status !== 'PAID' && !forceInvoiceEmailForm ?
                                <p className="resend-invoice-action-wrapper">
                                If invoice has not been received or to send invoice to a different email address,&nbsp;
                                    <button
                                        disabled={isLoading}
                                        className="link resend-invoice-action"
                                        onClick={this.handleResentInvoiceClick}
                                    >
                                        click here
                                    </button>
                                </p> : null
                        }

                    </div>

                </div>
                <Footer/>
                {
                    isLoading && (invoice.id === '' || forceInvoiceEmailForm) ? <Preloader type="overlay" /> : null
                }
            </div>
        );
    }
}

const mapStateToProps = ({ order, profile }) => ({
    isOSC: !!profile.accessToken && profile.expired != null && !profile.expired,
    isLoading: order.isLoading,
    customer: order.customerAccount,
    saleDetails: order.saleDetails,
    cartItems: order.items,
    tax: order.tax,
    invoice: order.invoice
});

export default withRouter(connect(mapStateToProps, {
    clearOrder,
    sendInvoice,
    cancelPaypalInvoice,
    getInvoiceStatus
})(PageCheckout));
